import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Navigate } from '@ngxs/router-plugin';
import { Action, State, StateContext } from '@ngxs/store';
import { append, insertItem, patch, removeItem } from '@ngxs/store/operators';
import { Observable, of, switchMap } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { ConversationActionsDistributorService } from '@clover/conversations-v4/conversation/state/conversation/conversation-actions-distributor.service';
import { CONVERSATIONS_BASE_URL } from '@clover/conversations-v4/routes';
import { CdkPortalService } from '@clover/core/services/cdk-portal.service';
import { ConversationsCategory } from '@conversations/conversations/categories';
import {
  ConversationsStateModel,
  defaultConversationsState,
} from '@conversations/conversations/state/conversations/conversations-state.model';
import {
  AddConversation,
  CreateChannel,
  LoadConversations,
  LoadNewConversation,
  LoadNextConversations,
  RemoveConversation,
  UpsertConversation,
} from '@conversations/conversations/state/conversations/conversations.actions';
import {
  ConversationCategoryFilterParams,
  ConversationsService,
} from '@conversations/conversations/state/conversations/conversations.service';
import { upsertItem } from '@core/helpers/custom-state-operators';
import { PagingOrder } from '@core/helpers/paging';
import { ToastType } from '@design/overlays/toast/toast';

@State<ConversationsStateModel>({
  name: 'conversations',
  defaults: defaultConversationsState,
})
@Injectable()
export class ConversationsState {
  private loadedWorkspaceId: number | 'aggregated' | null = null;
  private loadedCategoryId: ConversationsCategory | null = null;
  private loadedFilterParams: ConversationCategoryFilterParams | null = null;

  private readonly conversationsService = inject(ConversationsService);
  private readonly conversationActionsDistributorService = inject(ConversationActionsDistributorService);
  private readonly portal = inject(CdkPortalService);
  private readonly translateService = inject(TranslateService);

  @Action(LoadConversations, { cancelUncompleted: true })
  loadConversations(
    ctx: StateContext<ConversationsStateModel>,
    { workspaceId, categoryId, filterParams }: LoadConversations,
  ): Observable<void> {
    ctx.patchState({ loadingStatus: 'loading' });

    return this.conversationsService
      .getConversations(workspaceId, categoryId, filterParams, {
        limit: 30,
        order: PagingOrder.Descending,
        orderBy: 'last_updated_at',
      })
      .pipe(
        tap((conversations) => {
          ctx.patchState({
            conversations,
            loadingStatus: 'loaded',
          });

          this.loadedWorkspaceId = workspaceId || 'aggregated';
          this.loadedCategoryId = categoryId;
          this.loadedFilterParams = filterParams;
        }),
        catchError((err) => {
          ctx.patchState({ loadingStatus: 'error' });
          console.error(err);
          return of();
        }),
        map(() => undefined),
      );
  }

  @Action(LoadNextConversations)
  loadNextConversations(ctx: StateContext<ConversationsStateModel>): Observable<void> {
    const state = ctx.getState();
    const { loadingStatus } = state;
    const nextCursor = state.conversations.cursor.next;

    const workspaceId = this.loadedWorkspaceId;
    const categoryId = this.loadedCategoryId;
    const filterParams = this.loadedFilterParams;

    if (!workspaceId || !categoryId || !filterParams || !nextCursor || loadingStatus !== 'loaded') return of();

    ctx.patchState({ loadingStatus: 'loading-next' });

    return this.conversationsService
      .getConversations(workspaceId === 'aggregated' ? undefined : workspaceId, categoryId, filterParams, {
        limit: 30,
        cursor: nextCursor,
        order: PagingOrder.Descending,
        orderBy: 'last_updated_at',
      })
      .pipe(
        tap((nextConversations) => {
          ctx.setState(
            patch<ConversationsStateModel>({
              conversations: patch({
                data: append(nextConversations.data),
                count: state.conversations.count + nextConversations.count,
                cursor: patch({ next: nextConversations.cursor.next }),
                paging: nextConversations.paging,
              }),
            }),
          );

          ctx.patchState({ loadingStatus: 'loaded' });
        }),
        catchError((err) => {
          ctx.patchState({ loadingStatus: 'error' });
          console.error(err);
          return of();
        }),
        map(() => undefined),
      );
  }

  @Action(LoadNewConversation)
  loadNewConversation(
    ctx: StateContext<ConversationsStateModel>,
    { conversationId }: LoadNewConversation,
  ): Observable<void> {
    const state = ctx.getState();
    const { conversations } = state;

    // Skip if conversation already exists.
    if (conversations.data.some((conversation) => conversation.id === conversationId)) return of();

    return this.conversationsService.getConversation(conversationId).pipe(
      switchMap((conversation) => ctx.dispatch(new AddConversation(conversation))),
      map(() => undefined),
    );
  }

  @Action(AddConversation)
  addConversation(ctx: StateContext<ConversationsStateModel>, { conversation }: AddConversation): void {
    const state = ctx.getState();
    const { conversations } = state;

    ctx.setState(
      patch<ConversationsStateModel>({
        conversations: patch({
          data: insertItem(conversation, 0),
          count: conversations.count + 1,
          total: conversations.total + 1,
        }),
      }),
    );
  }

  @Action(UpsertConversation)
  upsertConversation(
    ctx: StateContext<ConversationsStateModel>,
    { conversation: newConversation }: UpsertConversation,
  ): void {
    const state = ctx.getState();
    const hasConversation = state.conversations.data.some((conversation) => conversation.id === newConversation.id);

    ctx.setState(
      patch<ConversationsStateModel>({
        conversations: patch({
          data: upsertItem((item) => item.id === newConversation.id, newConversation),
          count: hasConversation ? state.conversations.count : state.conversations.count + 1,
          total: hasConversation ? state.conversations.total : state.conversations.total + 1,
        }),
      }),
    );
  }

  @Action(RemoveConversation)
  removeConversation(ctx: StateContext<ConversationsStateModel>, { conversationId }: RemoveConversation): void {
    const state = ctx.getState();
    const { conversations } = state;

    ctx.setState(
      patch<ConversationsStateModel>({
        conversations: patch({
          data: removeItem((conversation) => conversation.id === conversationId),
          count: conversations.count - 1,
          total: conversations.total - 1,
        }),
      }),
    );
  }

  @Action(CreateChannel)
  createChannel(
    ctx: StateContext<ConversationsStateModel>,
    { workspaceId, name, memberIds }: CreateChannel,
  ): Observable<void> {
    return this.conversationsService.createChannel(workspaceId, name, memberIds).pipe(
      tap((conversation) => {
        this.conversationActionsDistributorService.handleConversationCreation(conversation.id);

        ctx.dispatch(
          new Navigate([
            '/',
            CONVERSATIONS_BASE_URL,
            conversation.workspaceId,
            ConversationsCategory.Channels,
            conversation.id,
          ]),
        );

        this.portal.presentToast(
          this.translateService.instant('conversations-v4.workspaces.dialogs.createChannel.channelCreated'),
          ToastType.Success,
        );
      }),
      catchError(() => {
        this.portal.presentToast(
          this.translateService.instant('conversations-v4.workspaces.dialogs.createChannel.failedToCreateChannel'),
          ToastType.Error,
        );
        return of(undefined);
      }),
      map(() => undefined),
    );
  }
}
