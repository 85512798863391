import type { Signature, SignatureDefaults } from './signatures-state.model';

export class LoadSignatures {
  static readonly type = '[Signatures] Load Signatures';

  constructor(public workspaceIds: number[]) {}
}

export class CreateSignature {
  static readonly type = '[Signatures] Create Signature';

  constructor(
    public workspaceId: number,
    public signature: Pick<Signature, 'name' | 'content'>,
  ) {}
}

export class UpdateSignature {
  static readonly type = '[Signatures] Update Signature';

  constructor(
    public workspaceId: number,
    public signatureId: number,
    public signature: Pick<Signature, 'name' | 'content'>,
  ) {}
}

export class DeleteSignature {
  static readonly type = '[Signatures] Delete Signature';

  constructor(
    public workspaceId: number,
    public signatureId: number,
  ) {}
}

export class SetSignaturesDefaults {
  static readonly type = '[Signatures] Set Signatures Defaults';

  constructor(
    public workspaceId: number,
    public defaults: Pick<SignatureDefaults, 'newEmailSignatureId' | 'replyEmailSignatureId'>,
  ) {}
}
