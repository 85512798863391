import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Label } from '@conversations/workspaces/state/labels/labels-state.model';

export const DEFAULT_LABEL_BACKGROUND_COLOR = '#E7E7E7';
export const DEFAULT_LABEL_FOREGROUND_COLOR = '#464645';

@Component({
  selector: 'cc-label-icon',
  standalone: true,
  imports: [],
  templateUrl: './label-icon.component.html',
  styleUrls: ['./label-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelIconComponent {
  @Input()
  label: Pick<Label, 'private' | 'backgroundColor' | 'foregroundColor'>;

  defaultBackgroundColor = DEFAULT_LABEL_BACKGROUND_COLOR;
  defaultForegroundColor = DEFAULT_LABEL_FOREGROUND_COLOR;
}
