import { ConnectedPosition, OverlayModule } from '@angular/cdk/overlay';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { map } from 'rxjs';
import { v4 as uuid } from 'uuid';

import {
  ExternalAccount,
  ExternalAccountStatus,
} from '@conversations/workspaces/state/workspaces/workspaces-state.model';
import { WorkspacesSelectors } from '@conversations/workspaces/state/workspaces/workspaces.selectors';
import { getOverlayVisibilityAfterOutsideClick } from '@core/helpers/get-overlay-visibility-after-outside-click';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { DropdownActionComponent } from '@design/overlays/dropdown/dropdown-action/dropdown-action.component';
import { DropdownTextComponent } from '@design/overlays/dropdown/dropdown-text/dropdown-text.component';
import { DropdownComponent } from '@design/overlays/dropdown/dropdown.component';

import { InitNewEmailComposer } from '../composer/state/composers/composers.actions';

@Component({
  selector: 'cc-create-button',
  standalone: true,
  imports: [
    DropdownComponent,
    DropdownActionComponent,
    OverlayModule,
    DropdownTextComponent,
    AsyncPipe,
    ButtonComponent,
    TranslateModule,
  ],
  templateUrl: './create-button.component.html',
  styleUrls: ['./create-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateButtonComponent {
  externalAccounts$ = inject(Store)
    .select(WorkspacesSelectors.externalAccounts)
    .pipe(map((accounts) => accounts.filter((account) => account.status === ExternalAccountStatus.Active)));

  protected dropdownVisible = false;
  protected readonly dropdownPositionStrategy: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
      offsetY: 4,
    },
  ];

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;

  protected readonly getOverlayVisibilityAfterOutsideClick = getOverlayVisibilityAfterOutsideClick;
  protected readonly ExternalAccountStatus = ExternalAccountStatus;
  private readonly store = inject(Store);

  initNewMessageComposer(account: ExternalAccount): void {
    if (account.status !== ExternalAccountStatus.Active) return;

    this.store.dispatch(new InitNewEmailComposer(uuid(), account.workspaceId, account.id));
  }
}
