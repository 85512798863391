import { ChangeDetectionStrategy, Component, HostBinding, HostListener, model, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';

import { maxImageHeight, maxImageWidth, minImageHeight, minImageWidth } from '../image.extension';

type ResizeType = 'width' | 'height' | 'both' | 'radius' | 'none';

@Component({
  selector: 'cc-image-resizer',
  standalone: true,
  imports: [TooltipDirective, TranslateModule],
  templateUrl: './image-resizer.component.html',
  styleUrl: './image-resizer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageResizerComponent {
  width = model.required<number>();
  height = model.required<number>();
  radius = model.required<number>();

  @HostBinding('style.width.px')
  get imageWidth(): number {
    return Number(this.width());
  }

  @HostBinding('style.height.px')
  get imageHeight(): number {
    return Number(this.height());
  }

  @HostBinding('style.borderRadius.px')
  get imageRadius(): number {
    return Number(this.radius());
  }

  currentResizeType: ResizeType = 'none';

  movementX$ = signal(0);
  movementY$ = signal(0);
  initialImageWidth$ = signal(0);
  initialImageHeight$ = signal(0);
  initialRadius$ = signal(0);

  dimensionsFrameId: number | null = null;
  radiusFrameId: number | null = null;

  @HostBinding('style.userSelect')
  get userSelect(): string {
    return this.currentResizeType === 'none' ? 'initial' : 'none';
  }

  startResize(resizeType: ResizeType, event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.currentResizeType = resizeType;

    this.movementX$.set(0);
    this.movementY$.set(0);
    this.initialImageWidth$.set(this.imageWidth);
    this.initialImageHeight$.set(this.imageHeight);
    this.initialRadius$.set(this.imageRadius);
  }

  @HostListener('document:mouseup')
  stopResize(): void {
    this.currentResizeType = 'none';
  }

  @HostListener('document:mousemove', ['$event'])
  handleMouseMove(event: MouseEvent): void {
    if (this.currentResizeType === 'none') {
      return;
    }

    if (this.currentResizeType === 'width') {
      this.handleWidthResize(event);
    }

    if (this.currentResizeType === 'height') {
      this.handleHeightResize(event);
    }

    if (this.currentResizeType === 'both') {
      this.handleBothResize(event);
    }

    if (this.currentResizeType === 'radius') {
      this.handleRadiusResize(event);
    }
  }

  private handleBothResize(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    const { movementX, movementY } = event;

    this.movementX$.update((prev) => prev + movementX);
    this.movementY$.update((prev) => prev + movementY);

    let newWidth = this.initialImageWidth$() + this.movementX$();
    let newHeight = this.initialImageHeight$() + this.movementY$();

    if (event.shiftKey) {
      // Aspect ratio unlocked
      if (newHeight < minImageHeight) {
        newHeight = minImageHeight;
      }

      if (newWidth < minImageWidth) {
        newWidth = minImageWidth;
      }
    } else {
      // Aspect ratio locked
      const aspectRatio = this.initialImageWidth$() / this.initialImageHeight$();
      newHeight = newWidth / aspectRatio;

      if (newHeight < minImageHeight) {
        newHeight = minImageHeight;
        newWidth = newHeight * aspectRatio;
      }

      if (newWidth < minImageWidth) {
        newWidth = minImageWidth;
        newHeight = newWidth / aspectRatio;
      }
    }

    this.updateDimensions(newWidth, newHeight);
  }

  private handleWidthResize(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    const { movementX } = event;

    this.movementX$.update((prev) => prev + movementX);

    this.updateDimensions(this.initialImageWidth$() + this.movementX$(), this.initialImageHeight$());
  }

  private handleHeightResize(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    const { movementY } = event;

    this.movementY$.update((prev) => prev + movementY);

    this.updateDimensions(this.initialImageWidth$(), this.initialImageHeight$() + this.movementY$());
  }

  private handleRadiusResize(event: MouseEvent): void {
    const { movementX, movementY } = event;

    this.movementX$.update((prev) => prev + movementX);
    this.movementY$.update((prev) => prev + movementY);

    const largerAbsMovement =
      Math.abs(this.movementX$()) > Math.abs(this.movementY$()) ? this.movementX$() : this.movementY$();

    this.updateRadius(this.initialRadius$() + largerAbsMovement);
  }

  private updateDimensions(width: number, height: number): void {
    if (width < minImageWidth || height < minImageHeight) return;
    if (width > maxImageWidth || height > maxImageHeight) return;

    if (this.dimensionsFrameId) cancelAnimationFrame(this.dimensionsFrameId);

    this.dimensionsFrameId = requestAnimationFrame(() => {
      this.width.set(width);
      this.height.set(height);
    });

    this.updateRadius(this.imageRadius);
  }

  private updateRadius(radius: number): void {
    const smallerSide = Math.min(this.imageWidth, this.imageHeight);

    const minRadius = 0;
    const maxRadius = smallerSide / 2;

    if (radius < minRadius) radius = minRadius;
    if (radius > maxRadius) radius = maxRadius;

    if (this.radiusFrameId) cancelAnimationFrame(this.radiusFrameId);

    this.radiusFrameId = requestAnimationFrame(() => {
      this.radius.set(radius);
    });
  }
}
