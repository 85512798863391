<cc-image-resizer
  [(width)]="width"
  [(height)]="height"
  [(radius)]="radius"
>
  <img
    class="cc-image"
    [style.width.px]="width()"
    [style.height.px]="height()"
    [style.border-radius.px]="radius()"
    [src]="src()"
    [alt]="alt()"
  />
</cc-image-resizer>
