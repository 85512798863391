import type { Message } from '@clover/conversations-v4/conversation/state/conversation/conversation-state.model';

export interface AssistantThread {
  id: string;
  messages: AssistantMessage[];
  metadata: AssistantThreadMetadata;
  assistantState: 'busy' | 'idle';
}

export interface AssistantMessage {
  id: string;
  sender: 'user' | 'assistant';
  text: string;
  timestamp: string;
  finished: boolean;
}

export interface AssistantThreadMetadata {
  relatedConversationId?: string;
}

export interface AssistantThreadConversationContext {
  contextType: 'conversation';
  id: string;
  subject: string;
  messages: Message[];
}

export type AssistantThreadContext = AssistantThreadConversationContext;

// =========================================

export interface AssistantStateModel {
  threads: AssistantThread[];
}

export const defaultAssistantState: AssistantStateModel = {
  threads: [],
};
