import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CdkConnectedOverlay, ConnectedPosition } from '@angular/cdk/overlay';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { Label } from '@conversations/workspaces/state/labels/labels-state.model';
import { LabelsSelectors } from '@conversations/workspaces/state/labels/labels.selectors';
import { Workspace } from '@conversations/workspaces/state/workspaces/workspaces-state.model';
import { WorkspacesSelectors } from '@conversations/workspaces/state/workspaces/workspaces.selectors';
import { getOverlayVisibilityAfterOutsideClick } from '@core/helpers/get-overlay-visibility-after-outside-click';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { TextboxComponent } from '@design/forms/textbox/textbox.component';
import { ToggleComponent } from '@design/forms/toggle/toggle.component';
import {
  DEFAULT_LABEL_BACKGROUND_COLOR,
  DEFAULT_LABEL_FOREGROUND_COLOR,
} from '@design/misc/label-icon/label-icon.component';
import { DropdownActionComponent } from '@design/overlays/dropdown/dropdown-action/dropdown-action.component';
import { DropdownTextComponent } from '@design/overlays/dropdown/dropdown-text/dropdown-text.component';
import { DropdownComponent } from '@design/overlays/dropdown/dropdown.component';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';

import { LabelColor, labelColors } from './label-colors';

export interface LabelEditorDialogData {
  labelToEdit?: Label;
  preselectedWorkspaceId?: number;
  predefinedName?: string;
}

export type LabelEditorDialogResult = Pick<
  Label,
  'workspaceId' | 'name' | 'visibleInNavigation' | 'backgroundColor' | 'foregroundColor'
>;

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    TextboxComponent,
    ToggleComponent,
    ButtonComponent,
    DropdownComponent,
    AsyncPipe,
    DropdownTextComponent,
    DropdownActionComponent,
    CdkConnectedOverlay,
    TranslateModule,
    TooltipDirective,
  ],
  standalone: true,
  styleUrls: ['./label-editor-modal.component.scss'],
  templateUrl: './label-editor-modal.component.html',
})
export class LabelEditorModalComponent implements OnInit {
  readonly dialogData: LabelEditorDialogData = inject(DIALOG_DATA);

  formGroup = new FormGroup({
    locationId: new FormControl<number>(undefined, [Validators.required]),
    name: new FormControl<string>('', [Validators.required]),
    showInNavigation: new FormControl<boolean>(false, [Validators.required]),
    color: new FormControl<LabelColor>(labelColors[0], [Validators.required]),
  });

  protected privateWorkspace$: Observable<Workspace>;
  protected teamWorkspaces$: Observable<Workspace[]>;
  protected selectedWorkspace$: Observable<Workspace>;

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;
  protected readonly LABEL_COLORS = labelColors;

  protected workspaceSelectDropdownOpen = false;
  protected readonly workspaceSelectDropdownPositionStrategy: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
      offsetY: 8,
    },
  ];
  protected readonly getOverlayVisibilityAfterOutsideClick = getOverlayVisibilityAfterOutsideClick;
  private readonly store = inject(Store);
  private readonly dialogRef: DialogRef<LabelEditorDialogResult> = inject(DialogRef);

  get workspaces(): Workspace[] {
    return this.store.selectSnapshot(WorkspacesSelectors.workspaces);
  }

  get locationFormControl(): FormControl {
    return this.formGroup.get('locationId') as FormControl;
  }

  get nameFormControl(): FormControl {
    return this.formGroup.get('name') as FormControl;
  }

  get showInNavigationFormControl(): FormControl {
    return this.formGroup.get('showInNavigation') as FormControl;
  }

  get colorFormControl(): FormControl {
    return this.formGroup.get('color') as FormControl;
  }

  get isNameUnique(): boolean {
    if (!this.locationFormControl.value) return true;

    const workspaceLabels = this.store.selectSnapshot(
      LabelsSelectors.labelsByWorkspaceId(this.locationFormControl.value),
    );

    return (
      workspaceLabels.every((label) => label.name.trim() !== this.nameFormControl.value.trim()) ||
      (this.editMode && this.nameFormControl.value.trim() === this.dialogData.labelToEdit?.name.trim())
    );
  }

  get editMode(): boolean {
    return !!this.dialogData.labelToEdit;
  }

  get canSubmit(): boolean {
    if (this.formGroup.invalid || !this.isNameUnique) return false;

    return this.editMode
      ? this.nameFormControl.value.trim() !== this.dialogData.labelToEdit?.name.trim() ||
          this.locationFormControl.value !== this.dialogData.labelToEdit?.workspaceId ||
          this.showInNavigationFormControl.value !== this.dialogData.labelToEdit?.visibleInNavigation ||
          this.colorFormControl.value.bg !== this.dialogData.labelToEdit?.backgroundColor ||
          this.colorFormControl.value.fg !== this.dialogData.labelToEdit?.foregroundColor
      : true;
  }

  ngOnInit(): void {
    if (this.dialogData.labelToEdit) {
      this.locationFormControl.setValue(this.dialogData.labelToEdit.workspaceId);
      this.nameFormControl.setValue(this.dialogData.labelToEdit.name);
      this.showInNavigationFormControl.setValue(this.dialogData.labelToEdit.visibleInNavigation);

      const labelColor = labelColors.find(
        (labelColor) =>
          labelColor.bg === this.dialogData.labelToEdit.backgroundColor &&
          labelColor.fg === this.dialogData.labelToEdit.foregroundColor,
      ) || {
        bg: DEFAULT_LABEL_BACKGROUND_COLOR,
        fg: DEFAULT_LABEL_FOREGROUND_COLOR,
      };
      this.selectLabelColor(labelColor);
    } else {
      this.locationFormControl.setValue(
        this.dialogData.preselectedWorkspaceId || this.store.selectSnapshot(WorkspacesSelectors.privateWorkspace).id,
      );
      this.nameFormControl.setValue(this.dialogData.predefinedName || '');
    }

    this.privateWorkspace$ = this.store.select(WorkspacesSelectors.privateWorkspace);
    this.teamWorkspaces$ = this.store.select(WorkspacesSelectors.teamWorkspaces);

    this.selectedWorkspace$ = combineLatest([
      this.store.select(WorkspacesSelectors.workspaces),
      this.locationFormControl.valueChanges.pipe(startWith(this.locationFormControl.value)),
    ]).pipe(
      map(([workspaces, selectedWorkspaceId]) => {
        return workspaces.find((workspace) => workspace.id === selectedWorkspaceId);
      }),
    );
  }

  isLabelColorSelected(labelColor: LabelColor): boolean {
    return this.colorFormControl.value.bg === labelColor.bg && this.colorFormControl.value.fg === labelColor.fg;
  }

  selectLabelColor(labelColor: LabelColor): void {
    this.colorFormControl.setValue(labelColor);
  }

  changeLocation(workspace: Workspace): void {
    this.locationFormControl.setValue(workspace.id);
  }

  cancel(): void {
    this.dialogRef.close();
  }

  submitLabel(): void {
    if (!this.canSubmit) return;

    this.dialogRef.close({
      workspaceId: this.locationFormControl.value,
      name: this.formGroup.get('name').value.trim(),
      visibleInNavigation: this.formGroup.get('showInNavigation').value,
      backgroundColor: this.formGroup.get('color').value.bg,
      foregroundColor: this.formGroup.get('color').value.fg,
    });
  }
}
