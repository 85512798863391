import { inject, Injectable } from '@angular/core';
import { addDays, addWeeks, formatISO, set } from 'date-fns';

import { DateService } from '@core/services/date.service';

export enum SnoozeOption {
  LaterToday = 'LaterToday',
  Tomorrow = 'Tomorrow',
  NextWeek = 'NextWeek',
}

export interface SnoozeOptionDetails {
  option: SnoozeOption;
  date: string;
}

const snoozeLaterHours = 18;

@Injectable({
  providedIn: 'root',
})
export class ConversationSnoozeService {
  private readonly dateService = inject(DateService);

  get snoozeOptions(): SnoozeOptionDetails[] {
    const now = this.dateService.representLocalDateInProfileTimezoneDate(new Date());
    const canSnoozeForLaterToday = now.getHours() < snoozeLaterHours;

    const options: SnoozeOptionDetails[] = [];

    if (canSnoozeForLaterToday) {
      options.push({
        option: SnoozeOption.LaterToday,
        date: this.snoozeOptionToISOString(SnoozeOption.LaterToday),
      });
    }

    options.push(
      {
        option: SnoozeOption.Tomorrow,
        date: this.snoozeOptionToISOString(SnoozeOption.Tomorrow),
      },
      {
        option: SnoozeOption.NextWeek,
        date: this.snoozeOptionToISOString(SnoozeOption.NextWeek),
      },
    );

    return options;
  }

  snoozeOptionToISOString(option: SnoozeOption): string {
    const now = this.dateService.representLocalDateInProfileTimezoneDate(new Date());

    switch (option) {
      case SnoozeOption.LaterToday:
        return formatISO(
          this.dateService.representProfileTimezoneDateInLocalDate(
            set(now, {
              hours: snoozeLaterHours,
              minutes: 0,
              seconds: 0,
              milliseconds: 0,
            }),
          ),
        );

      case SnoozeOption.Tomorrow:
        const tomorrow = addDays(now, 1);

        return formatISO(
          this.dateService.representProfileTimezoneDateInLocalDate(
            set(tomorrow, {
              hours: snoozeLaterHours,
              minutes: 0,
              seconds: 0,
              milliseconds: 0,
            }),
          ),
        );

      case SnoozeOption.NextWeek:
        const nextWeek = addWeeks(now, 1);

        return formatISO(
          this.dateService.representProfileTimezoneDateInLocalDate(
            set(nextWeek, {
              hours: snoozeLaterHours,
              minutes: 0,
              seconds: 0,
              milliseconds: 0,
            }),
          ),
        );
    }
  }
}
