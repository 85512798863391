import { Selector } from '@ngxs/store';

import {
  Conversation,
  ConversationsStateModel,
} from '@conversations/conversations/state/conversations/conversations-state.model';
import { ConversationsState } from '@conversations/conversations/state/conversations/conversations.state';

export class ConversationsSelectors {
  @Selector([ConversationsState])
  static conversations(state: ConversationsStateModel): Conversation[] {
    return (state.conversations.data || []).sort((a, b) => {
      const aPrioritized = a.type === 'existing' && a.prioritized;
      const bPrioritized = b.type === 'existing' && b.prioritized;

      if (aPrioritized && !bPrioritized) {
        return -1;
      } else if (!aPrioritized && bPrioritized) {
        return 1;
      } else {
        return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
      }
    });
  }

  @Selector([ConversationsState])
  static loadingStatus(state: ConversationsStateModel): 'void' | 'loading' | 'loaded' | 'error' | 'loading-next' {
    return state.loadingStatus;
  }
}
