<div #dropdownTrigger>
  <app-button
    (click)="dropdownVisible = !dropdownVisible"
    [class.create-button--expanded]="dropdownVisible"
    [size]="ButtonSize.Small"
    [type]="ButtonType.Secondary"
    class="create-button"
  >
    {{ 'createButton.title' | translate }}

    <i
      class="create-button__icon icon-chevronDown"
      slot="end-icon"
    ></i>
  </app-button>
</div>

<ng-template
  (detach)="dropdownVisible = false"
  (overlayOutsideClick)="dropdownVisible = getOverlayVisibilityAfterOutsideClick($event, dropdownTrigger)"
  [cdkConnectedOverlayOpen]="dropdownVisible"
  [cdkConnectedOverlayOrigin]="dropdownTrigger"
  [cdkConnectedOverlayPositions]="dropdownPositionStrategy"
  cdkConnectedOverlay
>
  <cc-dropdown width="200px">
    <cc-dropdown-action>
      <ng-container slot="title">
        {{ 'createButton.newEmail.title' | translate }}
      </ng-container>

      <cc-dropdown>
        <cc-dropdown-text>
          <ng-container slot="title">
            {{ 'createButton.newEmail.emailSender' | translate }}
          </ng-container>
        </cc-dropdown-text>

        @for (account of externalAccounts$ | async; track account.id) {
          <cc-dropdown-action (click)="initNewMessageComposer(account); dropdownVisible = !dropdownVisible">
            <ng-container slot="title">{{ account.name }}</ng-container>
            <ng-container slot="caption">{{ account.email }}</ng-container>
          </cc-dropdown-action>
        } @empty {
          <cc-dropdown-text>
            <ng-container slot="title">
              {{ 'createButton.newEmail.noAccounts' | translate }}
            </ng-container>
          </cc-dropdown-text>
        }
      </cc-dropdown>
    </cc-dropdown-action>
  </cc-dropdown>
</ng-template>
