import { inject, Injectable } from '@angular/core';
import { Action, State, type StateContext } from '@ngxs/store';
import { insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { finalize, map, tap, type Observable } from 'rxjs';

import { upsertItem } from '@clover/core/helpers/custom-state-operators';

import { DeleteThread, SendMessage } from './assistant.actions';
import { defaultAssistantState, type AssistantStateModel } from './assistant.entities';
import { AssistantService } from './assistant.service';

@State<AssistantStateModel>({
  name: 'assistant',
  defaults: defaultAssistantState,
})
@Injectable()
export class AssistantState {
  private readonly assistantService = inject(AssistantService);

  @Action(SendMessage)
  async sendMessage(
    ctx: StateContext<AssistantStateModel>,
    { threadId, message, context }: SendMessage,
  ): Promise<Observable<void>> {
    if (!threadId) {
      // If there is no threadId, create a new thread, with the user message
      // Note: it will be busy by default
      const thread = await this.assistantService.createThreadFromMessage(message, context);
      threadId = thread.id;

      ctx.setState(
        patch<AssistantStateModel>({
          threads: insertItem(thread),
        }),
      );
    } else {
      // Send user message, add it to the thread
      const userMessage = await this.assistantService.sendUserMessage(threadId, message);

      ctx.setState(
        patch<AssistantStateModel>({
          threads: updateItem(
            (thread) => thread.id === threadId,
            patch({
              messages: insertItem(userMessage),
              assistantState: 'busy',
            }),
          ),
        }),
      );
    }

    // Run the thread, receive assistant message, add it to the thread
    return this.assistantService.runThread(threadId, context).pipe(
      tap((message) => {
        ctx.setState(
          patch<AssistantStateModel>({
            threads: updateItem(
              (thread) => thread.id === threadId,
              patch({
                messages: upsertItem((_message) => _message.id === message.id, message),
              }),
            ),
          }),
        );
      }),
      finalize(() => {
        // Set thread state to 'idle'
        ctx.setState(
          patch<AssistantStateModel>({
            threads: updateItem(
              (thread) => thread.id === threadId,
              patch({
                assistantState: 'idle',
              }),
            ),
          }),
        );
      }),
      map(() => undefined),
    );
  }

  @Action(DeleteThread)
  deleteThread(ctx: StateContext<AssistantStateModel>, { threadId }: DeleteThread): void {
    ctx.setState(
      patch<AssistantStateModel>({
        threads: removeItem((thread) => thread.id === threadId),
      }),
    );
  }
}
