import { inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { asyncScheduler, Observable, Subject, Subscription } from 'rxjs';

const highlightTimeout = 1000;

@Injectable({
  providedIn: 'root',
})
export class ConversationScrollService {
  private highlightedMessageElement: HTMLElement | undefined;
  private scheduledHighlightRemove: Subscription | undefined;

  private renderer: Renderer2;
  private readonly rendererFactory = inject(RendererFactory2);

  constructor() {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  private _scrollDown$ = new Subject<void>();

  get scrollDown$(): Observable<void> {
    return this._scrollDown$.asObservable();
  }

  private _scrollToMessage$ = new Subject<string>();

  get scrollToMessage$(): Observable<string> {
    return this._scrollToMessage$.asObservable();
  }

  triggerScrollDown(): void {
    this._scrollDown$.next();
  }

  triggerScrollToMessage(messageId: string, highlightMessage = true): void {
    this._scrollToMessage$.next(messageId);
    if (highlightMessage) this.highlightMessage(messageId);
  }

  highlightMessage(messageId: string): void {
    const messageElement = document.querySelector(`[data-message-id="${messageId}"]`) as HTMLElement;
    if (!messageElement) return;

    if (this.highlightedMessageElement) this.removeHighlight(this.highlightedMessageElement);
    if (this.scheduledHighlightRemove) this.scheduledHighlightRemove.unsubscribe();

    this.highlightedMessageElement = messageElement;
    this.renderer.addClass(messageElement, 'cc-highlighted-message');

    this.scheduledHighlightRemove = asyncScheduler.schedule(
      () => this.removeHighlight(messageElement),
      highlightTimeout,
    );
  }

  private removeHighlight(messageElement: HTMLElement): void {
    if (!messageElement) return;
    this.renderer.removeClass(messageElement, 'cc-highlighted-message');
  }
}
