import { runInInjectionContext, inject, Renderer2, ElementRef, effect, RendererStyleFlags2 } from '@angular/core';
import { assertInjector } from 'ngxtension/assert-injector';

/* eslint-disable @typescript-eslint/ban-types */
/**
 * `hostBinding` takes a `hostPropertyName` to attach a data property, a class, a style or an attribute (as `@HostBinding` would) to the host.
 * The udpate is applied based on the update of the provided signal (writable or not).
 *
 * @param {Required<HostBinding>['hostPropertyName']} hostPropertyName - the same property that is bound to a data property, a class, a style or an attribute as `@HostBinding`.
 * @param {Signal | WritableSignal} signal - the signal on which to react to changes to update the host, and the one that will be returned as it is
 * @returns {Signal | WritableSignal}
 *
 * @example
 * ```ts
 * export class MyComponent {
 *  readonly background = hostBinding('style.background', signal('blue'));
 *
 *  constructor() {
 *    setTimeout(() => this.background.set('red'), 3000);
 *  }
 * }
 * ```
 */
function hostBinding(hostPropertyName, signal, injector) {
    injector = assertInjector(hostBinding, injector);
    runInInjectionContext(injector, () => {
        const renderer = inject(Renderer2);
        const element = inject(ElementRef).nativeElement;
        let prevClasses = [];
        effect(() => {
            const value = signal();
            const [binding, property, unit] = hostPropertyName.split('.');
            switch (binding) {
                case 'style':
                    renderer.setStyle(element, property, `${value}${unit ?? ''}`, property.startsWith('--')
                        ? RendererStyleFlags2.DashCase
                        : undefined);
                    break;
                case 'attr':
                    if (value == null) {
                        renderer.removeAttribute(element, property);
                    }
                    else {
                        renderer.setAttribute(element, property, String(value));
                    }
                    break;
                case 'class':
                    if (!property) {
                        if (prevClasses.length) {
                            prevClasses.forEach((k) => renderer.removeClass(element, k));
                        }
                        prevClasses =
                            typeof value === 'string' ? value.split(' ').filter(Boolean) : [];
                        prevClasses.forEach((k) => renderer.addClass(element, k));
                    }
                    else {
                        if (value) {
                            renderer.addClass(element, property);
                        }
                        else {
                            renderer.removeClass(element, property);
                        }
                    }
                    break;
                default:
                    renderer.setProperty(element, binding, value);
            }
        });
    });
    return signal;
}

/**
 * Generated bundle index. Do not edit.
 */

export { hostBinding };

