import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { modifyPathParams } from '@clover/core/helpers/modify-path-param';
import { CONVERSATIONS_BASE_URL } from '@conversations/routes';
import {
  WORKSPACE_SETTINGS_MODAL_OUTLET,
  WorkspaceSettingsModalTab,
} from '@conversations/workspaces/modals/workspace-settings-modal/routes';
import {
  checkWorkspaceExistence,
  getFallbackWorkspaceAlias,
} from '@conversations/workspaces/state/workspaces/workspaces-state.helpers';
import { ExternalAccountState } from '@conversations/workspaces/state/workspaces/workspaces-state.model';
import { ConnectExternalAccount, LoadWorkspaces } from '@conversations/workspaces/state/workspaces/workspaces.actions';
import { WorkspacesSelectors } from '@conversations/workspaces/state/workspaces/workspaces.selectors';

import { WorkspaceAlias } from './workspaces';

export const workspacesResolver: ResolveFn<boolean> = (route: ActivatedRouteSnapshot): Observable<boolean> => {
  const { workspaceAlias } = route.firstChild?.params || {};
  const { state: nylasStateParam, code: nylasCodeParam } = route.queryParams;
  const nylasState: ExternalAccountState | undefined = nylasStateParam
    ? JSON.parse(decodeURIComponent(nylasStateParam))
    : null;

  const store = inject(Store);

  return of(true).pipe(
    switchMap(() => {
      // If the URL contains Nylas query params, we need to connect the external account first.
      if (nylasState && nylasCodeParam) return store.dispatch(new ConnectExternalAccount(nylasState, nylasCodeParam));
      return of(true);
    }),
    switchMap(() => store.dispatch(new LoadWorkspaces())),
    switchMap(() => {
      if (nylasState && nylasCodeParam) {
        const workspaceAlias = store.selectSnapshot(
          WorkspacesSelectors.workspaceAliasById(Number(nylasState.workspaceId)),
        );

        return store.dispatch(
          new Navigate(
            [
              CONVERSATIONS_BASE_URL,
              {
                outlets: {
                  primary: [workspaceAlias],
                  [WORKSPACE_SETTINGS_MODAL_OUTLET]: [workspaceAlias, WorkspaceSettingsModalTab.Inbox],
                },
              },
            ],
            {
              state: undefined,
              code: undefined,
            },
            {
              queryParamsHandling: 'merge',
              replaceUrl: true,
            },
          ),
        );
      }

      // Change the workspace alias to "private" if route contains the explicit private workspace ID.
      const privateWorkspace = store.selectSnapshot(WorkspacesSelectors.privateWorkspace);
      const hasExplicitlySetPrivateWorkspaceId =
        workspaceAlias && privateWorkspace && workspaceAlias === String(privateWorkspace.id);

      if (hasExplicitlySetPrivateWorkspaceId) {
        return store.dispatch(
          new Navigate(
            [
              CONVERSATIONS_BASE_URL,
              ...modifyPathParams(route, [
                {
                  paramName: 'workspaceAlias',
                  outletName: 'primary',
                  replaceWith: WorkspaceAlias.Private,
                },
              ]),
            ],
            undefined,
            {
              replaceUrl: true,
            },
          ),
        );
      }

      // Preselect the default workspace if it is not in the URL or the workspace does not exist.
      const workspaces = store.selectSnapshot(WorkspacesSelectors.workspaces);
      const validWorkspace = workspaceAlias && checkWorkspaceExistence(workspaces, workspaceAlias);

      if (!validWorkspace) {
        return store.dispatch(
          new Navigate([CONVERSATIONS_BASE_URL, getFallbackWorkspaceAlias(workspaces)], undefined, {
            replaceUrl: true,
          }),
        );
      }
      return of(true);
    }),
    take(1),
    map(() => true),
  );
};
