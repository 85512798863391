import { DIALOG_DATA, DialogModule, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';

export interface AccountAlreadyConnectedDialogData {
  email: string;
}

@Component({
  standalone: true,
  templateUrl: './account-already-connected-modal.component.html',
  styleUrls: ['./account-already-connected-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonComponent, DialogModule, TranslateModule],
})
export class AccountAlreadyConnectedModalComponent {
  protected readonly dialogData: AccountAlreadyConnectedDialogData = inject(DIALOG_DATA);
  protected readonly dialogRef: DialogRef = inject(DialogRef);

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;
}
