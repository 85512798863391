<div
  cdkDropListGroup
  class="composer-recipients-selector-editor"
>
  <cc-composer-recipients-selector-editor-row
    (participantsUpdate)="updateRecipients('to', $event)"
    (transfer)="transferItem($event)"
    [participants]="recipients.to"
    class="composer-recipients-selector-editor__row"
    type="to"
  />

  <cc-composer-recipients-selector-editor-row
    (participantsUpdate)="updateRecipients('cc', $event)"
    (transfer)="transferItem($event)"
    [participants]="recipients.cc"
    class="composer-recipients-selector-editor__row"
    type="cc"
  />

  <cc-composer-recipients-selector-editor-row
    (participantsUpdate)="updateRecipients('bcc', $event)"
    (transfer)="transferItem($event)"
    [participants]="recipients.bcc"
    class="composer-recipients-selector-editor__row"
    type="bcc"
  />
</div>
