<div class="composer-actions">
  <div class="composer-actions__group">
    <div #plusDropdownTrigger>
      <app-button
        (click)="plusDropdownVisible = !plusDropdownVisible"
        [active]="plusDropdownVisible"
        [ccTooltip]="'common.buttons.moreActions' | translate"
        [size]="ButtonSize.Big"
        [tooltipAlignment]="TooltipAlignment.Start"
        [type]="ButtonType.Ghost"
        class="composer-actions__group-plus-button"
      >
        <i
          [class.composer-actions__group-plus-button-icon--active]="plusDropdownVisible"
          class="composer-actions__group-plus-button-icon icon-add"
          slot="ghost-icon"
        ></i>
      </app-button>
    </div>

    <ng-template
      (detach)="plusDropdownVisible = false"
      (overlayOutsideClick)="plusDropdownVisible = getOverlayVisibilityAfterOutsideClick($event, plusDropdownTrigger)"
      [cdkConnectedOverlayOpen]="plusDropdownVisible"
      [cdkConnectedOverlayOrigin]="plusDropdownTrigger"
      [cdkConnectedOverlayPositions]="dropdownPositionStrategy"
      cdkConnectedOverlay
    >
      <cc-dropdown [shadow]="false">
        <cc-dropdown-action
          [disabled]="newTaskCreationDisabled"
          leadingIcon="icon-create-task"
        >
          <ng-container slot="title">
            {{ 'conversations-v4.composer.actions.more.createNewTask' | translate }}
          </ng-container>
        </cc-dropdown-action>

        <cc-dropdown-divider></cc-dropdown-divider>

        <cc-dropdown-text>
          <ng-container slot="title">
            {{ 'conversations-v4.composer.actions.more.attachments' | translate }}
          </ng-container>
        </cc-dropdown-text>

        <cc-dropdown-action
          (click)="presentTaskEmbedDialog(); plusDropdownVisible = false"
          leadingIcon="icon-tasks"
        >
          <ng-container slot="title">
            {{ 'conversations-v4.composer.actions.more.task' | translate }}
          </ng-container>
        </cc-dropdown-action>

        <cc-dropdown-action
          (click)="presentBusinessObjectEmbedDialog(); plusDropdownVisible = false"
          leadingIcon="icon-link"
        >
          <ng-container slot="title">
            {{ 'conversations-v4.composer.actions.more.bpi' | translate }}
          </ng-container>
        </cc-dropdown-action>

        <cc-dropdown-action
          (click)="attachFileFromCloverStorage(); plusDropdownVisible = false"
          leadingIcon="icon-files"
        >
          <ng-container slot="title">
            {{ 'conversations-v4.composer.actions.more.cloverFileStorage' | translate }}
          </ng-container>
        </cc-dropdown-action>

        <cc-dropdown-action
          (click)="fileUploader.click(); plusDropdownVisible = false"
          leadingIcon="icon-upload-file"
        >
          <ng-container slot="title">
            {{ 'conversations-v4.composer.actions.more.uploadFromComputer' | translate }}
          </ng-container>
        </cc-dropdown-action>
      </cc-dropdown>
    </ng-template>

    <div class="composer-actions__group-divider"></div>

    <ng-scrollbar orientation="horizontal">
      <div class="composer-actions__group-formatting-actions">
        <cc-rich-text-editor-bold-control [editors]="editors()" />
        <cc-rich-text-editor-italic-control [editors]="editors()" />
        <cc-rich-text-editor-strike-control [editors]="editors()" />
        <cc-rich-text-editor-underline-control [editors]="editors()" />

        <div class="composer-actions__group-divider"></div>

        <cc-rich-text-editor-font-family-control [editors]="editors()" />

        <div class="composer-actions__group-divider"></div>

        <cc-rich-text-editor-font-size-control [editors]="editors()" />

        <div class="composer-actions__group-divider"></div>

        <cc-rich-text-editor-bullet-list-control [editors]="editors()" />
        <cc-rich-text-editor-ordered-list-control [editors]="editors()" />
        <cc-rich-text-editor-link-control [editors]="editors()" />
        <cc-rich-text-editor-table-control [editors]="editors()" />
      </div>
    </ng-scrollbar>

    @if (composer.draft) {
      <div class="composer-actions__group-divider"></div>
      <app-button
        (click)="deleteDraft()"
        [size]="ButtonSize.Big"
        [type]="ButtonType.Ghost"
        [ccTooltip]="'conversations-v4.composer.actions.deleteDraft' | translate"
      >
        <i
          class="icon-delete"
          slot="ghost-icon"
        ></i>
      </app-button>

      <app-button
        (click)="createNewDraft()"
        [size]="ButtonSize.Big"
        [type]="ButtonType.Ghost"
        [ccTooltip]="'conversations-v4.composer.actions.newDraft' | translate"
      >
        <i
          class="icon-pencil-plus"
          slot="ghost-icon"
        ></i>
      </app-button>
    }
  </div>

  <div
    [class.composer-actions__draft-status--saved]="composer.draftSaveStatus === 'saved'"
    class="composer-actions__draft-status"
  >
    @if (composer.draftSaveStatus === 'saving') {
      {{ 'conversations-v4.composer.saving' | translate }}
    }
    @if (composer.draftSaveStatus === 'saved') {
      @if (draftSavedJustNow) {
        {{ 'conversations-v4.composer.savedJustNow' | translate }}
      } @else {
        {{
          'conversations-v4.composer.savedAgo'
            | translate
              : {
                  ago: composer.draft.updatedAt | ccDateFormatDistance: { strict: true },
                }
        }}
      }
    }
  </div>

  <app-button
    (click)="send()"
    [disabled]="sendDisabled"
    [size]="ButtonSize.Small"
    [type]="ButtonType.Primary"
  >
    {{ 'conversations-v4.composer.send' | translate }}
  </app-button>
</div>

<input
  #fileUploader
  (change)="uploadFile($event)"
  [multiple]="false"
  style="display: none"
  type="file"
/>
