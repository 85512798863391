import { inject, Injectable } from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';
import { Store } from '@ngxs/store';
import * as R from 'ramda';
import { filter, mergeMap, Observable, of, tap, timer } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { ExternalAccount } from '@conversations/workspaces/state/workspaces/workspaces-state.model';
import { PatchExternalAccount } from '@conversations/workspaces/state/workspaces/workspaces.actions';
import { WorkspacesSelectors } from '@conversations/workspaces/state/workspaces/workspaces.selectors';
import { HttpService } from '@core/services/http.service';

enum ExternalAccountSyncStatus {
  Created = 'Created',
  InProgress = 'InProgress',
  Completed = 'Completed',
  Failed = 'Failed',
  Cancelled = 'Cancelled',
}

interface ExternalAccountSyncResponse {
  externalAccountId: number;
  status: ExternalAccountSyncStatus;
  processed: number | undefined;
}

const pollIntervalMs = 3000;

@Injectable({
  providedIn: 'root',
})
export class AccountsSyncService {
  private readonly store = inject(Store);
  private readonly http = inject(HttpService);
  private readonly router = inject(Router);
  private readonly serializer = inject(UrlSerializer);

  initPolling(): Observable<void> {
    return this.store.select(WorkspacesSelectors.syncingExternalAccounts).pipe(
      distinctUntilChanged((a, b) => {
        const aIds = a.map((account) => account.id).sort();
        const bIds = b.map((account) => account.id).sort();
        return R.equals(aIds, bIds);
      }),
      switchMap((accounts: ExternalAccount[]) => {
        if (accounts.length === 0) return of([]);
        return timer(0, pollIntervalMs).pipe(map(() => accounts));
      }),
      filter((accounts: ExternalAccount[]) => accounts.length > 0),
      mergeMap((accounts: ExternalAccount[]) =>
        this.getSyncedThreadsCount(accounts.map((account) => account.id)).pipe(
          catchError(() => {
            return of([]);
          }),
        ),
      ),
      tap((syncUpdates: ExternalAccountSyncResponse[]) => {
        this.store.dispatch(
          syncUpdates.map(
            (update) =>
              new PatchExternalAccount(update.externalAccountId, {
                syncedThreads: update.processed || undefined,
              }),
          ),
        );
      }),
      map(() => undefined),
    );
  }

  private getSyncedThreadsCount(externalAccountIds: number[]): Observable<ExternalAccountSyncResponse[]> {
    const urlTree = this.router.createUrlTree(['api', 'workspaces', 'external-accounts', 'sync'], {
      queryParams: {
        externalAccountIds,
      },
    });

    const path = this.serializer.serialize(urlTree);
    return this.http.getV2<ExternalAccountSyncResponse[]>(path);
  }
}
