<div class="composer-recipients-selector-editor-row">
  <div class="composer-recipients-selector-editor-row__label">
    @switch (type) {
      @case ('to') {
        {{ 'conversations-v4.composer.recipientSelector.to' | translate }}:
      }
      @case ('cc') {
        {{ 'conversations-v4.composer.recipientSelector.cc' | translate }}:
      }
      @case ('bcc') {
        {{ 'conversations-v4.composer.recipientSelector.bcc' | translate }}:
      }
    }
  </div>

  <div
    (cdkDropListDropped)="handleRecipientDrop($event)"
    [cdkDropListData]="dropListData"
    [cdkDropListSortingDisabled]="true"
    cdkDropList
    cdkDropListOrientation="horizontal"
    class="composer-recipients-selector-editor-row__content"
  >
    @for (contact of participants; track contact.email; let i = $index) {
      <cc-composer-recipients-selector-contact-chip
        (@contactChipLeaveAnimation.done)="allowNextLeaveAnimation = false"
        (cdkDragEnded)="dragging = false"
        (cdkDragStarted)="dragging = true"
        (click)="$event.stopPropagation()"
        (edit)="editParticipant(i)"
        (remove)="removeParticipant(i)"
        @contactChipLeaveAnimation
        [@.disabled]="!allowNextLeaveAnimation"
        [attr.data-index]="i"
        [cdkDragData]="contact"
        [contact]="contact"
        [tooltipAlignment]="TooltipAlignment.Start"
        [ccTooltip]="'conversations-v4.composer.recipientSelector.dragToMove' | translate"
        cdkDrag
      />
    }

    <input
      #inputField
      (blur)="addPendingParticipant()"
      [formControl]="inputFormControl"
      [placeholder]="placeholder"
      [style.width.px]="inputContentWidth"
      class="composer-recipients-selector-editor-row__content-input"
      type="text"
    />

    <div
      (ccResize)="inputContentWidth = $event.newRect.width"
      class="composer-recipients-selector-editor-row__content-input-text-measurer"
    >
      {{ inputFormControl.value || '' }}
    </div>

    <div class="composer-recipients-selector-editor-row__content-drop-indicator">
      {{ 'conversations-v4.composer.recipientSelector.releaseToMoveHere' | translate }}
    </div>
  </div>
</div>

<ng-template
  [cdkConnectedOverlayOpen]="inputFocused && suggestions.length > 0"
  [cdkConnectedOverlayOrigin]="inputField"
  [cdkConnectedOverlayPositions]="dropdownPositionStrategy"
  cdkConnectedOverlay
>
  <cc-dropdown
    (mousedown)="$event.preventDefault()"
    [fakeFocus]="true"
    class="cc-recipients-selector-dropdown"
    maxHeight="180px"
    width="240px"
  >
    <cc-dropdown-text>
      <ng-container slot="title">
        {{ 'conversations-v4.composer.recipientSelector.knownContacts' | translate }}
      </ng-container>
    </cc-dropdown-text>

    @for (suggestion of suggestions; track suggestion.email) {
      <cc-dropdown-action (click)="addParticipantFromSuggestion(suggestion)">
        <ng-container slot="leading">
          <img
            [alt]="suggestion.name"
            [src]="suggestion.avatarUrl || getGenericAvatarUrl(suggestion)"
            class="suggestion-dropdown__avatar"
            height="40px"
            width="40px"
          />
        </ng-container>
        <ng-container slot="title"> {{ suggestion.name }}</ng-container>
        <ng-container slot="caption"> {{ suggestion.email }}</ng-container>
      </cc-dropdown-action>
    }
  </cc-dropdown>
</ng-template>
