<cc-select
  [style.--font-family]="fontFamily().payload || 'Inter, sans-serif'"
  [ccTooltip]="'richTextEditor.actions.fontFamily.title' | translate"
  [ngModel]="fontFamily()"
  (ngModelChange)="setFontFamily($event)"
  appearance="ghost"
>
  <ng-container ccOptions>
    <cc-dropdown-text>
      <ng-container slot="title">
        {{ 'richTextEditor.actions.fontFamily.title' | translate }}
      </ng-container>
    </cc-dropdown-text>

    @for (option of fontFamilyOptions; track option.id) {
      <cc-select-item
        class="cc-font-family-option-item"
        [style.--font-family]="option.payload || 'Inter, sans-serif'"
        [value]="option.title"
        [id]="option.id"
        [payload]="option.payload"
      />

      @if (option.id === 'default') {
        <cc-dropdown-divider />
      }
    }
  </ng-container>
</cc-select>
