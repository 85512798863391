import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cc-task-embed-modal-placeholder',
  standalone: true,
  templateUrl: './task-embed-modal-placeholder.component.html',
  styleUrls: ['./task-embed-modal-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule],
})
export class TaskEmbedModalPlaceholderComponent {
  @Input()
  type: 'empty' | 'loading' | 'error' = 'empty';
}
