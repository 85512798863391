<div class="account-already-connected-modal">
  <div class="account-already-connected-modal__title">
    {{ 'conversations-v4.workspaces.dialogs.alreadyConnected.title' | translate }}
  </div>

  <p class="account-already-connected-modal__description">
    {{ 'conversations-v4.workspaces.dialogs.alreadyConnected.message' | translate: { email: dialogData.email } }}
    <br />
    <br />
    {{ 'conversations-v4.workspaces.dialogs.alreadyConnected.notice' | translate }}
    <a href="https://help.ledgerset.io/article/76-private-and-team-workspaces">
      {{ 'conversations-v4.workspaces.dialogs.alreadyConnected.link' | translate }}
    </a>
  </p>

  <app-button
    (click)="dialogRef.close()"
    [size]="ButtonSize.Small"
    [type]="ButtonType.Secondary"
    class="account-already-connected-modal__button"
  >
    {{ 'common.buttons.ok' | translate }}
  </app-button>
</div>
