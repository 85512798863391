@if (failures$ | async; as failures) {
  <div class="accounts-failures-notice">
    <div class="accounts-failures-notice__title">
      {{ 'conversations-v4.workspaces.accountsFailuresNotice.title' | translate }}
    </div>

    <div class="accounts-failures-notice__issues">
      @if (failures.expired.length) {
        <app-button
          (click)="openSyncIssuesModal(failures.expired, ExternalAccountStatus.Expired)"
          [hasGhostText]="true"
          [size]="ButtonSize.Small"
          [type]="ButtonType.Ghost"
          [ccTooltip]="'conversations-v4.workspaces.accountsFailuresNotice.viewAuthIssues' | translate"
        >
          <i
            class="icon-sync-auth-error"
            slot="ghost-icon"
          ></i>
          <span slot="ghost-text">
            {{ failures.expired.length }}
          </span>
        </app-button>
      }

      @if (failures.failed.length) {
        <app-button
          (click)="openSyncIssuesModal(failures.failed, ExternalAccountStatus.Failed)"
          [hasGhostText]="true"
          [size]="ButtonSize.Small"
          [type]="ButtonType.Ghost"
          [ccTooltip]="'conversations-v4.workspaces.accountsFailuresNotice.viewSyncIssues' | translate"
        >
          <i
            class="icon-sync-error"
            slot="ghost-icon"
          ></i>
          <span slot="ghost-text">
            {{ failures.failed.length }}
          </span>
        </app-button>
      }
    </div>
  </div>
}
