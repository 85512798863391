export interface LabelColor {
  bg: string;
  fg: string;
}

export const labelColors: LabelColor[] = [
  {
    bg: '#E7E7E7',
    fg: '#464645',
  },
  {
    bg: '#C2C2C2',
    fg: '#FFFFFF',
  },
  {
    bg: '#B7CFF6',
    fg: '#0C3572',
  },
  {
    bg: '#4A86E7',
    fg: '#FFFFFF',
  },
  {
    bg: '#99D7E4',
    fg: '#0C3B44',
  },
  {
    bg: '#2EA2BB',
    fg: '#FFFFFF',
  },
  {
    bg: '#E3D6FF',
    fg: '#3C188D',
  },
  {
    bg: '#B999FF',
    fg: '#FFFFFF',
  },
  {
    bg: '#FCD3E1',
    fg: '#711A36',
  },
  {
    bg: '#F691B2',
    fg: '#994A64',
  },
  {
    bg: '#F1B2A7',
    fg: '#8A1B0B',
  },
  {
    bg: '#FB4C2F',
    fg: '#FFFFFF',
  },
  {
    bg: '#FFC7AF',
    fg: '#7A2E0C',
  },
  {
    bg: '#FE7537',
    fg: '#FFFFFF',
  },
  {
    bg: '#FFDEB5',
    fg: '#7A4707',
  },
  {
    bg: '#FFAE45',
    fg: '#FFFFFF',
  },
  {
    bg: '#FDEDC1',
    fg: '#464645',
  },
  {
    bg: '#FCEA84',
    fg: '#594C06',
  },
  {
    bg: '#EADCDE',
    fg: '#662F37',
  },
  {
    bg: '#CCA5AC',
    fg: '#FFFFFF',
  },
  {
    bg: '#B4EFD4',
    fg: '#0B4F31',
  },
  {
    bg: '#A2DBC1',
    fg: '#04512E',
  },
  {
    bg: '#42D691',
    fg: '#074328',
  },
  {
    bg: '#17A764',
    fg: '#FFFFFF',
  },
];
