<div class="label-picker">
  <app-textbox
    [formControl]="searchFormControl"
    [placeholder]="'labelPicker.searchPlaceholder' | translate"
    class="label-picker__search"
    icon="icon-search"
    width="100%"
  />

  <cc-dropdown-divider />

  <ng-scrollbar
    [style.max-height]="listMaxHeight"
    cdkScrollable
  >
    <div class="label-picker__items">
      @if (labelGroups$ | async; as groups) {
        @if (!groups.length) {
          <cc-dropdown-text>
            <ng-container slot="title">
              {{ 'labelPicker.noLabelsPlaceholder' | translate }}
            </ng-container>
          </cc-dropdown-text>
        }

        @for (group of groups; track group) {
          <cc-dropdown-text>
            <ng-container slot="title">
              @if (group.workspace.ownerType === WorkspaceOwnerType.User) {
                {{ 'conversations-v4.workspaces.titles.privateWorkspace' | translate }}
              }
              @if (group.workspace.ownerType === WorkspaceOwnerType.Team) {
                {{ group.workspace.name }}
              }
            </ng-container>
          </cc-dropdown-text>

          @for (label of group.labels; track label) {
            @switch (selectionMode) {
              @case ('single') {
                <cc-dropdown-action
                  (click)="selectLabel.emit(label)"
                  [active]="selectedLabelIds.includes(label.id)"
                >
                  <ng-container slot="leading">
                    <cc-label-icon [label]="label"></cc-label-icon>
                  </ng-container>

                  <ng-container slot="title">
                    {{ label.name }}
                  </ng-container>
                </cc-dropdown-action>
              }
              @case ('single-radio') {
                <app-radio
                  name="label-picker-item"
                  (change)="handleRadioChange($event, label)"
                  [checked]="selectedLabelIds.includes(label.id)"
                  [label]="label.name"
                  class="label-picker__items-radio"
                />
              }
              @case ('multiple') {
                <app-checkbox
                  (change)="handleCheckboxChange($event, label)"
                  [checked]="selectedLabelIds.includes(label.id)"
                  [label]="label.name"
                  class="label-picker__items-checkbox"
                />
              }
            }
          }
        }
      }
    </div>
  </ng-scrollbar>

  <cc-dropdown-divider />

  <cc-dropdown-action
    (click)="createLabel.emit(searchFormControl.value)"
    leadingIcon="icon-create-task"
  >
    <ng-container slot="title">
      {{ 'labelPicker.createLabel' | translate }}
    </ng-container>

    <ng-container slot="trailing">
      @if (searchFormControl.value) {
        {{ searchFormControl.value }}
      }
    </ng-container>
  </cc-dropdown-action>

  @if (showManageLabels) {
    @if (primaryWorkspace$ | async; as primaryWorkspace) {
      <cc-dropdown-action
        (click)="manageLabels.emit()"
        leadingIcon="icon-manage"
      >
        <ng-container slot="title">
          @if (primaryWorkspace.ownerType === WorkspaceOwnerType.User) {
            {{ 'labelPicker.manageLabels' | translate }}
          }
          @if (primaryWorkspace.ownerType === WorkspaceOwnerType.Team) {
            {{
              'labelPicker.manageWorkspaceLabels'
                | translate
                  : {
                      workspace: primaryWorkspace.name,
                    }
            }}
          }
        </ng-container>
      </cc-dropdown-action>
    }
  }
</div>
