import { CurrencyPipe, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { DateFormatDistancePipe, DateFormatPipe } from '@clover/core/pipes/date-format.pipe';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { TooltipAlignment } from '@design/overlays/tooltip/tooltip';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';

import type { BPIInvoice } from '../business-object';

@Component({
  selector: 'cc-bpi-invoice-drawer',
  standalone: true,
  imports: [ButtonComponent, TooltipDirective, NgOptimizedImage, DateFormatDistancePipe, DateFormatPipe, CurrencyPipe],
  templateUrl: './bpi-invoice-drawer.component.html',
  styleUrls: ['./bpi-invoice-drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BpiInvoiceDrawerComponent {
  @Input()
  invoice: BPIInvoice;

  @Output()
  closeDrawer = new EventEmitter<void>();

  @Output()
  selectInvoice = new EventEmitter<BPIInvoice>();

  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;
  protected readonly TooltipAlignment = TooltipAlignment;

  getStatusText(invoice: BPIInvoice): string {
    switch (invoice.status) {
      case 'paid':
        return 'Paid';
      case 'disputed':
        return 'Disputed';
      default:
        return 'Unknown';
    }
  }

  getStatusFgColor(invoice: BPIInvoice): string {
    switch (invoice.status) {
      case 'paid':
        return '#08A262';
      case 'disputed':
        return '#AB2345';
      default:
        return '#000000';
    }
  }

  getStatusBgColor(invoice: BPIInvoice): string {
    switch (invoice.status) {
      case 'paid':
        return '#D6F9EA';
      case 'disputed':
        return '#FBE4E9';
      default:
        return '#FFFFFF';
    }
  }
}
