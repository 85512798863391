<div class="composer-html-editor">
  <div
    #editor
    (input)="handleInput($event)"
    [contentEditable]="true"
    class="composer-html-editor__content"
  ></div>

  @if (htmlEmpty) {
    <div class="composer-html-editor__placeholder">
      {{ placeholder }}
    </div>
  }
</div>
