import { ChangeDetectionStrategy, Component, output, signal } from '@angular/core';

import { DropdownActionComponent } from '@design/overlays/dropdown/dropdown-action/dropdown-action.component';
import { DropdownComponent } from '@design/overlays/dropdown/dropdown.component';

import { TaskPreview } from './../../../../../../app/conversations-v4/tasks/tasks.model';

@Component({
  standalone: true,
  imports: [DropdownComponent, DropdownActionComponent],
  templateUrl: './task-autocomplete-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskAutocompleteDropdownComponent {
  tasks = signal<TaskPreview[]>([], {
    equal: (a, b) => a.length === b.length && a.every((task, index) => task.id === b[index].id),
  });

  selectTask = output<TaskPreview>();
}
