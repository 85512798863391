import { runInInjectionContext, effect, untracked } from '@angular/core';
import { assertInjector } from 'ngxtension/assert-injector';

function effectOnceIf(condition, execution, options) {
    const assertedInjector = assertInjector(effectOnceIf, options?.injector);
    return runInInjectionContext(assertedInjector, () => {
        const effectRef = effect((onCleanup) => {
            const hasCondition = condition();
            if (hasCondition) {
                untracked(() => execution(hasCondition, onCleanup));
                effectRef.destroy();
            }
        }, options);
        return effectRef;
    });
}

/**
 * Generated bundle index. Do not edit.
 */

export { effectOnceIf };

