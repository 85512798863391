@if (syncingAccounts$ | async; as accounts) {
  <div
    class="accounts-sync-notice"
    [class.accounts-sync-notice--active]="dropdownVisible"
    [class.cc-overlay-active]="dropdownVisible"
    #dropdownTrigger
    role="button"
    (click)="dropdownVisible = !dropdownVisible"
  >
    <div class="accounts-sync-notice__header">
      <div class="accounts-sync-notice__header-title cc-ellipsis-animation">
        {{ 'conversations-v4.workspaces.accountsSyncNotice.title' | translate }}
      </div>
      <div class="accounts-sync-notice__header-count">{{ syncingAccountsCount$ | async }} left</div>
    </div>

    <cc-progress-bar type="indeterminate" />
  </div>

  <ng-template
    (detach)="dropdownVisible = false"
    (overlayOutsideClick)="dropdownVisible = getOverlayVisibilityAfterOutsideClick($event, dropdownTrigger)"
    [cdkConnectedOverlayOpen]="dropdownVisible"
    [cdkConnectedOverlayOrigin]="dropdownTrigger"
    [cdkConnectedOverlayPositions]="dropdownPositionStrategy"
    cdkConnectedOverlay
  >
    <cc-dropdown
      [shadow]="false"
      width="230px"
    >
      <cc-dropdown-text>
        <ng-container slot="title">
          {{ 'conversations-v4.workspaces.accountsSyncNotice.accountsInSync' | translate }}
        </ng-container>
      </cc-dropdown-text>

      @for (account of accounts; track account.id) {
        <cc-dropdown-action (click)="navigateToWorkspaceSettings(account); dropdownVisible = false">
          <ng-container slot="title">{{ account.email }}</ng-container>
          <ng-container slot="caption">
            @if (account.status === ExternalAccountStatus.Syncing && account.syncedThreads) {
              <span class="cc-ellipsis-animation">
                @if (account.syncedThreads === 1) {
                  {{ 'conversations-v4.workspaces.accountsSyncNotice.threadsSingularSynced' | translate }}
                } @else {
                  {{
                    'conversations-v4.workspaces.accountsSyncNotice.threadsPluralSynced'
                      | translate
                        : {
                            count: account.syncedThreads,
                          }
                  }}
                }
              </span>
            } @else {
              <span class="cc-ellipsis-animation">
                {{ 'conversations-v4.workspaces.accountsSyncNotice.connecting' | translate }}
              </span>
            }
          </ng-container>
        </cc-dropdown-action>
      }
    </cc-dropdown>
  </ng-template>
}
