<div class="link-editor">
  <div class="link-editor__header">
    <div class="link-editor__header-title">
      @switch (mode) {
        @case ('insert') {
          {{ 'richTextEditor.actions.link.insertLink' | translate }}
        }
        @case ('edit') {
          {{ 'richTextEditor.actions.link.editLink' | translate }}
        }
      }
    </div>

    <div class="link-editor__header-close"></div>

    <app-button
      (click)="dialogRef.close()"
      [ccTooltip]="'common.buttons.close' | translate"
      [size]="ButtonSize.Small"
      [type]="ButtonType.Ghost"
    >
      <i
        class="icon-close"
        slot="ghost-icon"
      ></i>
    </app-button>
  </div>

  <div
    [formGroup]="linkForm"
    class="link-editor__body"
  >
    <app-textbox
      [label]="'richTextEditor.actions.link.linkTitle' | translate"
      [placeholder]="'richTextEditor.actions.link.linkTitlePlaceholder' | translate"
      formControlName="title"
      width="100%"
    >
    </app-textbox>

    <app-textbox
      [errorMessage]="linkErrorMessage"
      [label]="'richTextEditor.actions.link.linkUrl' | translate"
      [placeholder]="'richTextEditor.actions.link.linkUrlPlaceholder' | translate"
      [type]="TextboxType.Url"
      formControlName="href"
      width="100%"
    >
    </app-textbox>
  </div>

  <div class="link-editor__footer">
    <app-button
      (click)="dialogRef.close()"
      [size]="ButtonSize.Small"
      [type]="ButtonType.Secondary"
    >
      {{ 'common.buttons.cancel' | translate }}
    </app-button>

    <app-button
      (click)="submit()"
      [disabled]="linkForm.invalid"
      [size]="ButtonSize.Small"
      [type]="ButtonType.Primary"
    >
      {{ 'common.buttons.ok' | translate }}
    </app-button>
  </div>
</div>
