import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import type { SelectItem } from '@design/forms/select/select-item/select-item';
import { SelectItemComponent } from '@design/forms/select/select-item/select-item.component';
import { SelectComponent } from '@design/forms/select/select.component';
import { DropdownDividerComponent } from '@design/overlays/dropdown/dropdown-divider/dropdown-divider.component';
import { DropdownTextComponent } from '@design/overlays/dropdown/dropdown-text/dropdown-text.component';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';

import { RichTextEditorBaseControlComponent } from '../base-action.component';

@Component({
  selector: 'cc-rich-text-editor-font-family-control',
  standalone: true,
  imports: [
    SelectComponent,
    SelectItemComponent,
    FormsModule,
    DropdownTextComponent,
    DropdownDividerComponent,
    TranslateModule,
    TooltipDirective,
  ],
  templateUrl: './font-family-control.component.html',
  styleUrls: ['./font-family-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RichTextEditorFontFamilyControlComponent extends RichTextEditorBaseControlComponent {
  fontFamily = computed(() => {
    this.selectionNotifier.listen();

    return (
      this.fontFamilyOptions.find((option) =>
        this.activeEditor()?.isActive('textStyle', { fontFamily: option.payload }),
      ) ?? this.defaultFontFamily
    );
  });

  private readonly translate = inject(TranslateService);

  protected get fontFamilyOptions(): SelectItem<string>[] {
    return [
      {
        title: this.translate.instant('richTextEditor.actions.fontFamily.default'),
        id: 'default',
        payload: '',
      },
      {
        title: this.translate.instant('richTextEditor.actions.fontFamily.arial'),
        id: 'arial',
        payload: 'Arial, sans-serif',
      },
      {
        title: this.translate.instant('richTextEditor.actions.fontFamily.helvetica'),
        id: 'helvetica',
        payload: 'Helvetica, sans-serif',
      },
      {
        title: this.translate.instant('richTextEditor.actions.fontFamily.timesNewRoman'),
        id: 'times-new-roman',
        payload: 'Times New Roman, serif',
      },
      {
        title: this.translate.instant('richTextEditor.actions.fontFamily.georgia'),
        id: 'georgia',
        payload: 'Georgia, serif',
      },
      {
        title: this.translate.instant('richTextEditor.actions.fontFamily.tahoma'),
        id: 'tahoma',
        payload: 'Tahoma, sans-serif',
      },
      {
        title: this.translate.instant('richTextEditor.actions.fontFamily.verdana'),
        id: 'verdana',
        payload: 'Verdana, sans-serif',
      },
      {
        title: this.translate.instant('richTextEditor.actions.fontFamily.courierNew'),
        id: 'courier-new',
        payload: 'Courier New, monospace',
      },
      {
        title: this.translate.instant('richTextEditor.actions.fontFamily.trebuchetMS'),
        id: 'trebuchet-ms',
        payload: 'Trebuchet MS, sans-serif',
      },
    ];
  }

  protected readonly defaultFontFamily = this.fontFamilyOptions[0];

  setFontFamily(option: SelectItem<string>): void {
    if (!this.activeEditor()) return;

    option.id !== this.defaultFontFamily.id
      ? this.activeEditor().chain().focus().setFontFamily(option.payload).run()
      : this.activeEditor().chain().focus().unsetFontFamily().run();

    this.selectionNotifier.notify();
  }
}
