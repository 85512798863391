import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Action, State, StateContext } from '@ngxs/store';
import { insertItem, patch, removeItem } from '@ngxs/store/operators';
import * as R from 'ramda';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { defaultLabelsState, LabelsStateModel } from '@conversations/workspaces/state/labels/labels-state.model';
import {
  CreateLabel,
  DeleteLabel,
  LoadLabel,
  LoadLabels,
  UpdateLabel,
} from '@conversations/workspaces/state/labels/labels.actions';
import { LabelsService } from '@conversations/workspaces/state/labels/labels.service';
import { upsertItem } from '@core/helpers/custom-state-operators';
import { CdkPortalService } from '@core/services/cdk-portal.service';
import { ToastType } from '@design/overlays/toast/toast';

@State<LabelsStateModel>({
  name: 'labels',
  defaults: defaultLabelsState,
})
@Injectable()
export class LabelsState {
  private readonly labelsService = inject(LabelsService);
  private readonly portalService = inject(CdkPortalService);
  private readonly translate = inject(TranslateService);

  @Action(LoadLabels)
  loadLabels(ctx: StateContext<LabelsStateModel>, { workspaceIds }: LoadLabels): Observable<void> {
    ctx.patchState({ loadingStatus: 'loading' });

    return forkJoin(workspaceIds.map((workspaceId) => this.labelsService.getWorkspaceLabels(workspaceId))).pipe(
      tap((workspacesLabels) => {
        ctx.patchState({ labels: R.flatten(workspacesLabels), loadingStatus: 'loaded' });
      }),
      catchError(() => {
        ctx.patchState({ loadingStatus: 'error' });
        return of();
      }),
      map(() => undefined),
    );
  }

  @Action(LoadLabel)
  loadLabel(ctx: StateContext<LabelsStateModel>, { workspaceId, labelId }: LoadLabel): Observable<void> {
    return this.labelsService.getWorkspaceLabel(workspaceId, labelId).pipe(
      tap((label) => {
        ctx.setState(
          patch<LabelsStateModel>({
            labels: upsertItem(
              (existingLabel) => existingLabel.id === label.id && existingLabel.workspaceId === label.workspaceId,
              label,
            ),
          }),
        );
      }),
      map(() => undefined),
    );
  }

  @Action(CreateLabel)
  createLabel(ctx: StateContext<LabelsStateModel>, { workspaceId, label }: CreateLabel): Observable<void> {
    return this.labelsService.createLabel(workspaceId, label).pipe(
      tap((createdLabel) => {
        this.portalService.presentToast(
          this.translate.instant('conversations-v4.workspaces.settings.labels.toasts.created'),
          ToastType.Success,
        );

        ctx.setState(
          patch<LabelsStateModel>({
            labels: insertItem(createdLabel),
          }),
        );
      }),
      catchError(() => {
        this.portalService.presentToast(
          this.translate.instant('conversations-v4.workspaces.settings.labels.toasts.failedToCreate'),
          ToastType.Error,
        );
        return of();
      }),
      map(() => undefined),
    );
  }

  @Action(UpdateLabel)
  updateLabel(ctx: StateContext<LabelsStateModel>, { workspaceId, labelId, newValues }: UpdateLabel): Observable<void> {
    const existingLabel = ctx.getState().labels.find((label) => label.id === labelId);
    const labelToUpdate = {
      ...existingLabel,
      ...newValues,
    };

    return this.labelsService.updateLabel(workspaceId, labelId, labelToUpdate).pipe(
      tap((updatedLabel) => {
        this.portalService.presentToast(
          this.translate.instant('conversations-v4.workspaces.settings.labels.toasts.updated'),
          ToastType.Success,
        );

        ctx.setState(
          patch<LabelsStateModel>({
            labels: upsertItem((label) => label.id === updatedLabel.id, updatedLabel),
          }),
        );
      }),
      catchError(() => {
        this.portalService.presentToast(
          this.translate.instant('conversations-v4.workspaces.settings.labels.toasts.failedToUpdate'),
          ToastType.Error,
        );
        return of();
      }),
      map(() => undefined),
    );
  }

  @Action(DeleteLabel)
  deleteLabel(ctx: StateContext<LabelsStateModel>, { workspaceId, labelId }: DeleteLabel): Observable<void> {
    return this.labelsService.deleteLabel(workspaceId, labelId).pipe(
      tap(() => {
        this.portalService.presentToast(
          this.translate.instant('conversations-v4.workspaces.settings.labels.toasts.deleted'),
          ToastType.Success,
        );

        ctx.setState(
          patch<LabelsStateModel>({
            labels: removeItem((label) => label.id === labelId),
          }),
        );
      }),
      catchError(() => {
        this.portalService.presentToast(
          this.translate.instant('conversations-v4.workspaces.settings.labels.toasts.failedToDelete'),
          ToastType.Error,
        );
        return of();
      }),
      map(() => undefined),
    );
  }
}
