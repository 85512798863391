import type { AssistantThreadContext } from './assistant.entities';

export class SendMessage {
  static readonly type = '[Assistant] Send Message';

  constructor(
    public threadId: string | undefined, // If undefined, a new thread will be created automatically
    public message: string,
    public context?: AssistantThreadContext, // Only used when creating a new thread
  ) {}
}

export class DeleteThread {
  static readonly type = '[Assistant] Delete Thread';

  constructor(public threadId: string) {}
}
