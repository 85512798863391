<div class="bpi-order-table">
  <div class="bpi-order-table__content">
    <div class="bpi-order-table__content-header">
      <app-textbox
        [icon]="'icon-search'"
        [type]="TextboxType.Text"
        class="bpi-order-table__content-header-search"
        placeholder="Search orders..."
        width="100%"
      >
      </app-textbox>

      <app-button
        [size]="ButtonSize.Small"
        [type]="ButtonType.Secondary"
      >
        All Orders
        <i
          class="icon-expand"
          slot="end-icon"
        ></i>
      </app-button>
    </div>

    <table class="bpi-order-table__content-table">
      <thead>
        <tr>
          <th>PO #</th>
          <th style="width: 160px">Age</th>
          <th style="width: 54px">Sender's Company</th>
          <th style="width: 54px">Assignee</th>
        </tr>
      </thead>

      <tbody>
        @for (order of MOCK_BPI_ORDERS; track order.id) {
          <tr
            (click)="showInDrawer(order)"
            [class.active-row]="order.id === activeOrderId"
          >
            <td>
              <div class="po-details">
                <div class="po-details__header">
                  <div class="po-details__header-title">
                    {{ order.poNumber }}
                  </div>

                  <div
                    [style.background-color]="getStatusBgColor(order)"
                    [style.color]="getStatusFgColor(order)"
                    class="po-details__header-status"
                  >
                    {{ getStatusText(order) }}
                  </div>
                </div>

                <div class="po-details__amount">Total PO amount: {{ order.totalPOAmount | currency: 'USD' }}</div>
              </div>
            </td>
            <td>{{ getOrderAge(order) }}</td>
            <td>
              <img
                [alt]="order.retailer"
                [ccTooltip]="order.retailer"
                class="company-avatar"
                height="48"
                ngSrc="https://picsum.photos/seed/1/200"
                width="48"
              />
            </td>
            <td>
              <img
                [alt]="order.supplier"
                [ccTooltip]="order.supplier"
                class="company-avatar"
                height="48"
                ngSrc="https://picsum.photos/seed/2/200"
                width="48"
              />
            </td>
          </tr>
        }
      </tbody>
    </table>
  </div>

  @if (activeOrder) {
    <div
      @drawer
      class="bpi-order-table__drawer"
    >
      <cc-bpi-order-drawer
        (closeDrawer)="showInDrawer(null)"
        (selectOrder)="selectOrder.emit($event)"
        class="bpi-order-table__drawer-inner"
        [order]="activeOrder"
      ></cc-bpi-order-drawer>
    </div>
  }
</div>
