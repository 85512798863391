import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  output,
} from '@angular/core';

import { sanitize } from '@core/helpers/sanitize-email/sanitizer';

@Component({
  selector: 'cc-composer-html-editor',
  standalone: true,
  templateUrl: './composer-html-editor.component.html',
  styleUrls: ['./composer-html-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.ShadowDom,
  imports: [],
})
export class ComposerHtmlEditorComponent implements OnInit, OnChanges {
  @Input()
  html: string;

  @Input()
  placeholder: string;

  htmlChange = output<string>();

  @ViewChild('editor', { static: true, read: ElementRef })
  editor: ElementRef<HTMLDivElement>;

  private readonly cdr = inject(ChangeDetectorRef);

  private skipUpdate = false;

  get htmlEmpty(): boolean {
    return this._htmlEmpty(this.html);
  }

  ngOnInit() {
    this.updateContent();
  }

  ngOnChanges(): void {
    if (this.skipUpdate) {
      this.skipUpdate = false;
      return;
    }

    this.updateContent();
  }

  updateContent(): void {
    this.editor.nativeElement.innerHTML = sanitize(this.html);
    this.cdr.detectChanges();
  }

  handleInput(event: Event): void {
    const html: string = event.target['innerHTML'];
    const isEmpty = this._htmlEmpty(html);

    if (isEmpty) {
      this.html = '';
      this.updateContent();
    } else {
      this.html = html;
    }

    this.htmlChange.emit(this.html);

    this.skipUpdate = true;
  }

  private _htmlEmpty(html: string): boolean {
    if (!html) return true;
    const plainText = html.replace(/<[^>]*>/g, '');
    return !plainText.trim();
  }
}
