/* eslint-disable import/order */
import { Routes } from '@angular/router';
import { provideStates } from '@ngxs/store';

import { NetworkService as LegacyNetworkService } from '@clover/network/network.service';
import { TasksService as LegacyTasksService } from '@clover/tasks/tasks.service';
import { ConversationsPageComponent } from '@conversations/conversations-page.component';
import {
  WORKSPACE_SETTINGS_MODAL_OUTLET,
  workspaceSettingsModalRoutes,
} from '@conversations/workspaces/modals/workspace-settings-modal/routes';
import { workspacesResolver } from '@conversations/workspaces/workspaces.resolver';

// States has to be imported last due to a bug in @ngxs/store
// @see: https://github.com/ngxs/store/issues/1631
// This comment is here to prevent import sorter from reordering the imports
//
// TODO (Oleksandr D.): Submit another issue to the @ngxs/store repository
//                      (mentioned one is closed and their team does not seem
//                      to see a problem in this) or change loading approach to
//                      avoid such issues.
//
// This also applies to tasks, products, workflows modules,
// and other ones that preload initial data in states using route resolvers.
import { AssistantState } from '@clover/assistant/state/assistant.state';
import { ComposersState } from '@conversations/composer/state/composers/composers.state';
import { ConversationState } from '@conversations/conversation/state/conversation/conversation.state';
import { ConversationsState } from '@conversations/conversations/state/conversations/conversations.state';
import { LabelsState } from '@conversations/workspaces/state/labels/labels.state';
import { WorkspacesState } from '@conversations/workspaces/state/workspaces/workspaces.state';
import { SignaturesState } from './workspaces/state/signatures/signatures.state';

export const CONVERSATIONS_BASE_URL = 'conversations-v4';

export const conversationsPageRoutes: Routes = [
  {
    path: '',
    component: ConversationsPageComponent,
    providers: [
      provideStates([
        WorkspacesState,
        LabelsState,
        SignaturesState,
        ConversationsState,
        ConversationState,
        ComposersState,
        AssistantState,
      ]),
      // TODO (Oleksandr D.): This is part of legacy code, should be rewritten in the future.
      // This service is used inside the legacy task re-route component, which is used inside the legacy modal service
      // inside new standalone task-card component. This is a temporary solution.
      LegacyTasksService,
      LegacyNetworkService,
    ],
    resolve: {
      workspacesState: workspacesResolver,
    },
    children: [
      {
        path: ':workspaceAlias',
        loadChildren: () =>
          import('@conversations/conversations/routes').then(({ conversationsRoutes }) => conversationsRoutes),
      },
      {
        path: ':workspaceAlias',
        outlet: WORKSPACE_SETTINGS_MODAL_OUTLET,
        children: workspaceSettingsModalRoutes,
      },
    ],
  },
];
