import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { RouterLinkWithHref, RouterOutlet } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { tap } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { RouterSelectors } from '@clover/custom-router-state-serializer';
import { CreateButtonComponent } from '@conversations/create-button/create-button.component';
import { OverlayComposersComponent } from '@conversations/overlay-composers/overlay-composers.component';
import { CONVERSATIONS_BASE_URL } from '@conversations/routes';
import { WORKSPACE_SETTINGS_MODAL_OUTLET } from '@conversations/workspaces/modals/workspace-settings-modal/routes';
import { AccountsSyncService } from '@conversations/workspaces/state/workspaces/accounts-sync.service';
import { getFallbackWorkspaceAlias } from '@conversations/workspaces/state/workspaces/workspaces-state.helpers';
import { WorkspacesSelectors } from '@conversations/workspaces/state/workspaces/workspaces.selectors';
import { WorkspacesComponent } from '@conversations/workspaces/workspaces.component';
import { PageWithSubnavComponent } from '@design/layout/page-with-subnav/page-with-subnav.component';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    RouterOutlet,
    WorkspacesComponent,
    RouterLinkWithHref,
    OverlayComposersComponent,
    CreateButtonComponent,
    PageWithSubnavComponent,
  ],
  templateUrl: './conversations-page.component.html',
  styleUrls: ['./conversations-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationsPageComponent implements OnInit {
  protected readonly WORKSPACE_SETTINGS_MODAL_OUTLET = WORKSPACE_SETTINGS_MODAL_OUTLET;

  private readonly store = inject(Store);
  private readonly accountsSyncService = inject(AccountsSyncService);

  ngOnInit(): void {
    this.healRoute();
    this.accountsSyncService.initPolling().pipe(untilDestroyed(this)).subscribe();
  }

  private healRoute(): void {
    this.store
      .select(RouterSelectors.state)
      .pipe(
        untilDestroyed(this),
        map(({ params, segments }) => {
          const [baseSegment] = segments;
          return baseSegment.path === CONVERSATIONS_BASE_URL && !params['workspaceAlias'];
        }),
        distinctUntilChanged(),
        tap((missingWorkspaceInUrl) => {
          const workspaces = this.store.selectSnapshot(WorkspacesSelectors.workspaces);

          if (missingWorkspaceInUrl && workspaces)
            this.store.dispatch(
              new Navigate([CONVERSATIONS_BASE_URL, getFallbackWorkspaceAlias(workspaces)], undefined, {
                replaceUrl: true,
              }),
            );
        }),
      )
      .subscribe();
  }
}
