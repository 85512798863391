import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'cc-progress-bar',
  standalone: true,
  templateUrl: './progress-bar.component.html',
  styleUrl: './progress-bar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent {
  @Input()
  progress: number;

  @Input()
  type: 'determinate' | 'indeterminate' = 'determinate';

  @Input()
  height: number = 6;
}
