import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  effect,
  HostBinding,
  inject,
  Injector,
  input,
  output,
  untracked,
  type OnDestroy,
  type OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { Editor } from '@tiptap/core';
import { debounceTime, distinctUntilChanged, filter, map, pairwise, switchMap, tap } from 'rxjs';

import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { withBold } from '@design/forms/rich-text-editor/extensions/bold/bold.extension';
import { withBulletList } from '@design/forms/rich-text-editor/extensions/bullet-list/bullet-list.extension';
import { createExtensionKit } from '@design/forms/rich-text-editor/extensions/create-extension-kit';
import { withFontFamily } from '@design/forms/rich-text-editor/extensions/font-family/font-family.extension';
import { withFontSize } from '@design/forms/rich-text-editor/extensions/font-size/font-size.extension';
import { withImage } from '@design/forms/rich-text-editor/extensions/image/image.extension';
import { withItalic } from '@design/forms/rich-text-editor/extensions/italic/italic.extension';
import { withLink } from '@design/forms/rich-text-editor/extensions/link/link.extension';
import { withOrderedList } from '@design/forms/rich-text-editor/extensions/ordered-list/ordered-list.extension';
import { withPlaceholder } from '@design/forms/rich-text-editor/extensions/placeholder/placeholder.extension';
import { withStrike } from '@design/forms/rich-text-editor/extensions/strike/strike.extension';
import { withTable } from '@design/forms/rich-text-editor/extensions/table/table.extension';
import { withTaskAutocomplete } from '@design/forms/rich-text-editor/extensions/task-autocomplete/task-autocomplete.extension';
import { withUnderline } from '@design/forms/rich-text-editor/extensions/underline/underline.extension';
import { RichTextEditorComponent } from '@design/forms/rich-text-editor/rich-text-editor.component';
import { TooltipAlignment } from '@design/overlays/tooltip/tooltip';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';

import { SaveDraft, SetSignature } from '../state/composers/composers.actions';

@Component({
  selector: 'cc-composer-signature-input',
  standalone: true,
  imports: [ButtonComponent, TooltipDirective, ReactiveFormsModule, RichTextEditorComponent, TranslateModule],
  templateUrl: './composer-signature-input.component.html',
  styleUrl: './composer-signature-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComposerSignatureInputComponent implements OnInit, OnDestroy {
  composerId = input.required<string>();
  signature = input<string>('');

  focusIn = output<void>();

  private readonly injector = inject(Injector);

  readonly editor = new Editor({
    content: this.signature(),
    extensions: [
      createExtensionKit(
        withBold(),
        withItalic(),
        withStrike(),
        withUnderline(),
        withFontFamily(),
        withFontSize(),
        withBulletList(),
        withOrderedList(),
        withLink(this.injector),
        withTable(),
        withImage(this.injector),
        withTaskAutocomplete(this.injector),
        withPlaceholder(inject(TranslateService).instant('conversations-v4.composer.signatureInput.placeholder')),
      ),
    ],
  });

  protected collapsed = true;
  protected signatureFormControl = new FormControl<string>(this.signature());

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;
  protected readonly TooltipAlignment = TooltipAlignment;

  private readonly store = inject(Store);
  private readonly destroyRef = inject(DestroyRef);

  @HostBinding('class.cc-expanded')
  get expanded(): boolean {
    return !this.collapsed;
  }

  constructor() {
    effect(() => {
      const signature = this.signature();

      untracked(() => {
        this.signatureFormControl.setValue(signature, { emitEvent: false });
      });
    });
  }

  ngOnInit(): void {
    this.signatureFormControl.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        pairwise(),
        filter(([prevSignature, signature]) => signature !== prevSignature),
        map(([, signature]) => signature),
        tap((signature) => {
          this.store.dispatch(new SetSignature(this.composerId(), signature));
        }),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(() => this.store.dispatch(new SaveDraft(this.composerId()))),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }
}
