import { StateOperator } from '@ngxs/store';
import { compose, ExistingState, iif, insertItem, NoInfer, patch, Predicate, updateItem } from '@ngxs/store/operators';

export function upsertItem<T>(selector: number | Predicate<T>, upsertValue: NoInfer<T>): StateOperator<T[]> {
  return compose<T[]>(
    (items) => <T[]>(items || []),
    iif<T[]>(
      (items) => Number(selector) === selector,
      iif<T[]>(
        (items) => (selector as any) < items.length,
        updateItem(selector, patch(upsertValue)),
        insertItem(upsertValue, <number>selector),
      ),
      iif<T[]>(
        (items) => items.some(<Predicate<T>>selector),
        updateItem(selector, patch(upsertValue)),
        insertItem(upsertValue),
      ),
    ),
  );
}

export function prepend<T>(items: NoInfer<T[]>): StateOperator<T[]> {
  return function prependOperator(existing: ExistingState<T[]>): T[] {
    // If `items` is `undefined` or `null` or `[]` but `existing` is provided
    // just return `existing`
    const itemsNotProvidedButExistingIs = (!items || !items.length) && existing;
    if (itemsNotProvidedButExistingIs) {
      return existing as unknown as T[];
    }

    if (Array.isArray(existing)) {
      return (items as unknown as ExistingState<T[]>).concat(existing);
    }

    // For example if some property is added dynamically
    // and didn't exist before thus it's not `ArrayLike`
    return items as unknown as T[];
  };
}
