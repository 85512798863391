<ng-content />

<div
  class="cc-resize-handles"
  [class.cc-resize-handles--resizing]="currentResizeType !== 'none'"
>
  <div
    [ccTooltip]="'richTextEditor.actions.image.bothTooltip' | translate"
    [tooltipDisabled]="currentResizeType !== 'none'"
    class="cc-resize-handles__both"
    (mousedown)="startResize('both', $event)"
  ></div>

  <div
    [ccTooltip]="'richTextEditor.actions.image.widthTooltip' | translate"
    [tooltipDisabled]="currentResizeType !== 'none'"
    class="cc-resize-handles__width"
    (mousedown)="startResize('width', $event)"
  ></div>

  <div
    [ccTooltip]="'richTextEditor.actions.image.heightTooltip' | translate"
    [tooltipDisabled]="currentResizeType !== 'none'"
    class="cc-resize-handles__height"
    (mousedown)="startResize('height', $event)"
  ></div>

  <div
    [ccTooltip]="'richTextEditor.actions.image.radiusTooltip' | translate"
    [tooltipDisabled]="currentResizeType !== 'none'"
    class="cc-resize-handles__radius"
    [style.--offset.px]="imageRadius"
    (mousedown)="startResize('radius', $event)"
  ></div>
</div>
