<div class="new-label-modal">
  <div class="new-label-modal__header">
    <div class="new-label-modal__header-title">
      {{
        editMode
          ? ('conversations-v4.workspaces.dialogs.labelEditor.editLabel' | translate)
          : ('conversations-v4.workspaces.dialogs.labelEditor.newLabel' | translate)
      }}
    </div>
  </div>

  <div
    [formGroup]="formGroup"
    class="new-label-modal__body"
  >
    <div class="new-label-modal__body-location">
      <div class="new-label-modal__body-location-title">
        {{ 'conversations-v4.workspaces.dialogs.labelEditor.location.title' | translate }}
      </div>

      <!-- TODO (Oleksandr D.): rewrite to use `cc-select` component -->
      <div
        #workspaceSelect
        (click)="workspaceSelectDropdownOpen = !workspaceSelectDropdownOpen"
        (keydown.enter)="workspaceSelectDropdownOpen = !workspaceSelectDropdownOpen"
        class="new-label-modal__body-location-select"
        tabindex="0"
      >
        <div class="new-label-modal__body-location-select-name">
          @if (selectedWorkspace$ | async; as workspace) {
            {{ workspace.name }}
          } @else {
            {{ 'conversations-v4.workspaces.dialogs.labelEditor.location.placeholder' | translate }}
          }
        </div>

        <i
          [class.icon-collapse]="workspaceSelectDropdownOpen"
          [class.icon-expand]="!workspaceSelectDropdownOpen"
          class="new-label-modal__body-location-select-toggle"
        ></i>
      </div>
    </div>

    <app-textbox
      [errorMessage]="
        isNameUnique ? null : ('conversations-v4.workspaces.dialogs.labelEditor.name.uniqueError' | translate)
      "
      [label]="'conversations-v4.workspaces.dialogs.labelEditor.name.title' | translate"
      [placeholder]="'conversations-v4.workspaces.dialogs.labelEditor.name.placeholder' | translate"
      formControlName="name"
      width="100%"
    >
    </app-textbox>

    <app-toggle
      [label]="'conversations-v4.workspaces.dialogs.labelEditor.showInNav.title' | translate"
      [tooltip]="'conversations-v4.workspaces.dialogs.labelEditor.showInNav.tooltip' | translate"
      formControlName="showInNavigation"
    >
    </app-toggle>

    <div class="new-label-modal__body-color">
      <div class="new-label-modal__body-color-title">
        {{ 'conversations-v4.workspaces.dialogs.labelEditor.color.title' | translate }}
      </div>

      <div class="new-label-modal__body-color-picker">
        @for (labelColor of LABEL_COLORS; track labelColor) {
          <div
            (keydown.enter)="selectLabelColor(labelColor)"
            (click)="selectLabelColor(labelColor)"
            [class.new-label-modal__body-color-picker-item--selected]="isLabelColorSelected(labelColor)"
            [style.--label-bg]="labelColor.bg"
            [style.--label-fg]="labelColor.fg"
            tabindex="0"
            class="new-label-modal__body-color-picker-item"
          >
            @if (!isLabelColorSelected(labelColor)) {
              <div class="new-label-modal__body-color-picker-item-text">a</div>
            } @else {
              <i class="new-label-modal__body-color-picker-item-selected-icon icon-check-small"></i>
            }
          </div>
        }
      </div>
    </div>
  </div>

  <div class="new-label-modal__footer">
    <app-button
      (click)="cancel()"
      [size]="ButtonSize.Small"
      [type]="ButtonType.Secondary"
    >
      {{ 'common.buttons.cancel' | translate }}
    </app-button>

    <app-button
      (click)="submitLabel()"
      [disabled]="!canSubmit"
      [size]="ButtonSize.Small"
      [type]="ButtonType.Primary"
    >
      {{
        editMode
          ? ('conversations-v4.workspaces.dialogs.labelEditor.saveChanges' | translate)
          : ('conversations-v4.workspaces.dialogs.labelEditor.createLabel' | translate)
      }}
    </app-button>
  </div>

  <div class="new-label-modal__close-button">
    <app-button
      (click)="cancel()"
      [ccTooltip]="'common.buttons.close' | translate"
      [size]="ButtonSize.Small"
      [type]="ButtonType.Ghost"
    >
      <i
        class="icon-close"
        slot="ghost-icon"
      ></i>
    </app-button>
  </div>
</div>

<ng-template
  (detach)="workspaceSelectDropdownOpen = false"
  (overlayOutsideClick)="workspaceSelectDropdownOpen = getOverlayVisibilityAfterOutsideClick($event, workspaceSelect)"
  [cdkConnectedOverlayOpen]="workspaceSelectDropdownOpen"
  [cdkConnectedOverlayOrigin]="workspaceSelect"
  [cdkConnectedOverlayPositions]="workspaceSelectDropdownPositionStrategy"
  cdkConnectedOverlay
>
  <cc-dropdown>
    @if (privateWorkspace$ | async; as privateWorkspace) {
      <cc-dropdown-text>
        <ng-container slot="title">
          {{ 'conversations-v4.workspaces.titles.privateWorkspace' | translate }}
        </ng-container>
      </cc-dropdown-text>

      <cc-dropdown-action
        [selectable]="true"
        (click)="changeLocation(privateWorkspace); workspaceSelectDropdownOpen = false"
        [selected]="privateWorkspace.id === locationFormControl.value"
      >
        <ng-container slot="title">{{ privateWorkspace.name }}</ng-container>
      </cc-dropdown-action>
    }

    @if (teamWorkspaces$ | async; as teamWorkspaces) {
      @if (teamWorkspaces.length) {
        <cc-dropdown-text>
          <ng-container slot="title">
            {{ 'conversations-v4.workspaces.titles.teamWorkspaces' | translate }}
          </ng-container>
        </cc-dropdown-text>

        @for (teamWorkspace of teamWorkspaces; track teamWorkspace.id) {
          <cc-dropdown-action
            (click)="changeLocation(teamWorkspace); workspaceSelectDropdownOpen = false"
            [selectable]="true"
            [selected]="teamWorkspace.id === locationFormControl.value"
          >
            <ng-container slot="title">{{ teamWorkspace.name }}</ng-container>
          </cc-dropdown-action>
        }
      }
    }
  </cc-dropdown>
</ng-template>
