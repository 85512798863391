import { Dialog } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnInit,
  output,
  QueryList,
  ViewChildren,
  type Signal,
} from '@angular/core';
import { ActivatedRoute, IsActiveMatchOptions, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { Navigate, RouterNavigated } from '@ngxs/router-plugin';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { CreateChannel } from '@clover/conversations-v4/conversations/state/conversations/conversations.actions';
import { ConversationsService } from '@clover/conversations-v4/conversations/state/conversations/conversations.service';
import { CdkPortalService } from '@clover/core/services/cdk-portal.service';
import { ConversationActionsDistributorService } from '@conversations/conversation/state/conversation/conversation-actions-distributor.service';
import {
  ConversationsCategory,
  externalAccountCategorySegmentParam,
  labelsCategorySegmentParam,
} from '@conversations/conversations/categories';
import { CONVERSATIONS_BASE_URL } from '@conversations/routes';
import {
  LabelEditorDialogData,
  LabelEditorDialogResult,
  LabelEditorModalComponent,
} from '@conversations/workspaces/modals/label-editor-modal/label-editor-modal.component';
import {
  WORKSPACE_SETTINGS_MODAL_OUTLET,
  WorkspaceSettingsModalTab,
} from '@conversations/workspaces/modals/workspace-settings-modal/routes';
import { Label } from '@conversations/workspaces/state/labels/labels-state.model';
import { CreateLabel } from '@conversations/workspaces/state/labels/labels.actions';
import { LabelsSelectors } from '@conversations/workspaces/state/labels/labels.selectors';
import { WorkspacesSelectors } from '@conversations/workspaces/state/workspaces/workspaces.selectors';
import { WorkspaceAlias } from '@conversations/workspaces/workspaces';
import { LimitNumberPipe } from '@design/chips/nav-counter-chip/limit-number.pipe';
import { LabelIconComponent } from '@design/misc/label-icon/label-icon.component';
import { SubnavItemComponent } from '@design/navigation/subnav-item/subnav-item.component';
import { DropdownActionComponent } from '@design/overlays/dropdown/dropdown-action/dropdown-action.component';
import { DropdownDividerComponent } from '@design/overlays/dropdown/dropdown-divider/dropdown-divider.component';
import { DropdownTriggerDirective } from '@design/overlays/dropdown/dropdown-trigger.directive';
import { DropdownComponent } from '@design/overlays/dropdown/dropdown.component';
import { LabelPickerComponent } from '@design/overlays/label-picker/label-picker.component';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';

import { ButtonComponent } from '../../../../stories/buttons/button/button.component';
import {
  ChannelCreateDialogComponent,
  type ChannelCreateDialogData,
  type ChannelCreateDialogResult,
} from '../modals/channel-create-dialog/channel-create-dialog.component';
import { UnreadCountersService } from '../state/unread-counters/unread-counters.service';
import { ButtonType, ButtonSize } from './../../../../stories/buttons/button/types';

interface MoreDropdownLink {
  icon: string;
  titleTranslationKey: string;
  commands: unknown[];
}

const moreDropdownLinks: MoreDropdownLink[] = [
  {
    icon: 'icon-star',
    titleTranslationKey: 'conversations-v4.categories.prioritized',
    commands: [ConversationsCategory.Prioritized],
  },
  {
    icon: 'icon-snooze',
    titleTranslationKey: 'conversations-v4.categories.snoozed',
    commands: [ConversationsCategory.Snoozed],
  },
  {
    icon: 'icon-important',
    titleTranslationKey: 'conversations-v4.categories.important',
    commands: [ConversationsCategory.Important],
  },
  {
    icon: 'icon-all-mail',
    titleTranslationKey: 'conversations-v4.categories.allMail',
    commands: [ConversationsCategory.AllMail],
  },
  {
    icon: 'icon-spam',
    titleTranslationKey: 'conversations-v4.categories.spam',
    commands: [ConversationsCategory.Spam],
  },
  {
    icon: 'icon-trash',
    titleTranslationKey: 'conversations-v4.categories.trash',
    commands: [ConversationsCategory.Trash],
  },
];

@UntilDestroy()
@Component({
  selector: 'cc-category-picker',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    DropdownActionComponent,
    DropdownComponent,
    DropdownDividerComponent,
    LabelIconComponent,
    LabelPickerComponent,
    SubnavItemComponent,
    TranslateModule,
    DropdownTriggerDirective,
    LimitNumberPipe,
    ButtonComponent,
    TooltipDirective,
  ],
  templateUrl: './category-picker.component.html',
  styleUrls: ['./category-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryPickerComponent implements OnInit, AfterViewInit {
  @Input()
  workspaceAlias: WorkspaceAlias | string;

  childSelected = output<void>();

  @ViewChildren(SubnavItemComponent)
  subnavItems: QueryList<SubnavItemComponent>;

  protected unreadConversations: Signal<number>;

  protected labels$: Observable<Label[]>;
  protected visibleLabels$: Observable<Label[]>;
  protected hiddenLabels$: Observable<Label[]>;
  protected activeHiddenLabel$: Observable<Label | undefined>;
  protected omittedInMoreDropdownLabelIds$: Observable<number[]>;
  protected activeLabelIds$: Observable<number[]>;
  protected showMoreLabelsDropdown$: Observable<boolean>;

  protected workspaceIds: number[] = [];
  protected primaryWorkspaceId: number;

  protected readonly routerLinkActiveOptions: IsActiveMatchOptions = {
    queryParams: 'ignored',
    paths: 'subset',
    fragment: 'ignored',
    matrixParams: 'subset',
  };
  protected readonly ConversationsCategory = ConversationsCategory;
  protected readonly labelsCategorySegmentParam = labelsCategorySegmentParam;
  protected readonly WorkspaceAlias = WorkspaceAlias;
  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;

  private subscriptions: Subscription[] = [];

  private readonly store = inject(Store);
  private readonly unreadCountersService = inject(UnreadCountersService);
  private readonly conversationsService = inject(ConversationsService);
  private readonly conversationActionsDistributorService = inject(ConversationActionsDistributorService);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly dialog = inject(Dialog);
  private readonly actions$ = inject(Actions);
  private readonly portal = inject(CdkPortalService);
  private readonly cdr = inject(ChangeDetectorRef);

  get hasFilteringParams(): boolean {
    const categoryRoute = this.activatedRoute.snapshot.firstChild.firstChild;
    if (!categoryRoute) return false;

    const [categorySegment] = categoryRoute.url;
    const parameters = categorySegment.parameters;

    return !!parameters[labelsCategorySegmentParam] || !!parameters[externalAccountCategorySegmentParam];
  }

  get activeMoreDropdownLink(): MoreDropdownLink | undefined {
    return moreDropdownLinks.find((link) => this.isLinkActive([this.workspaceAlias, ...link.commands]));
  }

  get nonActiveMoreDropdownLinks(): MoreDropdownLink[] {
    return moreDropdownLinks.filter((link) => !this.isLinkActive([this.workspaceAlias, ...link.commands]));
  }

  ngOnInit(): void {
    this.workspaceIds = this.getWorkspaceIds();
    this.primaryWorkspaceId = this.getPrimaryWorkspaceId();

    if (this.workspaceIds) {
      this.labels$ = this.store
        .select(LabelsSelectors.labelsByWorkspaceIds(this.workspaceIds))
        .pipe(map((labels) => labels.filter((label) => !label.system).sort((a, b) => a.name.localeCompare(b.name))));

      this.visibleLabels$ = this.labels$.pipe(map((labels) => labels.filter((label) => label.visibleInNavigation)));
      this.hiddenLabels$ = this.labels$.pipe(map((labels) => labels.filter((label) => !label.visibleInNavigation)));

      this.unreadConversations = this.unreadCountersService.getWorkspacesUnreadCount(this.workspaceIds);
    }

    this.activeHiddenLabel$ = combineLatest([this.hiddenLabels$, this.activatedRoute.firstChild.firstChild.url]).pipe(
      map(([hiddenLabels]) => {
        return hiddenLabels.find((label) =>
          this.isLinkActive([
            this.workspaceAlias,
            ConversationsCategory.Inbox,
            { [labelsCategorySegmentParam]: label.id },
          ]),
        );
      }),
    );

    this.activeLabelIds$ = combineLatest([this.labels$, this.activatedRoute.firstChild.firstChild.url]).pipe(
      map(([labels]) => {
        const activeLabel = labels.find((label) =>
          this.isLinkActive([
            this.workspaceAlias,
            ConversationsCategory.Inbox,
            { [labelsCategorySegmentParam]: label.id },
          ]),
        );

        return activeLabel ? [activeLabel.id] : [];
      }),
    );

    this.omittedInMoreDropdownLabelIds$ = this.visibleLabels$.pipe(map((labels) => labels.map((label) => label.id)));
    this.showMoreLabelsDropdown$ = this.hiddenLabels$.pipe(map((labels) => labels.length > 0));

    this.actions$
      .pipe(ofActionSuccessful(RouterNavigated), untilDestroyed(this))
      .subscribe(() => this.cdr.detectChanges());
  }

  ngAfterViewInit(): void {
    this.initChildren();

    this.subnavItems.changes.pipe(untilDestroyed(this)).subscribe(() => {
      this.initChildren();
    });
  }

  goToLabel(label: Label): void {
    this.store.dispatch(
      new Navigate(
        [this.workspaceAlias, ConversationsCategory.Inbox, { [labelsCategorySegmentParam]: label.id }],
        {},
        { relativeTo: this.activatedRoute },
      ),
    );
  }

  createLabel(predefinedName: string): void {
    const dialog = this.dialog.open<LabelEditorDialogResult, LabelEditorDialogData>(LabelEditorModalComponent, {
      data: {
        preselectedWorkspaceId: this.getPrimaryWorkspaceId(),
        predefinedName,
      },
    });

    dialog.closed.pipe(take(1)).subscribe((newLabel: LabelEditorDialogResult | undefined) => {
      if (!newLabel) return;
      this.store.dispatch(new CreateLabel(newLabel.workspaceId, newLabel));
    });
  }

  openWorkspaceSettingsLabelsTab(): void {
    const workspaceAlias = this.store.selectSnapshot(
      WorkspacesSelectors.workspaceAliasById(this.getPrimaryWorkspaceId()),
    );
    this.store.dispatch(
      new Navigate([
        '/',
        CONVERSATIONS_BASE_URL,
        { outlets: { [WORKSPACE_SETTINGS_MODAL_OUTLET]: [workspaceAlias, WorkspaceSettingsModalTab.Labels] } },
      ]),
    );
  }

  createChannel(): void {
    if (this.workspaceAlias === WorkspaceAlias.Private) return;

    const dialog = this.dialog.open<ChannelCreateDialogResult, ChannelCreateDialogData>(ChannelCreateDialogComponent, {
      data: {
        preselectedWorkspaceId:
          this.workspaceAlias === WorkspaceAlias.Aggregated ? undefined : this.getPrimaryWorkspaceId(),
      },
    });

    dialog.closed.pipe(take(1)).subscribe((newChat: ChannelCreateDialogResult | undefined) => {
      if (!newChat) return of(undefined);
      return this.store.dispatch(new CreateChannel(newChat.workspace.id, newChat.name, newChat.memberIds));
    });
  }

  protected mergeArrays(array1: unknown[], array2: unknown[]): unknown[] {
    return [...array1, ...array2];
  }

  protected getLabelRouteParams(label: Label): Record<string, number> {
    return {
      [labelsCategorySegmentParam]: label.id,
    };
  }

  private isLinkActive(commands: unknown[]): boolean {
    return this.router.isActive(
      this.router.createUrlTree(commands, { relativeTo: this.activatedRoute }),
      this.routerLinkActiveOptions,
    );
  }

  private getWorkspaceIds(): number[] {
    switch (this.workspaceAlias) {
      case WorkspaceAlias.Aggregated: {
        return this.store.selectSnapshot(WorkspacesSelectors.workspaces).map((workspace) => workspace.id);
      }
      case WorkspaceAlias.Private: {
        return [this.store.selectSnapshot(WorkspacesSelectors.privateWorkspace).id];
      }
      default: {
        const workspaceId = Number(this.workspaceAlias);
        return isNaN(workspaceId) ? [] : [workspaceId];
      }
    }
  }

  private getPrimaryWorkspaceId(): number | undefined {
    switch (this.workspaceAlias) {
      case WorkspaceAlias.Aggregated:
      case WorkspaceAlias.Private: {
        return this.store.selectSnapshot(WorkspacesSelectors.privateWorkspace).id;
      }
      default: {
        const workspaceId = Number(this.workspaceAlias);
        return isNaN(workspaceId) ? undefined : workspaceId;
      }
    }
  }

  private initChildren(): void {
    if (this.subnavItems.length === 0) return;

    for (const subscription of this.subscriptions) subscription.unsubscribe();

    for (const child of this.subnavItems) {
      this.subscriptions.push(
        child.selected$.pipe(untilDestroyed(this)).subscribe((childSelected) => {
          if (childSelected) this.childSelected.emit();
        }),
      );
    }
  }
}
