import { Params, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { RouterState, RouterStateModel, RouterStateSerializer } from '@ngxs/router-plugin';
import { Selector } from '@ngxs/store';

export interface RouterStateParams {
  url: string;
  params: Params;
  segments: UrlSegment[];
  queryParams: Params;
}

export class RouterSelectors {
  @Selector([RouterState])
  static state(state: RouterStateModel<RouterStateParams>): RouterStateParams {
    return state.state;
  }

  @Selector([RouterState])
  static url(state: RouterStateModel<RouterStateParams>): string {
    return state.state.url;
  }

  @Selector([RouterState])
  static params(state: RouterStateModel<RouterStateParams>): Params {
    return state.state.params;
  }

  @Selector([RouterState])
  static queryParams(state: RouterStateModel<RouterStateParams>): Params {
    return state.state.queryParams;
  }

  @Selector([RouterState])
  static segments(state: RouterStateModel<RouterStateParams>): UrlSegment[] {
    return state.state.segments;
  }
}

export class CustomRouterStateSerializer implements RouterStateSerializer<RouterStateParams> {
  serialize(routerState: RouterStateSnapshot): RouterStateParams {
    const {
      url,
      root: { queryParams },
    } = routerState;

    let { root: route } = routerState;
    let params: Params = { ...route.params };
    let segments: UrlSegment[] = route.url;

    while (route.firstChild) {
      route = route.firstChild;
      params = { ...params, ...route.params };
      segments = [...segments, ...route.url];
    }

    return { url, params, segments, queryParams };
  }
}
