import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Output } from '@angular/core';

import { AutoAnimateDirective } from '@clover/core/directives/auto-animate.directive';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { TabSelectorComponent, type TabSelectorItem } from '@design/navigation/tab-selector/tab-selector.component';

import { BpiInvoiceTableComponent } from './bpi-invoice-table/bpi-invoice-table.component';
import { BpiOrderTableComponent } from './bpi-order-table/bpi-order-table.component';
import type { BPIInvoice, BPIOrder } from './business-object';

export type BPIEmbedDialogData = void; // No need to pass data for now
export interface BPIEmbedDialogResult {
  type: 'order' | 'invoice';
  order?: BPIOrder;
  invoice?: BPIInvoice;
}

@Component({
  selector: 'cc-business-object-modal',
  standalone: true,
  imports: [
    ButtonComponent,
    TabSelectorComponent,
    BpiInvoiceTableComponent,
    BpiOrderTableComponent,
    AutoAnimateDirective,
  ],
  templateUrl: './business-object-modal.component.html',
  styleUrls: ['./business-object-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BusinessObjectModalComponent {
  @Output()
  closeModal = new EventEmitter<void>();

  readonly dialogData: BPIEmbedDialogData = inject(DIALOG_DATA);

  protected activeTabId: 'orders' | 'invoices' = 'orders';

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;

  protected readonly tabs: TabSelectorItem[] = [
    { id: 'orders', label: 'Orders', disabled: false },
    { id: 'invoices', label: 'Invoices', disabled: false },
  ];

  private readonly dialogRef: DialogRef<BPIEmbedDialogResult> = inject(DialogRef);

  setActiveTab(tabId: string) {
    this.activeTabId = tabId as 'orders' | 'invoices';
  }

  selectOrder(order: BPIOrder) {
    this.dialogRef.close({ type: 'order', order });
  }

  selectInvoice(invoice: BPIInvoice) {
    this.dialogRef.close({ type: 'invoice', invoice });
  }
}
