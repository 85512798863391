<div
  [style.height.px]="height"
  class="cc-progress-bar"
>
  <div
    [class.cc-progress-bar__inner--determinate]="type === 'determinate'"
    [class.cc-progress-bar__inner--indeterminate]="type === 'indeterminate'"
    [style.--progress]="progress"
    class="cc-progress-bar__inner"
  ></div>
</div>
