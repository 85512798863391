import { Extension } from '@tiptap/core';
import Gapcursor from '@tiptap/extension-gapcursor';
import { Table } from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import { TableRow } from '@tiptap/extension-table-row';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    allowResizing: {
      setAllowResizing: (allow: boolean) => ReturnType;
    };

    tableCellBorderColor: {
      setBorderColor: (borderColor: string) => ReturnType;
    };

    tableCellPadding: {
      setPadding: (padding: string) => ReturnType;
    };
  }
}

const TableCellAppearance = Extension.create({
  name: 'tableCellAppearance',

  addGlobalAttributes() {
    return [
      {
        types: ['tableCell', 'tableHeader'],
        attributes: {
          tableCellBorderColor: {
            default: '#e8eaeb',
            parseHTML: (element) => element.dataset.borderColor,
            renderHTML: (attributes) => {
              const borderColor = attributes.tableCellBorderColor;

              return {
                'data-border-color': borderColor,
                style: `border-color: ${borderColor};`,
              };
            },
          },
        },
      },
      {
        types: ['tableCell', 'tableHeader'],
        attributes: {
          tableCellPadding: {
            default: '8px 12px',
            parseHTML: (element) => element.dataset.padding,
            renderHTML: (attributes) => {
              const padding = attributes.tableCellPadding;

              return {
                'data-padding': padding,
                style: `padding: ${padding};`,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setBorderColor:
        (borderColor: string) =>
        ({ chain }) => {
          return chain().setCellAttribute('tableCellBorderColor', borderColor).run();
        },

      setPadding:
        (padding: string) =>
        ({ chain }) => {
          return chain().setCellAttribute('tableCellPadding', padding).run();
        },
    };
  },
});

const tableStyles = ['border-collapse: collapse', 'width: 100%', 'table-layout: fixed', 'word-break: break-all'].join(
  ';',
);

const tableCellStyles = [
  'height: 20px',
  'border-width: 1px',
  'border-style: solid',
  'text-align: left',
  'vertical-align: top',
].join(';');

const tableHeaderCellStyles = [tableCellStyles, 'background-color: #fafbfc', 'font-weight: unset'].join(';');
const tableDataCellStyles = [tableCellStyles].join(';');

export function withTable() {
  return [
    Table.configure({
      resizable: true,
      HTMLAttributes: {
        style: tableStyles,
      },
    }),
    TableRow,
    TableHeader.configure({
      HTMLAttributes: {
        style: tableHeaderCellStyles,
      },
    }),
    TableCell.configure({
      HTMLAttributes: {
        style: tableDataCellStyles,
      },
    }),
    TableCellAppearance,
    Gapcursor,
  ];
}
