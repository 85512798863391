import { UrlSegment, type ActivatedRouteSnapshot } from '@angular/router';

/**
 * Represents a parameter to modify in a path.
 */
export interface ParamToModify {
  paramName: string; // Name of the path parameter to replace. Without the colon.
  outletName: 'primary' | string | undefined; // If not provided, the value will be used for all outlets.
  replaceWith: string; // The value to replace the path parameter with.
}

/**
 * Modifies the path parameters of a route.
 *
 * @param route - The ActivatedRouteSnapshot object representing the current route.
 * @param paramsToModify - An array of ParamToModify objects specifying the parameters to modify.
 * @returns An array of router commands that represent the modified route.
 *
 * @example
 * ```
 * const route: ActivatedRouteSnapshot = ...; // Represents (user/000//posts:post/000)
 * const paramsToModify = [
 *  { paramName: 'userId', replaceWith: '123', outletName: 'primary' },
 *  { paramName: 'postId', replaceWith: '456', outletName: 'posts' },
 * ];
 *
 * const commands = modifyPathParams(route, paramsToModify);
 * // [
 * //   {
 * //     outlets: {
 * //       primary: ['user', '123'],
 * //       posts: ['post', '456'],
 * //     },
 * //   },
 * // ];
 */
export function modifyPathParams(route: ActivatedRouteSnapshot, paramsToModify: ParamToModify[]): unknown[] {
  const commands: unknown[] = [];
  const { outlet: outletName } = route;

  // Modify the current route's path segments.
  const segmentsToModify = getSegmentsToModify(route.routeConfig.path || '', outletName, paramsToModify);

  const segments = route.url.map((segment, index) => {
    const segmentToModify = segmentsToModify.find((part) => part.index === index);
    return segmentToModify ? new UrlSegment(segmentToModify.value, segment.parameters) : segment;
  });

  commands.push(...segments.flatMap(segmentToRouterCommands));

  // Recursively modify the child outlets.
  const childOutlets: Record<string, unknown[]> = {};
  for (const child of route.children) {
    childOutlets[child.outlet] = modifyPathParams(child, paramsToModify);
  }

  // If there is only one primary outlet, flatten the commands.
  const hasOnlyPrimaryOutlet = Object.keys(childOutlets).length === 1 && childOutlets['primary'];
  if (hasOnlyPrimaryOutlet) {
    commands.push(...childOutlets['primary']);
  } else if (Object.keys(childOutlets).length > 0) {
    commands.push({ outlets: childOutlets });
  }

  return commands;
}

interface SegmentPartToModify {
  index: number;
  value: string;
}

/**
 * Retrieves the segments to modify based on the provided configuration path, outlet name, and parameters to modify.
 *
 * @param configPath - The configuration path to split into segment parts.
 * @param outletName - The name of the outlet.
 * @param paramsToModify - The parameters to modify.
 * @returns An array of segments to modify, including their index and value.
 *
 * @example
 * ```
 * const configPath = '/users/:userId/posts/:postId';
 * const outletName = 'posts';
 * const paramsToModify = [
 *   { paramName: 'userId', replaceWith: '123' },
 *   { paramName: 'postId', replaceWith: '456' },
 * ];
 *
 * const segmentsToModify = getSegmentsToModify(configPath, outletName, paramsToModify);
 * // [{ index: 1, value: '123' }, { index: 3, value: '456' }]
 * ```
 */
function getSegmentsToModify(
  configPath: string,
  outletName: string,
  paramsToModify: ParamToModify[],
): SegmentPartToModify[] {
  const segmentParts = configPath.split('/') || [];
  const paramsToModifyForOutlet = paramsToModify.filter(
    (param) => !param.outletName || param.outletName === outletName,
  );

  return paramsToModifyForOutlet
    .map((param) => {
      const index = segmentParts.indexOf(`:${param.paramName}`);
      return { index, value: param.replaceWith };
    })
    .filter((segmentPart) => segmentPart.index !== -1);
}

/**
 * Converts a URL segment to an array of router commands.
 * @param segment - The URL segment to convert.
 * @returns An array of router commands.
 * @example
 * ```
 * const segment = new UrlSegment('example', { id: '123', name: 'John' });
 * // {
 * //   path: 'example',
 * //   parameters: {
 * //     id: '123',
 * //     name: 'John'
 * //   }
 * // }
 *
 * const commands = segmentToRouterCommands(segment);
 * // ['example', { id: '123', name: 'John' }]
 * ```
 */
function segmentToRouterCommands(segment: UrlSegment): unknown[] {
  const commands: unknown[] = [segment.path];
  if (Object.keys(segment.parameters).length > 0) commands.push(segment.parameters);
  return commands;
}
