import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { TextboxComponent } from '@design/forms/textbox/textbox.component';
import { TextboxType } from '@design/forms/textbox/textbox.types';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';

export interface LinkEditorDialogData {
  title?: string;
  href?: string;
}

export interface LinkEditorDialogResult {
  title: string;
  href: string;
}

@Component({
  standalone: true,
  templateUrl: './link-editor.component.html',
  styleUrls: ['./link-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ReactiveFormsModule, ButtonComponent, TextboxComponent, TranslateModule, TooltipDirective],
})
export class LinkEditorDialogComponent implements OnInit {
  protected readonly linkForm: FormGroup = new FormGroup({
    title: new FormControl(''),
    href: new FormControl('', [
      Validators.required,
      Validators.pattern(/^(http|https):\/\/([\w-]+(\.[\w-]+)+)(\/[\w-.%&/=?]*)?$/),
    ]),
  });

  protected readonly dialogData: LinkEditorDialogData = inject(DIALOG_DATA);
  protected readonly dialogRef = inject(DialogRef<LinkEditorDialogResult | undefined>);

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;
  protected readonly TextboxType = TextboxType;

  private readonly translate = inject(TranslateService);

  get mode(): 'insert' | 'edit' {
    return this.dialogData.href ? 'edit' : 'insert';
  }

  get linkErrorMessage(): string | undefined {
    const linkControl = this.linkForm.get('href');

    if (!linkControl.touched) return undefined;
    if (linkControl.hasError('required')) return this.translate.instant('richTextEditor.actions.link.linkRequired');
    if (linkControl.hasError('pattern')) return this.translate.instant('richTextEditor.actions.link.linkInvalid');

    return undefined;
  }

  ngOnInit(): void {
    this.linkForm.get('title').setValue(this.dialogData.title || '');
    this.linkForm.get('href').setValue(this.dialogData.href || '');
  }

  submit(): void {
    if (this.linkForm.invalid) return;

    const title = this.linkForm.get('title').value;
    const href = this.linkForm.get('href').value;

    this.dialogRef.close({
      title: title || href,
      href,
    });
  }
}
