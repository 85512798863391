import { ChangeDetectionStrategy, Component, computed, inject, Injector } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonComponent } from '@design/buttons/button/button.component';

import { RichTextEditorBaseControlComponent } from '../base-action.component';
import { TooltipDirective } from './../../../../overlays/tooltip/tooltip.directive';
import { insertLink, presentLinkEditor } from './link.extension';

@Component({
  selector: 'cc-rich-text-editor-link-control',
  standalone: true,
  imports: [ButtonComponent, TooltipDirective, TranslateModule],
  template: `
    <app-button
      [active]="active()"
      (click)="active() ? removeLink() : setLink()"
      [type]="ButtonType.Ghost"
      [size]="ButtonSize.Big"
      [ccTooltip]="
        active()
          ? ('richTextEditor.actions.link.removeLink' | translate)
          : ('richTextEditor.actions.link.insertLink' | translate)
      "
    >
      <i
        class="icon-link"
        slot="ghost-icon"
      ></i>
    </app-button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RichTextEditorLinkControlComponent extends RichTextEditorBaseControlComponent {
  active = computed(() => {
    this.selectionNotifier.listen();
    return this.activeEditor()?.isActive('link');
  });

  private readonly injector = inject(Injector);

  async setLink(): Promise<void> {
    if (!this.activeEditor()) return;

    const view = this.activeEditor().view;
    const { from, to } = view.state.selection;
    const selectedText = view.state.doc.textBetween(from, to);

    const linkOptions = await presentLinkEditor(this.injector, { title: selectedText });
    if (!linkOptions) return;

    const { title, href } = linkOptions;
    insertLink(this.activeEditor(), { title, href });
  }

  removeLink(): void {
    this.activeEditor()?.chain().focus().unsetLink().run();
  }
}
