@if (tasks(); as tasks) {
  @if (tasks.length > 0) {
    <cc-dropdown
      [shadow]="false"
      [fakeFocus]="true"
    >
      @for (task of tasks; track task.id) {
        <cc-dropdown-action (click)="selectTask.emit(task)">
          <ng-container slot="leading"> #{{ task.id }} </ng-container>
          <ng-container slot="title"> {{ task.name }} </ng-container>
        </cc-dropdown-action>
      }
    </cc-dropdown>
  }
}
