import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { CONVERSATIONS_BASE_URL } from '@conversations/routes';
import {
  AccountSyncIssuesDialogData,
  AccountSyncIssuesModalComponent,
} from '@conversations/workspaces/modals/account-sync-issues-modal/account-sync-issues-modal.component';
import {
  WORKSPACE_SETTINGS_MODAL_OUTLET,
  WorkspaceSettingsModalTab,
} from '@conversations/workspaces/modals/workspace-settings-modal/routes';
import {
  ExternalAccount,
  ExternalAccountStatus,
} from '@conversations/workspaces/state/workspaces/workspaces-state.model';
import { WorkspacesSelectors } from '@conversations/workspaces/state/workspaces/workspaces.selectors';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';

interface ExternalAccountFailures {
  failed: ExternalAccount[];
  expired: ExternalAccount[];
}

@Component({
  selector: 'cc-accounts-failures-notice',
  standalone: true,
  templateUrl: './accounts-failures-notice.component.html',
  styleUrls: ['./accounts-failures-notice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonComponent, AsyncPipe, DialogModule, TooltipDirective, TranslateModule],
})
export class AccountsFailuresNoticeComponent implements OnInit {
  failures$: Observable<ExternalAccountFailures | undefined>;

  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;
  protected readonly ExternalAccountStatus = ExternalAccountStatus;

  private readonly externalAccounts$ = inject(Store).select(WorkspacesSelectors.externalAccounts);
  private readonly dialog = inject(Dialog);
  private readonly store = inject(Store);

  ngOnInit(): void {
    this.failures$ = this.externalAccounts$.pipe(
      map((externalAccounts) => ({
        failed: externalAccounts.filter((account) => account.status === ExternalAccountStatus.Failed),
        expired: externalAccounts.filter((account) => account.status === ExternalAccountStatus.Expired),
      })),
      map((notices: ExternalAccountFailures) => {
        if (!notices.failed.length && !notices.expired.length) return undefined;
        return notices;
      }),
    );
  }

  openSyncIssuesModal(
    failedAccounts: ExternalAccount[],
    status: ExternalAccountStatus.Failed | ExternalAccountStatus.Expired,
  ) {
    const dialog = this.dialog.open<ExternalAccount, AccountSyncIssuesDialogData>(AccountSyncIssuesModalComponent, {
      data: {
        failedAccounts,
        issueType: status,
      },
    });

    dialog.closed.pipe(take(1)).subscribe(async (account: ExternalAccount | undefined) => {
      if (!account) return;

      const workspaceAlias = this.store.selectSnapshot(WorkspacesSelectors.workspaceAliasById(account.workspaceId));
      this.store.dispatch(
        new Navigate([
          CONVERSATIONS_BASE_URL,
          { outlets: { [WORKSPACE_SETTINGS_MODAL_OUTLET]: [workspaceAlias, WorkspaceSettingsModalTab.Inbox] } },
        ]),
      );
    });
  }
}
