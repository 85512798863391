import Placeholder from '@tiptap/extension-placeholder';

export function withPlaceholder(placeholder: string) {
  return [
    Placeholder.configure({
      placeholder,
      emptyEditorClass: 'cc-empty-editor',
      emptyNodeClass: 'cc-empty-node',
    }),
  ];
}
