import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CONVERSATIONS_BASE_URL } from '@conversations/routes';
import {
  WORKSPACE_SETTINGS_MODAL_OUTLET,
  WorkspaceSettingsModalTab,
} from '@conversations/workspaces/modals/workspace-settings-modal/routes';
import { WorkspaceAlias } from '@conversations/workspaces/workspaces';

@Component({
  selector: 'cc-connect-account-cta',
  standalone: true,
  templateUrl: './connect-account-cta.component.html',
  styleUrls: ['./connect-account-cta.component.scss'],
  imports: [RouterLink, TranslateModule],
})
export class ConnectAccountCtaComponent {
  protected readonly CONVERSATIONS_BASE_URL = CONVERSATIONS_BASE_URL;

  get link(): unknown[] {
    return [
      '/',
      CONVERSATIONS_BASE_URL,
      { outlets: { [WORKSPACE_SETTINGS_MODAL_OUTLET]: [WorkspaceAlias.Private, WorkspaceSettingsModalTab.Inbox] } },
    ];
  }
}
