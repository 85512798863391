import { Workspace, WorkspaceOwnerType } from '@conversations/workspaces/state/workspaces/workspaces-state.model';
import { WorkspaceAlias } from '@conversations/workspaces/workspaces';

export function getFallbackWorkspaceAlias(workspaces: Workspace[]): WorkspaceAlias | string | undefined {
  if (workspaces.length === 0) return undefined;

  if (checkWorkspaceExistence(workspaces, WorkspaceAlias.Aggregated)) return WorkspaceAlias.Aggregated;
  if (checkWorkspaceExistence(workspaces, WorkspaceAlias.Private)) return WorkspaceAlias.Private;

  return workspaces[0].id.toString();
}

export function checkWorkspaceExistence(workspaces: Workspace[], workspaceAlias: WorkspaceAlias | string): boolean {
  switch (workspaceAlias) {
    case WorkspaceAlias.Aggregated:
      return (
        workspaces.some((workspace) => workspace.ownerType === WorkspaceOwnerType.User) &&
        workspaces.some((workspace) => workspace.ownerType === WorkspaceOwnerType.Team)
      );
    case WorkspaceAlias.Private:
      return workspaces.some((workspace) => workspace.ownerType === WorkspaceOwnerType.User);
    default:
      return workspaces.some(
        (workspace) => workspace.id.toString() === workspaceAlias && workspace.ownerType === WorkspaceOwnerType.Team,
      );
  }
}

export function getWorkspaceIdByAlias(
  workspaces: Workspace[],
  workspaceAlias: WorkspaceAlias | string,
): number | undefined {
  switch (workspaceAlias) {
    case WorkspaceAlias.Aggregated:
      return undefined;
    case WorkspaceAlias.Private:
      return workspaces.find((workspace) => workspace.ownerType === WorkspaceOwnerType.User).id;
    default:
      return Number(workspaceAlias);
  }
}
