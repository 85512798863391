import { computed, inject, Injectable, type Signal } from '@angular/core';

import { generateGenericAvatar } from '@clover/core/helpers/generateGenericAvatar';
import { UserService } from '@clover/core/services/user.service';

import { ImagePlaceholderType } from './image-placeholder/placeholders';
import type { TextPlaceholderType } from './text-placeholder/placeholders';

export const textPlaceholderSelector = 'cc-text-placeholder';
export const imagePlaceholderSelector = 'cc-image-placeholder';

export const placeholderSelectors = [textPlaceholderSelector, imagePlaceholderSelector] as const;

@Injectable({
  providedIn: 'root',
})
export class DynamicValuesService {
  private readonly user$ = inject(UserService).userProfile$;
  private readonly company$ = inject(UserService).company$;

  dynamicValues$ = computed(() => {
    return {
      avatarUrl: this.avatarUrl$(),
      firstName: this.firstName$(),
      lastName: this.lastName$(),
      fullName: this.fullName$(),
      jobTitle: this.jobTitle$(),
      companyName: this.companyName$(),
      workEmail: this.workEmail$(),
      workPhoneNumber: this.workPhoneNumber$(),
    } as const;
  });

  avatarUrl$ = computed(() => {
    const user = this.user$();
    return user.logoUrl || generateGenericAvatar(user.firstName || '–', user.lastName, 128);
  });

  firstName$ = computed(() => this.user$().firstName);
  lastName$ = computed(() => this.user$().lastName);
  fullName$ = computed(() => this.user$().fullName);
  jobTitle$ = computed(() => this.user$().title);
  companyName$ = computed(() => this.company$().name);
  workEmail$ = computed(() => this.user$().workEmail);
  workPhoneNumber$ = computed(() => this.user$().workPhoneNumber);

  private get textPlaceholdersValueMap(): Map<TextPlaceholderType, Signal<string>> {
    return new Map([
      ['firstName', this.firstName$],
      ['lastName', this.lastName$],
      ['fullName', this.fullName$],
      ['jobTitle', this.jobTitle$],
      ['companyName', this.companyName$],
      ['workEmail', this.workEmail$],
      ['workPhoneNumber', this.workPhoneNumber$],
    ]);
  }

  private get imagePlaceholdersSrcMap(): Map<ImagePlaceholderType, Signal<string>> {
    return new Map([['avatar', this.avatarUrl$]]);
  }

  private get imagePlaceholdersAltMap(): Map<ImagePlaceholderType, Signal<string>> {
    return new Map([['avatar', this.fullName$]]);
  }

  replaceDynamicValues(content: string): string {
    const parser = new DOMParser();
    const document = parser.parseFromString(content, 'text/html');

    this.replaceTextPlaceholders(document);
    this.replaceImagePlaceholders(document);

    return document.body.innerHTML;
  }

  private replaceTextPlaceholders(document: Document): Document {
    const textPlaceholders = document.querySelectorAll(textPlaceholderSelector);

    for (const textPlaceholder of Array.from(textPlaceholders)) {
      const type = textPlaceholder.getAttribute('type') as TextPlaceholderType;
      const value$ = this.textPlaceholdersValueMap.get(type);

      const placeholderValue = value$ ? value$() : undefined;

      if (placeholderValue) textPlaceholder.replaceWith(placeholderValue);
      else textPlaceholder.remove();
    }

    return document;
  }

  private replaceImagePlaceholders(document: Document): Document {
    const imagePlaceholders = document.querySelectorAll(imagePlaceholderSelector);

    for (const imagePlaceholder of Array.from(imagePlaceholders)) {
      const type = imagePlaceholder.getAttribute('type') as ImagePlaceholderType;
      const imageUrl$ = this.imagePlaceholdersSrcMap.get(type);
      const imageAlt$ = this.imagePlaceholdersAltMap.get(type);

      const width = Number(imagePlaceholder.getAttribute('width'));
      const height = Number(imagePlaceholder.getAttribute('height'));
      const radius = Number(imagePlaceholder.getAttribute('radius'));

      const placeholderImageUrl = imageUrl$ ? imageUrl$() : undefined;

      if (placeholderImageUrl) {
        const image = new Image();
        image.src = placeholderImageUrl;
        image.alt = imageAlt$ ? imageAlt$() : '';
        image.dataset['placeholder'] = 'true';
        image.setAttribute('width', width.toString());
        image.setAttribute('height', height.toString());
        image.setAttribute('radius', radius.toString());
        image.style.width = CSS.px(width).toString();
        image.style.height = CSS.px(height).toString();
        image.style.borderRadius = CSS.px(radius).toString();
        image.style.objectFit = 'cover';

        imagePlaceholder.replaceWith(image);
      } else imagePlaceholder.remove();
    }

    return document;
  }
}
