import { Subject } from 'rxjs';

import type {
  CompactMessage,
  Draft,
  EmailParticipants,
  LinkedEmailDraft,
  NewEmailDraft,
} from '@clover/conversations-v4/conversation/state/conversation/conversation-state.model';

export interface ComposersStateModel {
  composers: ComposerInstance[];
}

// export interface ComposerInstance {
//   workspaceId: number; // Not defined for new messages
//   conversationId: string | undefined; // Not defined for new messages
//   messageType: ComposerMessageType;
//   senderAccountId: number;
//   emailParticipants: Omit<EmailParticipants, 'from'>;
//   subject: string;
//   quote: string;
//   signature: string;
//   replyToMessage: CompactMessage | undefined;
//   replyForbidden: boolean;
//   activeFileUploads: FileUpload[];
//   draft: Draft | undefined; // Not defined for new messages (this functionality could be added in the future)
//   draftSaveStatus: DraftSaveStatus;
//   presentation: ComposerPresentation;
// }

interface BaseComposerInstance {
  id: string;
  composerType: ComposerType;
  workspaceId: number;
  message: string;
  draft: Draft | undefined;
  sendStatus: SendStatus;
}

// This composer type is used when we compose a new email message.
export interface NewEmailComposerInstance extends BaseComposerInstance {
  composerType: 'newEmail';
  workspaceId: number;
  messageType: Extract<ComposerMessageType, 'new'>;
  senderAccountId: number;
  emailParticipants: Omit<EmailParticipants, 'from'>;
  subject: string;
  signature: string;
  activeFileUploads: FileUpload[];
  draft: NewEmailDraft | undefined;
  draftSaveStatus: DraftSaveStatus;
  presentation: Extract<ComposerPresentation, 'overlay-expanded' | 'overlay-collapsed'>;
}

// This composer type is used when we compose a message as a reply to some existing email conversation.
// Supports internal messages, reply, reply all, and forward message types.
export interface LinkedEmailComposerInstance extends BaseComposerInstance {
  composerType: 'linkedEmail';
  workspaceId: number;
  conversationId: string;
  messageType: LinkedEmailComposerMessageType;
  senderAccountId: number;
  emailParticipants: Omit<EmailParticipants, 'from'>;
  subject: string;
  quote: string;
  signature: string;
  replyToMessage: CompactMessage;
  replyForbidden: boolean;
  activeFileUploads: FileUpload[];
  draft: LinkedEmailDraft | undefined;
  draftSaveStatus: DraftSaveStatus;
  presentation: ComposerPresentation;
}

export type LinkedEmailComposerMessageType = Extract<
  ComposerMessageType,
  'internal' | 'reply' | 'replyAll' | 'forward'
>;

// TODO: Implement DM composer
// And, possibly, separate composer type for channel DMs.
// export interface DirectMessageComposerInstance extends BaseComposerInstance {}

export type ComposerInstance = NewEmailComposerInstance | LinkedEmailComposerInstance;

export type DraftSaveStatus = 'void' | 'saving' | 'saved' | 'error';
export type SendStatus = 'void' | 'sending';

export type ComposerType = 'newEmail' | 'linkedEmail' | 'linkedInternalMessage';
export type ComposerMessageType = 'internal' | 'reply' | 'replyAll' | 'forward' | 'new';
export type ComposerPresentation = 'inline' | 'overlay-expanded' | 'overlay-collapsed';

export interface FileUpload {
  id: string;
  file: File;
  status: 'uploading' | 'error';
  progress: number;
  error?: FileUploadError;
  cancel$: Subject<void>;
}

export enum FileUploadError {
  // TODO (Oleksandr D.): add more error types
  Unknown = 'unknown',
}

export const defaultComposersState: ComposersStateModel = {
  composers: [],
};
