<div class="account-sync-issues-modal">
  <div class="account-sync-issues-modal__title">
    @switch (dialogData.issueType) {
      @case (ExternalAccountStatus.Failed) {
        {{ 'conversations-v4.workspaces.dialogs.syncIssues.failed.title' | translate }}
      }
      @case (ExternalAccountStatus.Expired) {
        {{ 'conversations-v4.workspaces.dialogs.syncIssues.expired.title' | translate }}
      }
    }
  </div>

  <div class="account-sync-issues-modal__description">
    @switch (dialogData.issueType) {
      @case (ExternalAccountStatus.Failed) {
        {{ 'conversations-v4.workspaces.dialogs.syncIssues.failed.message' | translate }}
      }
      @case (ExternalAccountStatus.Expired) {
        {{ 'conversations-v4.workspaces.dialogs.syncIssues.expired.message' | translate }}
      }
    }
  </div>

  <div class="account-sync-issues-modal__accounts">
    <div class="account-sync-issues-modal__accounts-title">
      {{ 'conversations-v4.workspaces.dialogs.syncIssues.affectedAccounts' | translate }}
    </div>

    <div class="account-sync-issues-modal__accounts-wrapper">
      @for (account of dialogData.failedAccounts; track account.id) {
        <cc-dropdown-action
          (click)="dialogRef.close(account)"
          trailingIcon="icon-chevronRight"
        >
          <ng-container slot="title">
            {{ account.email }}
          </ng-container>
        </cc-dropdown-action>
      }

      <div class="account-sync-issues-modal__caption">
        @switch (dialogData.issueType) {
          @case (ExternalAccountStatus.Failed) {
            {{ 'conversations-v4.workspaces.dialogs.syncIssues.failed.caption' | translate }}
          }
          @case (ExternalAccountStatus.Expired) {
            {{ 'conversations-v4.workspaces.dialogs.syncIssues.expired.caption' | translate }}
          }
        }
      </div>
    </div>
  </div>

  <app-button
    (click)="dialogRef.close()"
    [size]="ButtonSize.Small"
    [type]="ButtonType.Primary"
    class="account-sync-issues-modal__close-button"
  >
    {{ 'common.buttons.ok' | translate }}
  </app-button>
</div>
