<div class="bpi-invoice-drawer">
  <div class="bpi-invoice-drawer__actions">
    <app-button
      (click)="closeDrawer.emit()"
      [size]="ButtonSize.Small"
      [type]="ButtonType.Ghost"
      ccTooltip="Exit"
    >
      <i
        class="icon-escape"
        slot="ghost-icon"
      ></i>
    </app-button>

    <div class="bpi-invoice-drawer__actions-title">Business object info</div>

    <div class="bpi-invoice-drawer__actions-extra">
      <app-button
        [size]="ButtonSize.Small"
        [tooltipAlignment]="TooltipAlignment.End"
        [type]="ButtonType.Ghost"
        ccTooltip="Copy link"
      >
        <i
          class="icon-link"
          slot="ghost-icon"
        ></i>
      </app-button>
    </div>
  </div>

  <div class="bpi-invoice-drawer__header">
    <div
      [style.background-color]="getStatusBgColor(invoice)"
      [style.color]="getStatusFgColor(invoice)"
      class="bpi-invoice-drawer__header-status"
    >
      {{ getStatusText(invoice) }}
    </div>

    <div class="bpi-invoice-drawer__header-title">{{ invoice.invoiceNumber }}</div>

    <div class="bpi-invoice-drawer__header-metadata">
      <div class="bpi-invoice-drawer__header-metadata-assigner">
        Created {{ invoice.invoiceDate | ccDateFormatDistance: { addSuffix: true } }}
      </div>
    </div>
  </div>

  <div class="bpi-invoice-drawer__content">
    <div class="bpi-invoice-drawer__content-groups">
      <div class="bpi-invoice-drawer__content-groups-item">
        <div class="bpi-invoice-drawer__content-groups-item-title">PO#</div>
        <div class="bpi-invoice-drawer__content-groups-item-value">
          {{ invoice.poNumber }}
        </div>
      </div>

      <div class="bpi-invoice-drawer__content-groups-item">
        <div class="bpi-invoice-drawer__content-groups-item-title">PO Date</div>
        <div class="bpi-invoice-drawer__content-groups-item-value">
          {{ invoice.poDate | ccDateFormat: { date: 'long' } }}
        </div>
      </div>

      <div class="bpi-invoice-drawer__content-groups-item-alt">
        <div class="bpi-invoice-drawer__content-groups-item-alt-title">Supplier</div>
        <div class="bpi-invoice-drawer__content-groups-item-alt-value">
          <div class="bpi-invoice-drawer__content-groups-item-alt-value-main">
            <img
              [alt]="invoice.supplier"
              [ccTooltip]="invoice.supplier"
              height="48"
              ngSrc="https://picsum.photos/seed/1/200"
              width="48"
            />

            <span> {{ invoice.supplier }}</span>
          </div>

          <div class="bpi-invoice-drawer__content-groups-item-alt-value-address">{{ invoice.supplierAddress }}</div>
        </div>
      </div>

      <div class="bpi-invoice-drawer__content-groups-item-alt">
        <div class="bpi-invoice-drawer__content-groups-item-alt-title">Retailer</div>
        <div class="bpi-invoice-drawer__content-groups-item-alt-value">
          <div class="bpi-invoice-drawer__content-groups-item-alt-value-main">
            <img
              [alt]="invoice.retailer"
              [ccTooltip]="invoice.retailer"
              height="48"
              ngSrc="https://picsum.photos/seed/2/200"
              width="48"
            />

            <span> {{ invoice.retailer }}</span>
          </div>

          <div class="bpi-invoice-drawer__content-groups-item-alt-value-address">{{ invoice.retailerAddress }}</div>
        </div>
      </div>

      <div class="bpi-invoice-drawer__content-groups-item">
        <div class="bpi-invoice-drawer__content-groups-item-title">Payment Terms</div>
        <div class="bpi-invoice-drawer__content-groups-item-value">{{ invoice.paymentTerms }}</div>
      </div>

      <div class="bpi-invoice-drawer__content-groups-item">
        <div class="bpi-invoice-drawer__content-groups-item-title">Due date</div>
        <div class="bpi-invoice-drawer__content-groups-item-value">
          {{ invoice.dueDate | ccDateFormat: { date: 'long' } }}
        </div>
      </div>

      <div class="bpi-invoice-drawer__content-groups-item">
        <div class="bpi-invoice-drawer__content-groups-item-title">Amount</div>
        <div class="bpi-invoice-drawer__content-groups-item-value">{{ invoice.totalAmountDue | currency: 'USD' }}</div>
      </div>
    </div>
  </div>

  <div class="bpi-invoice-drawer__select-invoice">
    <app-button
      (click)="selectInvoice.emit(invoice)"
      [size]="ButtonSize.Small"
      [type]="ButtonType.Primary"
    >
      Select invoice
    </app-button>
  </div>
</div>
