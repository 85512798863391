import { Dialog } from '@angular/cdk/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Action, State, StateContext } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { CONVERSATIONS_BASE_URL } from '@conversations/routes';
import {
  AccountAlreadyConnectedDialogData,
  AccountAlreadyConnectedModalComponent,
} from '@conversations/workspaces/modals/account-already-connected-modal/account-already-connected-modal.component';
import { LoadLabels } from '@conversations/workspaces/state/labels/labels.actions';
import {
  defaultWorkspacesState,
  ExternalAccount,
  ExternalAccountErrorCode,
  ExternalAccountStatus,
  WorkspacesStateModel,
} from '@conversations/workspaces/state/workspaces/workspaces-state.model';
import {
  ConnectExternalAccount,
  DisconnectExternalAccount,
  LoadWorkspaces,
  PatchExternalAccount,
} from '@conversations/workspaces/state/workspaces/workspaces.actions';
import {
  NylasTokenExchangeResponse,
  WorkspacesService,
} from '@conversations/workspaces/state/workspaces/workspaces.service';
import { WINDOW } from '@core/helpers/global-objects';
import { CdkPortalService } from '@core/services/cdk-portal.service';
import { ToastType } from '@design/overlays/toast/toast';

import { LoadSignatures } from '../signatures/signatures.actions';

@State<WorkspacesStateModel>({
  name: 'workspaces',
  defaults: defaultWorkspacesState,
})
@Injectable()
export class WorkspacesState {
  private readonly workspacesService = inject(WorkspacesService);
  private readonly window = inject(WINDOW);
  private readonly portalService = inject(CdkPortalService);
  private readonly dialog = inject(Dialog);
  private readonly translate = inject(TranslateService);

  @Action(LoadWorkspaces)
  loadWorkspaces(ctx: StateContext<WorkspacesStateModel>): Observable<void> {
    ctx.patchState({ loadingStatus: 'loading' });

    return this.workspacesService.getWorkspacesAndExternalAccounts().pipe(
      switchMap(({ workspaces, externalAccounts }) => {
        return ctx
          .dispatch([
            new LoadLabels(workspaces.map((workspace) => workspace.id)),
            new LoadSignatures(workspaces.map((workspace) => workspace.id)),
          ])
          .pipe(map(() => ({ workspaces, externalAccounts })));
      }),
      tap(({ workspaces, externalAccounts }) => {
        ctx.patchState({ workspaces, externalAccounts, loadingStatus: 'loaded' });
      }),
      catchError(() => {
        ctx.patchState({ loadingStatus: 'error' });
        return of();
      }),
      map(() => undefined),
    );
  }

  @Action(PatchExternalAccount)
  patchExternalAccount(
    ctx: StateContext<WorkspacesStateModel>,
    { externalAccountId, newValues }: PatchExternalAccount,
  ): void {
    ctx.setState(
      patch<WorkspacesStateModel>({
        externalAccounts: updateItem<ExternalAccount>(
          (externalAccount) => externalAccount.id === externalAccountId,
          patch(newValues),
        ),
      }),
    );
  }

  @Action(ConnectExternalAccount)
  connectExternalAccount(
    _: StateContext<WorkspacesStateModel>,
    { state, code }: ConnectExternalAccount,
  ): Observable<void> {
    const location = this.window.location;
    const redirectUri = `${location.origin}/${CONVERSATIONS_BASE_URL}`;

    return this.workspacesService.exchangeNylasToken(code, redirectUri).pipe(
      catchError(() => {
        this.portalService.presentToast(
          this.translate.instant('common.errorMessages.somethingWentWrong'),
          ToastType.Error,
        );
        return of();
      }),
      switchMap((response: NylasTokenExchangeResponse | undefined) => {
        if (!response) return;
        const { grantId, email } = response;

        return this.workspacesService.createExternalAccount(Number(state.workspaceId), grantId, email).pipe(
          catchError((errorResponse: HttpErrorResponse) => {
            const errorCodes =
              errorResponse?.error?.errors?.map((error: { errorCode: string }) => error?.errorCode) || [];

            switch (true) {
              case errorCodes.includes(ExternalAccountErrorCode.AlreadyConnected):
                this.dialog.open<never, AccountAlreadyConnectedDialogData>(AccountAlreadyConnectedModalComponent, {
                  data: {
                    email,
                  },
                  panelClass: 'clover-priority-overlay-panel',
                  backdropClass: ['clover-priority-overlay-backdrop', 'cdk-overlay-dark-backdrop'],
                });
                break;

              case errorCodes.includes(ExternalAccountErrorCode.Disconnecting):
                this.portalService.presentToast(
                  this.translate.instant('conversations-v4.workspaces.settings.accounts.toasts.accountDisconnecting'),
                  ToastType.Error,
                );
                break;

              default:
                this.portalService.presentToast(
                  this.translate.instant('conversations-v4.workspaces.settings.accounts.toasts.failedToConnect'),
                  ToastType.Error,
                );
                break;
            }

            return of();
          }),
        );
      }),
      map(() => undefined),
    );
  }

  @Action(DisconnectExternalAccount)
  disconnectExternalAccount(
    ctx: StateContext<WorkspacesStateModel>,
    { workspaceId, externalAccountId }: DisconnectExternalAccount,
  ): Observable<void> {
    return this.workspacesService.disconnectExternalAccount(workspaceId, externalAccountId).pipe(
      switchMap(() =>
        ctx.dispatch(new PatchExternalAccount(externalAccountId, { status: ExternalAccountStatus.Disconnecting })),
      ),
      tap(() => {
        this.portalService.presentToast(
          this.translate.instant('conversations-v4.workspaces.settings.accounts.toasts.disconnected'),
          ToastType.Success,
        );
      }),
      catchError(() => {
        this.portalService.presentToast(
          this.translate.instant('conversations-v4.workspaces.settings.accounts.toasts.failedToDisconnect'),
          ToastType.Error,
        );
        return of();
      }),
      map(() => undefined),
    );
  }
}
