import { ChangeDetectionStrategy, Component, computed, effect, HostBinding, signal, type OnInit } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { AngularNodeViewComponent } from 'ngx-tiptap';
import { hostBinding } from 'ngxtension/host-binding';
import { equals } from 'ramda';
import { debounceTime } from 'rxjs/operators';

import { ImageResizerComponent } from './image-resizer/image-resizer.component';

@Component({
  standalone: true,
  imports: [ImageResizerComponent],
  templateUrl: './image.component.html',
  styleUrl: './image.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageComponent extends AngularNodeViewComponent implements OnInit {
  src = signal<string>('');
  alt = signal<string>('');
  width = hostBinding('style.width.px', signal(0));
  height = hostBinding('style.height.px', signal(0));
  radius = hostBinding('style.borderRadius.px', signal(0));

  attributes = toSignal(
    toObservable(
      computed(
        () => {
          return {
            src: this.src(),
            alt: this.alt(),
            width: this.width(),
            height: this.height(),
            radius: this.radius(),
          };
        },
        { equal: equals },
      ),
    ).pipe(debounceTime(100)),
  );

  @HostBinding('class.cc-selected')
  get isSelected(): boolean {
    return this.selected;
  }

  constructor() {
    super();

    effect(
      () => {
        this.updateAttributes(this.attributes());
      },
      { allowSignalWrites: true },
    );
  }

  ngOnInit(): void {
    this.src.set(this.node.attrs['src']);
    this.alt.set(this.node.attrs['alt']);
    this.width.set(this.node.attrs['width']);
    this.height.set(this.node.attrs['height']);
    this.radius.set(this.node.attrs['radius']);
  }
}
