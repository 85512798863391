import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  effect,
  inject,
  Injector,
  input,
  OnDestroy,
  OnInit,
  output,
  untracked,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { Editor } from '@tiptap/core';
import { debounceTime, distinctUntilChanged, filter, map, pairwise, switchMap, tap } from 'rxjs';

import { withBold } from '@design/forms/rich-text-editor/extensions/bold/bold.extension';
import { withBulletList } from '@design/forms/rich-text-editor/extensions/bullet-list/bullet-list.extension';
import { createExtensionKit } from '@design/forms/rich-text-editor/extensions/create-extension-kit';
import { withFontFamily } from '@design/forms/rich-text-editor/extensions/font-family/font-family.extension';
import { withFontSize } from '@design/forms/rich-text-editor/extensions/font-size/font-size.extension';
import { withItalic } from '@design/forms/rich-text-editor/extensions/italic/italic.extension';
import { withLink } from '@design/forms/rich-text-editor/extensions/link/link.extension';
import { withOrderedList } from '@design/forms/rich-text-editor/extensions/ordered-list/ordered-list.extension';
import { withPlaceholder } from '@design/forms/rich-text-editor/extensions/placeholder/placeholder.extension';
import { withStrike } from '@design/forms/rich-text-editor/extensions/strike/strike.extension';
import { withTable } from '@design/forms/rich-text-editor/extensions/table/table.extension';
import { withTaskAutocomplete } from '@design/forms/rich-text-editor/extensions/task-autocomplete/task-autocomplete.extension';
import { withUnderline } from '@design/forms/rich-text-editor/extensions/underline/underline.extension';

import { RichTextEditorComponent } from '../../../../stories/forms/rich-text-editor/rich-text-editor.component';
import { SaveDraft, SetMessage } from '../state/composers/composers.actions';

@Component({
  selector: 'cc-composer-message-input',
  standalone: true,
  templateUrl: './composer-message-input.component.html',
  styleUrls: ['./composer-message-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RichTextEditorComponent, ReactiveFormsModule],
})
export class ComposerMessageInputComponent implements OnInit, OnDestroy {
  composerId = input.required<string>();
  message = input<string>('');

  focusIn = output<void>();

  private readonly injector = inject(Injector);
  private readonly destroyRef = inject(DestroyRef);
  private readonly store = inject(Store);

  readonly editor = new Editor({
    content: this.message(),
    extensions: [
      createExtensionKit(
        withBold(),
        withItalic(),
        withStrike(),
        withUnderline(),
        withFontFamily(),
        withFontSize(),
        withBulletList(),
        withOrderedList(),
        withLink(this.injector),
        withTable(),
        withTaskAutocomplete(this.injector),
        withPlaceholder(inject(TranslateService).instant('conversations-v4.composer.input.placeholder')),
      ),
    ],
  });

  formControl = new FormControl<string>(this.message());

  constructor() {
    effect(() => {
      const message = this.message();

      untracked(() => {
        this.formControl.setValue(message, { emitEvent: false });
      });
    });
  }

  ngOnInit(): void {
    this.formControl.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        pairwise(),
        filter(([prevMessage, message]) => message !== prevMessage),
        map(([, message]) => message),
        tap((message) => {
          this.store.dispatch(new SetMessage(this.composerId(), message));
        }),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(() => this.store.dispatch(new SaveDraft(this.composerId()))),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }
}
