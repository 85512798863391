import { ConversationsCategory } from '@conversations/conversations/categories';
import { Conversation } from '@conversations/conversations/state/conversations/conversations-state.model';
import { ConversationCategoryFilterParams } from '@conversations/conversations/state/conversations/conversations.service';

export class LoadConversations {
  static readonly type = '[Conversations] Load Conversations';

  constructor(
    public workspaceId: number | undefined,
    public categoryId: ConversationsCategory,
    public filterParams: ConversationCategoryFilterParams,
  ) {}
}

export class LoadNextConversations {
  static readonly type = '[Conversations] Load Next Conversations';

  constructor() {}
}

export class LoadNewConversation {
  static readonly type = '[Conversations] Load New Conversation';

  constructor(public conversationId: string) {}
}

export class AddConversation {
  static readonly type = '[Conversations] Add Conversation';

  constructor(public conversation: Conversation) {}
}

export class UpsertConversation {
  static readonly type = '[Conversations] Upsert Conversation';

  constructor(public conversation: Conversation) {}
}

export class RemoveConversation {
  static readonly type = '[Conversations] Remove Conversation';

  constructor(public conversationId: string) {}
}

// TODO (Oleksandr D.): Think of a better place for this action (probably in a separate service).
//                      This action is not really related to the conversations state.
export class CreateChannel {
  static readonly type = '[Conversations] Create Channel';

  constructor(
    public workspaceId: number,
    public name: string,
    public memberIds: number[],
  ) {}
}
