import { Routes } from '@angular/router';

import { WorkspaceSettingsModalComponent } from '@conversations/workspaces/modals/workspace-settings-modal/workspace-settings-modal.component';
import { workspaceSettingsModalResolver } from '@conversations/workspaces/modals/workspace-settings-modal/workspace-settings-modal.resolver';

export const WORKSPACE_SETTINGS_MODAL_OUTLET = 'settings';

export enum WorkspaceSettingsModalTab {
  Inbox = 'inbox',
  Labels = 'labels',
  Signatures = 'signatures',
  Members = 'members',
  Notifications = 'notifications',
  Settings = 'settings',
}

export const privateWorkspaceSettingsModalTabs: WorkspaceSettingsModalTab[] = [
  WorkspaceSettingsModalTab.Inbox,
  WorkspaceSettingsModalTab.Labels,
  WorkspaceSettingsModalTab.Signatures,
  WorkspaceSettingsModalTab.Notifications,
  WorkspaceSettingsModalTab.Settings,
];

export const teamWorkspaceSettingsModalTabs: WorkspaceSettingsModalTab[] = [
  WorkspaceSettingsModalTab.Inbox,
  WorkspaceSettingsModalTab.Labels,
  WorkspaceSettingsModalTab.Signatures,
  WorkspaceSettingsModalTab.Members,
  WorkspaceSettingsModalTab.Notifications,
  WorkspaceSettingsModalTab.Settings,
];

export const workspaceSettingsModalRoutes: Routes = [
  {
    path: '',
    component: WorkspaceSettingsModalComponent,
    resolve: {
      workspace: workspaceSettingsModalResolver,
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: WorkspaceSettingsModalTab.Inbox + '',
      },
      {
        path: WorkspaceSettingsModalTab.Inbox,
        loadComponent: () =>
          import('./workspace-settings-inbox/workspace-settings-inbox.component').then(
            (c) => c.WorkspaceSettingsInboxComponent,
          ),
      },
      {
        path: WorkspaceSettingsModalTab.Labels,
        loadComponent: () =>
          import('./workspace-settings-labels/workspace-settings-labels.component').then(
            (c) => c.WorkspaceSettingsLabelsComponent,
          ),
      },
      {
        path: WorkspaceSettingsModalTab.Signatures,
        loadComponent: () =>
          import('./workspace-settings-signatures/workspace-settings-signatures.component').then(
            (c) => c.WorkspaceSettingsSignaturesComponent,
          ),
      },
      {
        path: WorkspaceSettingsModalTab.Members,
        loadComponent: () =>
          import('./workspace-settings-members/workspace-settings-members.component').then(
            (c) => c.WorkspaceSettingsMembersComponent,
          ),
      },
      {
        path: WorkspaceSettingsModalTab.Notifications,
        loadComponent: () =>
          import('./workspace-settings-notifications/workspace-settings-notifications.component').then(
            (c) => c.WorkspaceSettingsNotificationsComponent,
          ),
      },
      {
        path: WorkspaceSettingsModalTab.Settings,
        loadComponent: () =>
          import('./workspace-settings-settings/workspace-settings-settings.component').then(
            (c) => c.WorkspaceSettingsSettingsComponent,
          ),
      },
    ],
  },
];
