import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { debounceTime, distinctUntilChanged, filter, map, pairwise, switchMap, tap } from 'rxjs/operators';

import { SaveDraft, SetSubject } from '@conversations/composer/state/composers/composers.actions';

@Component({
  selector: 'cc-composer-subject-input',
  standalone: true,
  templateUrl: './composer-subject-input.component.html',
  styleUrls: ['./composer-subject-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ReactiveFormsModule, TranslateModule],
})
export class ComposerSubjectInputComponent implements OnInit, OnChanges {
  @Input()
  composerId: string;

  @Input()
  subject: string;

  protected subjectFormControl = new FormControl<string>('');

  private readonly store = inject(Store);
  private readonly destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.subjectFormControl.setValue(this.subject, { emitEvent: false });

    this.subjectFormControl.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        pairwise(),
        filter(([prevSubject, subject]) => subject !== prevSubject),
        map(([, subject]) => subject),
        tap((subject) => {
          this.store.dispatch(new SetSubject(this.composerId, subject));
        }),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(() => this.store.dispatch(new SaveDraft(this.composerId))),
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['subject']) this.subjectFormControl.setValue(this.subject, { emitEvent: false });
  }
}
