<div class="connect-email-cta">
  <div class="connect-email-cta__title">
    {{ 'conversations-v4.workspaces.connectAccountCta.title' | translate }}
  </div>
  <div class="connect-email-cta__description">
    {{ 'conversations-v4.workspaces.connectAccountCta.message' | translate }}
  </div>

  <div
    [routerLink]="link"
    class="connect-email-cta__button"
  >
    {{ 'conversations-v4.workspaces.connectAccountCta.connectAccount' | translate }}
  </div>
</div>
