import { animate, state, style, transition, trigger } from '@angular/animations';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  DestroyRef,
  inject,
  Input,
  OnInit,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Navigate } from '@ngxs/router-plugin';
import { Actions, ofActionCompleted, Store } from '@ngxs/store';
import { subSeconds } from 'date-fns';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { combineLatest, defer, Observable, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { ComposerActionsComponent } from '@conversations/composer/composer-actions/composer-actions.component';
import { ComposerAttachmentsComponent } from '@conversations/composer/composer-attachments/composer-attachments.component';
import { ComposerHeaderComponent } from '@conversations/composer/composer-header/composer-header.component';
import { ComposerMessageInputComponent } from '@conversations/composer/composer-message-input/composer-message-input.component';
import { ComposerMessageTypeSelectorComponent } from '@conversations/composer/composer-message-type-selector/composer-message-type-selector.component';
import { ComposerQuoteInputComponent } from '@conversations/composer/composer-quote-input/composer-quote-input.component';
import { ComposerReplyToMessagePreviewComponent } from '@conversations/composer/composer-reply-to-message-preview/composer-reply-to-message-preview.component';
import { ComposerSubjectInputComponent } from '@conversations/composer/composer-subject-input/composer-subject-input.component';
import {
  ComposerInstance,
  ComposerMessageType,
  type LinkedEmailComposerInstance,
} from '@conversations/composer/state/composers/composers-state.model';
import { ComposersSelectors } from '@conversations/composer/state/composers/composers.selectors';
import { type CompactMessage } from '@conversations/conversation/state/conversation/conversation-state.model';
import {
  LoadConversation,
  LoadMessagesAfterDate,
} from '@conversations/conversation/state/conversation/conversation.actions';
import { ConversationSelectors } from '@conversations/conversation/state/conversation/conversation.selectors';
import {
  ConversationsCategory,
  defaultStatusCategorySegmentOption,
  statusCategorySegmentParam,
} from '@conversations/conversations/categories';
import { CONVERSATIONS_BASE_URL } from '@conversations/routes';
import { WorkspacesSelectors } from '@conversations/workspaces/state/workspaces/workspaces.selectors';
import { UserService } from '@core/services/user.service';

import { ComposerSignatureInputComponent } from './composer-signature-input/composer-signature-input.component';

@Component({
  selector: 'cc-composer',
  standalone: true,
  templateUrl: './composer.component.html',
  styleUrls: ['./composer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ComposerHeaderComponent,
    ComposerMessageTypeSelectorComponent,
    AsyncPipe,
    ComposerReplyToMessagePreviewComponent,
    ComposerSubjectInputComponent,
    ComposerMessageInputComponent,
    NgScrollbarModule,
    CdkScrollableModule,
    ComposerActionsComponent,
    ComposerQuoteInputComponent,
    ComposerAttachmentsComponent,
    ComposerSignatureInputComponent,
  ],
  animations: [
    trigger('minimizeAnimation', [
      state('overlay-collapsed', style({ height: '0', opacity: '0' })),
      state('overlay-expanded', style({ height: '*', opacity: '*' })),
      state('inline', style({ height: '*', opacity: '*' })),
      transition('overlay-collapsed <=> overlay-expanded', animate('0.2s ease-in-out')),
    ]),
  ],
})
export class ComposerComponent implements OnInit {
  @Input()
  composer: ComposerInstance;

  protected readonly messageInput = viewChild(ComposerMessageInputComponent);
  protected readonly messageInputEditor = computed(() => this.messageInput()?.editor);

  protected readonly signatureInput = viewChild(ComposerSignatureInputComponent);
  protected readonly signatureInputEditor = computed(() => this.signatureInput()?.editor);

  protected currentUserIsAssignee = false;
  protected disabled = false;
  protected allowedMessageTypes$: Observable<ComposerMessageType[]>;

  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly userService = inject(UserService);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.allowedMessageTypes$ = this.store.select(ComposersSelectors.allowedMessageTypes(this.composer.id));

    if (this.composer.composerType === 'linkedEmail') {
      const composer = this.composer;

      this.store
        .select(ConversationSelectors.details)
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          filter((details) => details?.id === composer.conversationId),
          map((details) => details.assignment.assignee),
          tap((assignee) => {
            const privateWorkspace = this.store.selectSnapshot(WorkspacesSelectors.privateWorkspace);
            if (composer.workspaceId === privateWorkspace.id) {
              this.currentUserIsAssignee = true;
              this.cdr.detectChanges();
              return;
            }

            this.currentUserIsAssignee = assignee?.id === this.userService.userProfile.id.toString();
            this.cdr.detectChanges();
          }),
        )
        .subscribe();

      combineLatest([
        this.store.select(ConversationSelectors.details),
        this.store.select(ConversationSelectors.parentAccountIssue),
      ])
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          filter(([details]) => !!details),
          filter(([details]) => details.id === composer.conversationId),
          map(([, parentAccountIssue]) => !!parentAccountIssue),
        )
        .subscribe((hasParentAccountIssue) => {
          this.disabled = hasParentAccountIssue;
          this.cdr.detectChanges();
        });
    }
  }

  jumpToMessage(message: CompactMessage): void {
    if (this.composer.composerType !== 'linkedEmail') return;
    const composer = this.composer as LinkedEmailComposerInstance;

    const activeConversationId = this.store.selectSnapshot(ConversationSelectors.details)?.id;

    defer(() => {
      if (activeConversationId === message.conversationId) return of(undefined);

      const workspaceAlias = this.store.selectSnapshot(WorkspacesSelectors.workspaceAliasById(composer.workspaceId));

      return this.store
        .dispatch(
          new Navigate([
            '/',
            CONVERSATIONS_BASE_URL,
            workspaceAlias,
            ConversationsCategory.Inbox,
            { [statusCategorySegmentParam]: defaultStatusCategorySegmentOption },
            composer.conversationId,
          ]),
        )
        .pipe(switchMap(() => this.actions$.pipe(ofActionCompleted(LoadConversation), take(1))));
    })
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap(() =>
          this.store.dispatch(new LoadMessagesAfterDate(subSeconds(new Date(message.createdAt), 1).toISOString())),
        ),
      )
      .subscribe();
  }
}
