import { DIALOG_DATA, DialogModule, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import {
  ExternalAccount,
  ExternalAccountStatus,
} from '@conversations/workspaces/state/workspaces/workspaces-state.model';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { DropdownActionComponent } from '@design/overlays/dropdown/dropdown-action/dropdown-action.component';

export interface AccountSyncIssuesDialogData {
  issueType: ExternalAccountStatus.Failed | ExternalAccountStatus.Expired;
  failedAccounts: ExternalAccount[];
}

@Component({
  standalone: true,
  templateUrl: './account-sync-issues-modal.component.html',
  styleUrls: ['./account-sync-issues-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonComponent, DialogModule, DropdownActionComponent, TranslateModule],
})
export class AccountSyncIssuesModalComponent {
  protected readonly dialogData: AccountSyncIssuesDialogData = inject(DIALOG_DATA);
  protected readonly dialogRef: DialogRef<ExternalAccount> = inject(DialogRef<ExternalAccount>);

  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;
  protected readonly ExternalAccountStatus = ExternalAccountStatus;
}
