import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { EmailParticipant } from '@conversations/conversation/state/conversation/conversation-state.model';
import { EMAIL_REGEX_PATTERN } from '@core/constants/regex-patterns';

@Component({
  selector: 'cc-composer-recipients-selector-preview-item',
  standalone: true,
  templateUrl: './composer-recipients-selector-preview-item.component.html',
  styleUrls: ['./composer-recipients-selector-preview-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComposerRecipientsSelectorPreviewItemComponent {
  @Input()
  recipient: EmailParticipant;

  get emailValid(): boolean {
    return EMAIL_REGEX_PATTERN.test(this.recipient.email);
  }
}
