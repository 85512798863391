import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  ViewEncapsulation,
  type OnDestroy,
  type OnInit,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Editor } from '@tiptap/core';
import { NgxTiptapModule } from 'ngx-tiptap';
import { NgxControlValueAccessor } from 'ngxtension/control-value-accessor';

@Component({
  selector: 'cc-rich-text-editor',
  standalone: true,
  imports: [NgxTiptapModule, FormsModule],
  hostDirectives: [
    {
      directive: NgxControlValueAccessor,
      inputs: ['value'],
      outputs: ['valueChange'],
    },
  ],
  template: `
    <tiptap
      [editor]="editor()"
      outputFormat="html"
      [(ngModel)]="cva.value$"
      [disabled]="cva.disabled$()"
    />
  `,
  styleUrls: ['./rich-text-editor.component.scss', '/node_modules/prosemirror-view/style/prosemirror.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class RichTextEditorComponent implements OnInit, OnDestroy {
  editor = input.required<Editor>();

  protected readonly cva = inject<NgxControlValueAccessor<string>>(NgxControlValueAccessor);

  ngOnInit(): void {
    this.editor().on('blur', this.markAsTouched.bind(this));
  }

  ngOnDestroy(): void {
    this.editor().off('blur', this.markAsTouched.bind(this));
  }

  private markAsTouched(): void {
    this.cva.markAsTouched();
  }
}
