<div class="bpi-invoice-table">
  <div class="bpi-invoice-table__content">
    <div class="bpi-invoice-table__content-header">
      <app-textbox
        [icon]="'icon-search'"
        [type]="TextboxType.Text"
        class="bpi-invoice-table__content-header-search"
        placeholder="Search invoices..."
        width="100%"
      >
      </app-textbox>

      <app-button
        [size]="ButtonSize.Small"
        [type]="ButtonType.Secondary"
      >
        All Invoices
        <i
          class="icon-expand"
          slot="end-icon"
        ></i>
      </app-button>
    </div>

    <table class="bpi-invoice-table__content-table">
      <thead>
        <tr>
          <th>INV #</th>
          <th style="width: 160px">Due date</th>
          <th style="width: 54px">Sender's Company</th>
          <th style="width: 54px">Assignee</th>
        </tr>
      </thead>

      <tbody>
        @for (invoice of MOCK_BPI_INVOICES; track invoice.id) {
          <tr
            (click)="showInDrawer(invoice)"
            [class.active-row]="invoice.id === activeInvoiceId"
          >
            <td>
              <div class="po-details">
                <div class="po-details__header">
                  <div class="po-details__header-title">
                    {{ invoice.invoiceNumber }}
                  </div>

                  <div
                    [style.background-color]="getStatusBgColor(invoice)"
                    [style.color]="getStatusFgColor(invoice)"
                    class="po-details__header-status"
                  >
                    {{ getStatusText(invoice) }}
                  </div>
                </div>

                <div class="po-details__amount">Amount: {{ invoice.totalAmountDue | currency: 'USD' }}</div>
              </div>
            </td>
            <td>{{ invoice.dueDate | ccDateFormat: { date: 'long' } }}</td>
            <td>
              <img
                [alt]="invoice.retailer"
                [ccTooltip]="invoice.retailer"
                class="company-avatar"
                height="48"
                ngSrc="https://picsum.photos/seed/1/200"
                width="48"
              />
            </td>
            <td>
              <img
                [alt]="invoice.supplier"
                [ccTooltip]="invoice.supplier"
                class="company-avatar"
                height="48"
                ngSrc="https://picsum.photos/seed/2/200"
                width="48"
              />
            </td>
          </tr>
        }
      </tbody>
    </table>
  </div>

  @if (activeInvoice) {
    <div
      @drawer
      class="bpi-invoice-table__drawer"
    >
      <cc-bpi-invoice-drawer
        (closeDrawer)="showInDrawer(null)"
        (selectInvoice)="selectInvoice.emit($event)"
        [invoice]="activeInvoice"
        class="bpi-invoice-table__drawer-inner"
      />
    </div>
  }
</div>
