<div
  [class.composer-attachments--collapsed]="collapsed"
  class="composer-attachments"
>
  <div
    (click)="collapsed = false"
    [ccTooltip]="'conversations-v4.composer.attachments.showAttachedFiles' | translate"
    [tooltipAlignment]="TooltipAlignment.Start"
    class="composer-attachments__expand-button"
    role="button"
  >
    <i class="icon-attachment-alt"></i>

    @if (count === 1) {
      {{ 'conversations-v4.composer.attachments.attachmentsCountSingular' | translate }}
    }
    @if (count !== 1) {
      {{ 'conversations-v4.composer.attachments.attachmentsCountSingular' | translate: { count } }}
    }
  </div>

  <div class="composer-attachments__expanded-container">
    <div class="composer-attachments__expanded-container-header">
      <app-button
        (click)="collapsed = true"
        [ccTooltip]="'conversations-v4.composer.attachments.hideAttachedFiles' | translate"
        [size]="ButtonSize.Small"
        [tooltipAlignment]="TooltipAlignment.Start"
        [type]="ButtonType.Ghost"
        class="composer-attachments__expanded-container-header-collapse-button"
      >
        <i
          class="icon-collapse"
          slot="ghost-icon"
        ></i>
      </app-button>

      <div class="composer-attachments__expanded-container-header-label">
        @if (count === 1) {
          {{ 'conversations-v4.composer.attachments.attachmentsCountSingular' | translate }}
        }
        @if (count !== 1) {
          {{ 'conversations-v4.composer.attachments.attachmentsCountSingular' | translate: { count } }}
        }
      </div>

      <div class="composer-attachments__expanded-container-header-size">
        {{ 'conversations-v4.composer.attachments.totalSize' | translate: { size: (totalSize | formatBytes) } }}

        <i
          [ccTooltip]="'conversations-v4.composer.attachments.totalSizeTooltip' | translate"
          [tooltipAlignment]="TooltipAlignment.End"
          class="composer-attachments__expanded-container-header-size-tooltip icon-info-fill"
        ></i>
      </div>
    </div>

    <div class="composer-attachments__expanded-container-list">
      @for (attachment of draftAttachments; track attachment.id) {
        <div class="composer-attachments__expanded-container-list-item">
          <i class="composer-attachments__expanded-container-list-item-icon icon-editor-attachment"></i>

          <div class="composer-attachments__expanded-container-list-item-details">
            <div class="composer-attachments__expanded-container-list-item-details-name">
              {{ attachment.name }}
            </div>

            <div class="composer-attachments__expanded-container-list-item-details-size">
              {{ attachment.size | formatBytes }}
            </div>
          </div>

          <div class="composer-attachments__expanded-container-list-item-actions">
            <app-button
              (click)="removeAttachment(attachment)"
              [size]="ButtonSize.Small"
              [tooltipAlignment]="TooltipAlignment.End"
              [type]="ButtonType.Ghost"
              [ccTooltip]="'common.buttons.remove' | translate"
            >
              <i
                class="icon-remove-attachment"
                slot="ghost-icon"
              ></i>
            </app-button>
          </div>
        </div>
      }

      @for (attachment of activeUploads; track attachment.id) {
        <div class="composer-attachments__expanded-container-list-item">
          <i class="composer-attachments__expanded-container-list-item-icon icon-editor-attachment"></i>

          <div
            [class.composer-attachments__expanded-container-list-item-details--tinted]="attachment.status === 'error'"
            class="composer-attachments__expanded-container-list-item-details"
          >
            <div class="composer-attachments__expanded-container-list-item-details-name">
              {{ attachment.file.name }}
            </div>
            <div class="composer-attachments__expanded-container-list-item-details-size">
              {{ attachment.file.size | formatBytes }}
            </div>
          </div>

          <div class="composer-attachments__expanded-container-list-item-actions">
            @if (attachment.status === 'uploading') {
              <cc-progress-bar
                [height]="8"
                class="composer-attachments__expanded-container-list-item-actions-progress"
                [ccTooltip]="formatProgress(attachment.progress)"
                [progress]="attachment.progress"
              />
            }

            @if (attachment.status === 'error') {
              <div class="composer-attachments__expanded-container-list-item-actions-error-notice">
                {{ 'conversations-v4.composer.attachments.uploadFailed' | translate }}
              </div>

              <app-button
                (click)="retryUpload(attachment)"
                [size]="ButtonSize.Small"
                [tooltipAlignment]="TooltipAlignment.End"
                [type]="ButtonType.Ghost"
                [ccTooltip]="'common.buttons.tryAgain' | translate"
              >
                <i
                  class="icon-retry"
                  slot="ghost-icon"
                ></i>
              </app-button>
            }

            <app-button
              (click)="cancelUpload(attachment)"
              [ccTooltip]="
                attachment.status === 'error'
                  ? ('common.buttons.remove' | translate)
                  : ('common.buttons.cancel' | translate)
              "
              [size]="ButtonSize.Small"
              [tooltipAlignment]="TooltipAlignment.End"
              [type]="ButtonType.Ghost"
            >
              <i
                class="icon-remove-attachment"
                slot="ghost-icon"
              ></i>
            </app-button>
          </div>
        </div>
      }
    </div>
  </div>
</div>
