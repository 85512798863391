import { Extension, type Extensions } from '@tiptap/core';
import Document from '@tiptap/extension-document';
import History from '@tiptap/extension-history';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Typography from '@tiptap/extension-typography';
import { prop, uniqBy } from 'ramda';

const coreExtensions = [
  Document,
  Paragraph.configure({
    HTMLAttributes: {
      style: ['margin: 0', 'line-height: 1.4', 'min-height: 1lh'].join('; '),
    },
  }),
  Text,
  History,
  Typography,
];

export function createExtensionKit(...extensions: Extensions[]): Extension {
  const customExtensions = uniqBy(prop('name'))(extensions.flat());

  return Extension.create({
    name: 'extensionKit',

    addExtensions() {
      return [...coreExtensions, ...customExtensions];
    },
  });
}
