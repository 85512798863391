import { inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { patch, removeItem } from '@ngxs/store/operators';
import { tap } from 'rxjs';
import { map } from 'rxjs/operators';

import { createDraftConversationId } from '@clover/conversations-v4/conversations/state/conversations/conversations.service';
import { ResetComposer } from '@conversations/composer/state/composers/composers.actions';
import { ComposersSelectors } from '@conversations/composer/state/composers/composers.selectors';
import { ConversationActionsDistributorService } from '@conversations/conversation/state/conversation/conversation-actions-distributor.service';
import {
  Draft,
  DraftMessageType,
  type LinkedEmailDraft,
} from '@conversations/conversation/state/conversation/conversation-state.model';
import { PatchConversationMessage } from '@conversations/conversation/state/conversation/conversation.actions';
import { upsertItem } from '@core/helpers/custom-state-operators';
import { ccPreventDuplicateSubscriptions } from '@core/helpers/prevent-duplicate-subscriptions';
import { HttpService } from '@core/services/http.service';

@Injectable({
  providedIn: 'root',
})
export class ComposersActionsDistributorService {
  private readonly http = inject(HttpService);
  private readonly store = inject(Store);
  private readonly conversationActionsService = inject(ConversationActionsDistributorService);

  @ccPreventDuplicateSubscriptions('first-wins')
  deleteDraft(draft: Draft) {
    return this.http
      .deleteV2(`api/stream-conversations/drafts/${draft.id}`)
      .pipe(
        tap(() => this.removeDraft(draft)),
        map(() => undefined),
      )
      .subscribe();
  }

  upsertDraft(draft: Draft): void {
    if (draft.type !== DraftMessageType.NewEmail) {
      this.conversationActionsService.handleConversationUpdate(draft.replyToMessage.conversationId);
      this.addDraftToMessage(draft);
    } else {
      this.conversationActionsService.handleConversationUpdate(createDraftConversationId(draft.id));
    }
  }

  removeDraft(draft: Draft): void {
    if (draft.type !== DraftMessageType.NewEmail) {
      this.conversationActionsService.handleConversationUpdate(draft.replyToMessage.conversationId);
      this.removeDraftFromMessage(draft);
    } else {
      this.conversationActionsService.handleConversationUpdate(createDraftConversationId(draft.id));
    }

    // Remove draft from composer
    const composerWithDraft = this.store.selectSnapshot(ComposersSelectors.composerByDraftId(draft.id));
    if (composerWithDraft) this.store.dispatch(new ResetComposer(composerWithDraft.id));
  }

  addDraftToMessage(draft: LinkedEmailDraft): void {
    // Add draft reference to the message
    this.store.dispatch(
      new PatchConversationMessage(
        draft.replyToMessage.conversationId,
        draft.replyToMessage.id,
        patch({
          drafts: upsertItem((item) => item.id === draft.id, draft),
        }),
      ),
    );
  }

  removeDraftFromMessage(draft: LinkedEmailDraft): void {
    // Remove draft reference from the message
    this.store.dispatch(
      new PatchConversationMessage(
        draft.replyToMessage.conversationId,
        draft.replyToMessage.id,
        patch({
          drafts: removeItem((item) => item.id === draft.id),
        }),
      ),
    );
  }
}
