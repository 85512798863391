<div
  [class.composer-signature-input--collapsed]="collapsed"
  class="composer-signature-input"
>
  <div
    (click)="collapsed = false"
    (keydown.enter)="collapsed = false"
    [ccTooltip]="'conversations-v4.composer.signatureInput.showSignature' | translate"
    [tooltipAlignment]="TooltipAlignment.Start"
    class="composer-signature-input__expand-button"
    role="button"
    tabindex="0"
  >
    <i class="icon-signature"></i>

    Signature
  </div>

  <div class="composer-signature-input__expanded-container">
    <app-button
      (click)="collapsed = true"
      [ccTooltip]="'conversations-v4.composer.signatureInput.hideSignature' | translate"
      [size]="ButtonSize.Small"
      [tooltipAlignment]="TooltipAlignment.Start"
      [type]="ButtonType.Ghost"
      class="composer-signature-input__expanded-container-collapse-button"
    >
      <i
        class="icon-collapse"
        slot="ghost-icon"
      ></i>
    </app-button>

    <div class="composer-signature-input__expanded-container-header">
      {{ 'conversations-v4.composer.signatureInput.title' | translate }}
    </div>

    <cc-rich-text-editor
      class="composer-signature-input__expanded-container-editor"
      [editor]="editor"
      [formControl]="signatureFormControl"
    />
  </div>
</div>
