import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnChanges,
  OnInit,
  output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { NgScrollbarModule } from 'ngx-scrollbar';
import * as R from 'ramda';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';

import { Label } from '@conversations/workspaces/state/labels/labels-state.model';
import { LabelsSelectors } from '@conversations/workspaces/state/labels/labels.selectors';
import { Workspace, WorkspaceOwnerType } from '@conversations/workspaces/state/workspaces/workspaces-state.model';
import { WorkspacesSelectors } from '@conversations/workspaces/state/workspaces/workspaces.selectors';
import { CheckboxComponent } from '@design/forms/checkbox/checkbox.component';
import { TextboxComponent } from '@design/forms/textbox/textbox.component';
import { TextboxType } from '@design/forms/textbox/textbox.types';
import { LabelIconComponent } from '@design/misc/label-icon/label-icon.component';
import { DropdownActionComponent } from '@design/overlays/dropdown/dropdown-action/dropdown-action.component';
import { DropdownDividerComponent } from '@design/overlays/dropdown/dropdown-divider/dropdown-divider.component';
import { DropdownTextComponent } from '@design/overlays/dropdown/dropdown-text/dropdown-text.component';
import { DropdownComponent } from '@design/overlays/dropdown/dropdown.component';

import { RadioComponent } from '../../forms/radio/radio.component';

interface LabelGroup {
  workspace: Workspace;
  labels: Label[];
}

@Component({
  selector: 'cc-label-picker',
  standalone: true,
  imports: [
    DropdownDividerComponent,
    DropdownTextComponent,
    DropdownActionComponent,
    LabelIconComponent,
    DropdownComponent,
    CdkScrollableModule,
    NgScrollbarModule,
    ReactiveFormsModule,
    TextboxComponent,
    CheckboxComponent,
    AsyncPipe,
    TranslateModule,
    RadioComponent,
  ],
  templateUrl: './label-picker.component.html',
  styleUrls: ['./label-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelPickerComponent implements OnInit, OnChanges {
  @Input()
  workspaceIds: number[] = [];

  @Input()
  primaryWorkspaceId: number;

  @Input()
  selectedLabelIds: number[] = [];

  @Input()
  selectionMode: 'single' | 'single-radio' | 'multiple' = 'single';

  @Input()
  omittedLabelIds: number[] = [];

  @Input()
  forceShowPrivateWorkspaceLabels = false;

  @Input()
  listMaxHeight = '360px';

  @Input()
  showManageLabels = true;

  selectLabel = output<Label>();

  deselectLabel = output<Label>();

  createLabel = output<string>();

  manageLabels = output<void>();

  protected labelGroups$: Observable<LabelGroup[]>;
  protected workspaceIds$ = new BehaviorSubject(this.workspaceIds);
  protected omittedLabelIds$ = new BehaviorSubject(this.omittedLabelIds);
  protected primaryWorkspace$: Observable<Workspace>;
  protected searchFormControl = new FormControl<string>('');

  protected readonly TextboxType = TextboxType;
  protected readonly WorkspaceOwnerType = WorkspaceOwnerType;

  private readonly store = inject(Store);

  ngOnInit(): void {
    const workspaces$ = this.store.select(WorkspacesSelectors.workspaces);
    const labels$ = this.store.select(LabelsSelectors.labels);
    const query$ = this.searchFormControl.valueChanges.pipe(startWith(''));
    const workspaceIds$ = this.workspaceIds$.pipe(distinctUntilChanged((a, b) => R.equals(a, b)));
    const omittedLabelIds$ = this.omittedLabelIds$.pipe(distinctUntilChanged((a, b) => R.equals(a, b)));

    this.labelGroups$ = combineLatest([workspaces$, labels$, query$, workspaceIds$, omittedLabelIds$]).pipe(
      map(([workspaces, labels, query, workspaceIds, omittedLabelIds]) => {
        const filteredWorkspaces = workspaces.filter(
          (workspace) =>
            workspaceIds.includes(workspace.id) ||
            (this.forceShowPrivateWorkspaceLabels && workspace.ownerType === WorkspaceOwnerType.User),
        );

        const filteredLabels = labels
          .filter((label) => !label.system)
          .filter((label) => !omittedLabelIds.includes(label.id))
          .filter((label) => {
            if (!query) return true;
            return label.name.toLowerCase().includes(query.toLowerCase());
          });

        return [filteredWorkspaces, filteredLabels] as const;
      }),
      map(([workspaces, labels]) => {
        const sortedWorkspaces = workspaces.sort((a, b) => {
          const aPrivate = a.ownerType === WorkspaceOwnerType.User;
          const bPrivate = b.ownerType === WorkspaceOwnerType.User;

          if (aPrivate === bPrivate) return 0;
          return aPrivate ? -1 : 1;
        });

        return [sortedWorkspaces, labels] as const;
      }),
      map(([workspaces, labels]) => {
        return workspaces.map((workspace) => ({
          workspace,
          labels: labels.filter((label) => label.workspaceId === workspace.id),
        }));
      }),
      map((labelGroups) => {
        return labelGroups.filter((labelGroup) => labelGroup.labels.length > 0);
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['workspaceIds']) {
      this.workspaceIds$.next(this.workspaceIds);
    }

    if (changes['primaryWorkspaceId']) {
      this.primaryWorkspace$ = this.store.select(WorkspacesSelectors.workspaceById(this.primaryWorkspaceId));
    }

    if (changes['omittedLabelIds']) {
      this.omittedLabelIds$.next(this.omittedLabelIds);
    }
  }

  handleRadioChange(event: Event, label: Label): void {
    const radio = event.target as HTMLInputElement;

    if (radio.checked) {
      this.selectLabel.emit(label);
    }
  }

  handleCheckboxChange(event: Event, label: Label): void {
    const checkbox = event.target as HTMLInputElement;

    if (checkbox.checked) {
      this.selectLabel.emit(label);
      return;
    }

    this.deselectLabel.emit(label);
  }
}
