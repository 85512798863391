<div class="composer-reply-to-message-preview">
  <div class="composer-reply-to-message-preview__message">
    <div class="composer-reply-to-message-preview__message-sender">
      <img
        [alt]="replyToMessage.sender.name"
        [src]="senderAvatar"
        class="composer-reply-to-message-preview__message-sender-avatar"
        height="36px"
        width="36px"
      />

      <div class="composer-reply-to-message-preview__message-sender-name">
        {{ replyToMessage.sender.name }}
      </div>
    </div>

    <div class="composer-reply-to-message-preview__message-snippet">
      {{ replyToMessage.snippet }}
    </div>
  </div>

  <app-button
    (click)="jumpToReplyToMessage.emit()"
    [ccTooltip]="'conversations-v4.common.jumpToMessage' | translate"
    [size]="ButtonSize.Small"
    [tooltipAlignment]="TooltipAlignment.End"
    [type]="ButtonType.Ghost"
  >
    <i
      class="icon-message-share"
      slot="ghost-icon"
    ></i>
  </app-button>
</div>
