<cc-select
  [ccTooltip]="'richTextEditor.actions.fontSize.title' | translate"
  [ngModel]="fontSize()"
  (ngModelChange)="setFontSize($event)"
  appearance="ghost"
>
  <ng-container ccOptions>
    <cc-dropdown-text>
      <ng-container slot="title">
        {{ 'richTextEditor.actions.fontSize.title' | translate }}
      </ng-container>
    </cc-dropdown-text>

    @for (option of fontSizeOptions; track option.id) {
      <cc-select-item
        class="cc-font-size-option-item"
        [style.--font-size.px]="option.payload || 14"
        [value]="option.title"
        [id]="option.id"
        [payload]="option.payload"
      />
    }
  </ng-container>
</cc-select>
