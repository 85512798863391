export interface LabelsStateModel {
  labels: Label[];
  loadingStatus: 'void' | 'loading' | 'loaded' | 'error';
}

export const defaultLabelsState: LabelsStateModel = {
  labels: [],
  loadingStatus: 'void',
};

export interface Label {
  id: number;
  workspaceId: number;
  name: string;
  backgroundColor: string;
  foregroundColor: string;
  system: boolean;
  private: boolean;
  visibleInNavigation: boolean;
}
