<cc-create-button class="create-button"></cc-create-button>

<cc-page-with-subnav
  [previewTriggerElementId]="'conversationsV4NavItem'"
  [subnavTemplate]="subnav"
  id="conversations-v4"
>
  <router-outlet></router-outlet>
</cc-page-with-subnav>

<ng-template #subnav>
  <cc-workspaces></cc-workspaces>
</ng-template>

<router-outlet [name]="WORKSPACE_SETTINGS_MODAL_OUTLET"></router-outlet>

<cc-overlay-composers></cc-overlay-composers>
