import { Clipboard } from '@angular/cdk/clipboard';
import { ConnectedPosition, OverlayModule } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, HostBinding, inject, Input, output } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { EmailParticipant } from '@conversations/conversation/state/conversation/conversation-state.model';
import { EMAIL_REGEX_PATTERN } from '@core/constants/regex-patterns';
import { generateGenericAvatar } from '@core/helpers/generateGenericAvatar';
import { getOverlayVisibilityAfterOutsideClick } from '@core/helpers/get-overlay-visibility-after-outside-click';
import { CdkPortalService } from '@core/services/cdk-portal.service';
import { DropdownActionComponent } from '@design/overlays/dropdown/dropdown-action/dropdown-action.component';
import { DropdownDividerComponent } from '@design/overlays/dropdown/dropdown-divider/dropdown-divider.component';
import { DropdownComponent } from '@design/overlays/dropdown/dropdown.component';

@Component({
  selector: 'cc-composer-recipients-selector-contact-chip',
  standalone: true,
  templateUrl: './composer-recipients-selector-contact-chip.component.html',
  styleUrls: ['./composer-recipients-selector-contact-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DropdownComponent, DropdownDividerComponent, DropdownActionComponent, OverlayModule, TranslateModule],
})
export class ComposerRecipientsSelectorContactChipComponent {
  @Input()
  contact: EmailParticipant;

  remove = output<void>();

  edit = output<void>();

  protected dropdownVisible = false;
  protected readonly dropdownPositionStrategy: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
      offsetY: 6,
    },
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
      offsetY: 6,
    },
  ];
  protected readonly getOverlayVisibilityAfterOutsideClick = getOverlayVisibilityAfterOutsideClick;

  private readonly clipboard = inject(Clipboard);
  private readonly portalService = inject(CdkPortalService);
  private readonly translate = inject(TranslateService);

  get avatar(): string {
    if (this.contact.avatarUrl) return this.contact.avatarUrl;

    const nameTokens = this.contact.name?.trim()?.split(' ') || [];
    const firstName = nameTokens[0] || '—';
    const lastName = nameTokens[1] || '';

    return generateGenericAvatar(firstName, lastName, 64);
  }

  get emailValid(): boolean {
    return EMAIL_REGEX_PATTERN.test(this.contact.email);
  }

  @HostBinding('attr.role')
  get role(): string {
    return 'button';
  }

  @HostBinding('attr.tabindex')
  get tabindex(): number {
    return 0;
  }

  protected copyEmailToClipboard(): void {
    this.clipboard.copy(this.contact.email);
    this.portalService.presentToast(this.translate.instant('common.strings.emailCopied'));
  }
}
