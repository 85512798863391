<div class="task-embed-modal">
  <div class="task-embed-modal__header">
    <div class="task-embed-modal__header-title">
      {{ 'conversations-v4.tasks.embedModal.selectTaskAssignment' | translate }}
    </div>

    <app-button
      (click)="closeDialog()"
      [ccTooltip]="'common.buttons.close' | translate"
      [size]="ButtonSize.Small"
      [tooltipAlignment]="TooltipAlignment.End"
      [type]="ButtonType.Ghost"
    >
      <i
        class="icon-close"
        slot="ghost-icon"
      ></i>
    </app-button>
  </div>

  <div class="task-embed-modal__body">
    <div class="task-embed-modal__body-main">
      <div class="task-embed-modal__body-main-filters">
        <app-textbox
          [formControl]="searchFormControl"
          [placeholder]="'conversations-v4.tasks.embedModal.searchPlaceholder' | translate"
          class="task-embed-modal__body-main-filters-search"
          icon="icon-search"
          width="100%"
        >
        </app-textbox>

        <cc-button-group>
          <app-button
            (click)="tasksType$.next(TaskType.Received)"
            [active]="tasksType$.value === TaskType.Received"
            [size]="ButtonSize.Small"
            [type]="ButtonType.Secondary"
          >
            {{ 'conversations-v4.tasks.embedModal.received' | translate }}
          </app-button>

          <app-button
            (click)="tasksType$.next(TaskType.Sent)"
            [active]="tasksType$.value === TaskType.Sent"
            [size]="ButtonSize.Small"
            [type]="ButtonType.Secondary"
          >
            {{ 'conversations-v4.tasks.embedModal.sent' | translate }}
          </app-button>
        </cc-button-group>

        <div
          #statusSelect
          (click)="statusSelectDropdownVisible = !statusSelectDropdownVisible"
          class="task-embed-modal__body-main-filters-status-select"
        >
          <div class="task-embed-modal__body-main-filters-status-select-value">
            @switch (tasksStatus$.value) {
              @case (FilterableTaskStatus.All) {
                {{ 'conversations-v4.tasks.embedModal.allTasks' | translate }}
              }
              @case (FilterableTaskStatus.NotStarted) {
                {{ 'conversations-v4.tasks.embedModal.notStarted' | translate }}
              }
              @case (FilterableTaskStatus.InProgress) {
                {{ 'conversations-v4.tasks.embedModal.inProgress' | translate }}
              }
              @case (FilterableTaskStatus.Completed) {
                {{ 'conversations-v4.tasks.embedModal.completed' | translate }}
              }
              @case (FilterableTaskStatus.NotConnected) {
                {{ 'conversations-v4.tasks.embedModal.noConnection' | translate }}
              }
              @case (FilterableTaskStatus.WaitingForApproval) {
                {{ 'conversations-v4.tasks.embedModal.waitingForApproval' | translate }}
              }
            }
          </div>

          <i
            [class.icon-collapse]="statusSelectDropdownVisible"
            [class.icon-expand]="!statusSelectDropdownVisible"
          ></i>
        </div>

        <ng-template
          (detach)="statusSelectDropdownVisible = false"
          (overlayOutsideClick)="
            statusSelectDropdownVisible = getOverlayVisibilityAfterOutsideClick($event, statusSelect)
          "
          [cdkConnectedOverlayOpen]="statusSelectDropdownVisible"
          [cdkConnectedOverlayOrigin]="statusSelect"
          [cdkConnectedOverlayPositions]="statusSelectDropdownPositionStrategy"
          cdkConnectedOverlay
        >
          <cc-dropdown width="184px">
            <cc-dropdown-action
              (click)="statusSelectDropdownVisible = false; tasksStatus$.next(FilterableTaskStatus.All)"
              [selectable]="true"
              [selected]="tasksStatus$.value === FilterableTaskStatus.All"
            >
              <ng-container slot="title">
                {{ 'conversations-v4.tasks.embedModal.allTasks' | translate }}
              </ng-container>
            </cc-dropdown-action>

            <cc-dropdown-action
              (click)="statusSelectDropdownVisible = false; tasksStatus$.next(FilterableTaskStatus.NotStarted)"
              [selectable]="true"
              [selected]="tasksStatus$.value === FilterableTaskStatus.NotStarted"
            >
              <ng-container slot="title">
                {{ 'conversations-v4.tasks.embedModal.notStarted' | translate }}
              </ng-container>
            </cc-dropdown-action>

            <cc-dropdown-action
              (click)="statusSelectDropdownVisible = false; tasksStatus$.next(FilterableTaskStatus.InProgress)"
              [selectable]="true"
              [selected]="tasksStatus$.value === FilterableTaskStatus.InProgress"
            >
              <ng-container slot="title">
                {{ 'conversations-v4.tasks.embedModal.inProgress' | translate }}
              </ng-container>
            </cc-dropdown-action>

            <cc-dropdown-action
              (click)="statusSelectDropdownVisible = false; tasksStatus$.next(FilterableTaskStatus.Completed)"
              [selectable]="true"
              [selected]="tasksStatus$.value === FilterableTaskStatus.Completed"
            >
              <ng-container slot="title">
                {{ 'conversations-v4.tasks.embedModal.completed' | translate }}
              </ng-container>
            </cc-dropdown-action>

            <cc-dropdown-divider></cc-dropdown-divider>

            <cc-dropdown-action
              (click)="statusSelectDropdownVisible = false; tasksStatus$.next(FilterableTaskStatus.NotConnected)"
              [selectable]="true"
              [selected]="tasksStatus$.value === FilterableTaskStatus.NotConnected"
            >
              <ng-container slot="title">
                {{ 'conversations-v4.tasks.embedModal.noConnection' | translate }}
              </ng-container>
            </cc-dropdown-action>

            <cc-dropdown-divider></cc-dropdown-divider>

            <cc-dropdown-action
              (click)="statusSelectDropdownVisible = false; tasksStatus$.next(FilterableTaskStatus.WaitingForApproval)"
              [selectable]="true"
              [selected]="tasksStatus$.value === FilterableTaskStatus.WaitingForApproval"
            >
              <ng-container slot="title">
                {{ 'conversations-v4.tasks.embedModal.waitingForApproval' | translate }}
              </ng-container>
            </cc-dropdown-action>
          </cc-dropdown>
        </ng-template>
      </div>

      @if (tasksLoadingStatus === 'loaded' && !tasks.length) {
        <cc-task-embed-modal-placeholder
          class="task-embed-modal__body-main-placeholder"
          type="empty"
        ></cc-task-embed-modal-placeholder>
      }

      @if (tasksLoadingStatus === 'loading') {
        <cc-task-embed-modal-placeholder
          class="task-embed-modal__body-main-placeholder"
          type="loading"
        ></cc-task-embed-modal-placeholder>
      }

      @if (tasksLoadingStatus === 'error') {
        <cc-task-embed-modal-placeholder
          class="task-embed-modal__body-main-placeholder"
          type="error"
        ></cc-task-embed-modal-placeholder>
      }

      @if (!['loading', 'error'].includes(tasksLoadingStatus) && tasks.length) {
        <ng-scrollbar
          (scrolled)="loadNextTasks()"
          [infiniteScrollDistance]="4"
          [infiniteScrollThrottle]="0"
          [scrollWindow]="false"
          cdkScrollable
          class="task-embed-modal__body-main-table--wrapper"
          infiniteScroll
          orientation="auto"
        >
          <div class="task-embed-modal__body-main-table">
            <cc-table
              [ccStickyHeader]="true"
              ccPadding="medium"
              ccStyle="minimal"
            >
              <ng-container slot="cc-table">
                <thead>
                  <tr ccTr>
                    <th ccTh>
                      {{ 'conversations-v4.tasks.task' | translate }}
                    </th>
                    <th
                      ccTh
                      ccWidth="160px"
                    >
                      {{ 'conversations-v4.tasks.assignerCompany' | translate }}
                    </th>
                    <th
                      ccTh
                      ccWidth="54px"
                    >
                      {{ 'conversations-v4.tasks.assignee' | translate }}
                    </th>
                    <th
                      ccTh
                      ccWidth="54px"
                    >
                      {{ 'conversations-v4.tasks.assignee' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  @for (task of tasks; track task.id) {
                    <tr
                      (click)="activeTaskId$.next(task.id)"
                      [ccClickable]="true"
                      [ccSelected]="activeTaskId$.value === task.id"
                      ccTr
                    >
                      <cc-task-table-row [task]="task"></cc-task-table-row>
                    </tr>
                  }
                </tbody>
              </ng-container>
            </cc-table>
          </div>
        </ng-scrollbar>
      }
    </div>

    @if (activeTask$ | async; as activeTask) {
      <div @drawerAnimation>
        <cc-task-drawer
          (drawerClose)="activeTaskId$.next(undefined)"
          [task]="activeTask"
          class="task-embed-modal__body-drawer"
        >
          <app-button
            (click)="selectTask(activeTask)"
            [size]="ButtonSize.Small"
            [type]="ButtonType.Primary"
          >
            {{ 'conversations-v4.tasks.selectTask' | translate }}
          </app-button>
        </cc-task-drawer>
      </div>
    }
  </div>
</div>
