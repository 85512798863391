import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterLink, RouterLinkWithHref } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { CONVERSATIONS_BASE_URL } from '@conversations/routes';
import { AccountsFailuresNoticeComponent } from '@conversations/workspaces/accounts-failures-notice/accounts-failures-notice.component';
import { AccountsSyncNoticeComponent } from '@conversations/workspaces/accounts-sync-notice/accounts-sync-notice.component';
import { CategoryPickerComponent } from '@conversations/workspaces/category-picker/category-picker.component';
import { ConnectAccountCtaComponent } from '@conversations/workspaces/connect-account-cta/connect-account-cta.component';
import { WORKSPACE_SETTINGS_MODAL_OUTLET } from '@conversations/workspaces/modals/workspace-settings-modal/routes';
import { ExternalAccountStatus } from '@conversations/workspaces/state/workspaces/workspaces-state.model';
import { WorkspacesSelectors } from '@conversations/workspaces/state/workspaces/workspaces.selectors';
import { WorkspaceAlias } from '@conversations/workspaces/workspaces';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { CollapsibleGroupComponent } from '@design/navigation/collapsible-group/collapsible-group.component';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';

@Component({
  selector: 'cc-workspaces',
  standalone: true,
  imports: [
    CategoryPickerComponent,
    CollapsibleGroupComponent,
    CdkScrollableModule,
    NgScrollbarModule,
    RouterLinkWithHref,
    RouterLink,
    AccountsFailuresNoticeComponent,
    ConnectAccountCtaComponent,
    ButtonComponent,
    TooltipDirective,
    AsyncPipe,
    AccountsSyncNoticeComponent,
    TranslateModule,
  ],
  templateUrl: './workspaces.component.html',
  styleUrls: ['./workspaces.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspacesComponent {
  privateWorkspace$ = inject(Store).select(WorkspacesSelectors.privateWorkspace);
  teamWorkspaces$ = inject(Store).select(WorkspacesSelectors.teamWorkspaces);
  externalAccounts$ = inject(Store).select(WorkspacesSelectors.externalAccounts);

  hasAggregatedWorkspace$ = combineLatest([this.privateWorkspace$, this.teamWorkspaces$]).pipe(
    map(([privateWorkspace, teamWorkspaces]) => {
      return !!privateWorkspace || teamWorkspaces.length > 0;
    }),
  );

  hasConnectedAccounts$ = this.externalAccounts$.pipe(
    map((externalAccounts) => {
      return externalAccounts.some((account) => account.status !== ExternalAccountStatus.Disconnected);
    }),
  );

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;
  protected readonly CONVERSATIONS_BASE_URL = CONVERSATIONS_BASE_URL;
  protected readonly WorkspaceAlias = WorkspaceAlias;

  protected getWorkspaceSettingsRouterLink(workspaceAlias: WorkspaceAlias | string): unknown[] {
    return ['/', CONVERSATIONS_BASE_URL, { outlets: { [WORKSPACE_SETTINGS_MODAL_OUTLET]: [workspaceAlias] } }];
  }
}
