import { animate, style, transition, trigger } from '@angular/animations';
import { CurrencyPipe, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Output } from '@angular/core';
import { formatDistanceToNowStrict } from 'date-fns';

import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { TextboxComponent } from '@design/forms/textbox/textbox.component';
import { TextboxType } from '@design/forms/textbox/textbox.types';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';

import { BpiOrderDrawerComponent } from '../bpi-order-drawer/bpi-order-drawer.component';
import { MOCK_BPI_ORDERS, type BPIOrder } from '../business-object';

@Component({
  selector: 'cc-bpi-order-table',
  standalone: true,
  imports: [
    TextboxComponent,
    ButtonComponent,
    NgOptimizedImage,
    TooltipDirective,
    BpiOrderDrawerComponent,
    CurrencyPipe,
  ],
  templateUrl: './bpi-order-table.component.html',
  styleUrls: ['./bpi-order-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('drawer', [
      transition(':enter', [
        style({ width: 0, minWidth: '0' }),
        animate(
          '0.2s ease-in-out',
          style({
            width: '320px',
            minWidth: '320px',
          }),
        ),
      ]),
      transition(':leave', [
        style({ width: '320px', minWidth: '320px' }),
        animate(
          '0.2s ease-in-out',
          style({
            width: 0,
            minWidth: '0',
          }),
        ),
      ]),
    ]),
  ],
})
export class BpiOrderTableComponent {
  @Output()
  selectOrder = new EventEmitter<BPIOrder>();

  protected activeOrderId: string | null = null;

  protected readonly TextboxType = TextboxType;
  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;
  protected readonly MOCK_BPI_ORDERS = MOCK_BPI_ORDERS;

  private readonly cdr = inject(ChangeDetectorRef);

  get activeOrder(): BPIOrder | undefined {
    return this.MOCK_BPI_ORDERS.find((order) => order.id === this.activeOrderId);
  }

  showInDrawer(order: BPIOrder | null): void {
    this.activeOrderId = order?.id;
    this.cdr.detectChanges();
  }

  getStatusText(order: BPIOrder): string {
    switch (order.status) {
      case 'partially-invoiced':
        return 'Partially Invoiced';
      default:
        return 'Unknown';
    }
  }

  getStatusFgColor(order: BPIOrder): string {
    switch (order.status) {
      case 'partially-invoiced':
        return '#725616';
      default:
        return '#000000';
    }
  }

  getStatusBgColor(order: BPIOrder): string {
    switch (order.status) {
      case 'partially-invoiced':
        return '#F9E8C0';
      default:
        return '#FFFFFF';
    }
  }

  getOrderAge(order: BPIOrder): string {
    const orderDate = new Date(order.poDate);
    return formatDistanceToNowStrict(orderDate, { addSuffix: false });
  }
}
