<div
  [class.composer-message-type-selector--internal]="composer.messageType === 'internal'"
  class="composer-message-type-selector"
>
  @if (composer.composerType !== 'newEmail' && !forceAllowOnlyInternal) {
    <div
      #messageTypeDropdownTrigger
      style="height: fit-content"
    >
      <app-button
        (click)="messageTypeDropdownVisible = !messageTypeDropdownVisible"
        [active]="messageTypeDropdownVisible"
        [class.composer-message-type-selector__dropdown-trigger-button--expanded]="messageTypeDropdownVisible"
        [size]="ButtonSize.Small"
        [tooltipAlignment]="TooltipAlignment.Start"
        [type]="ButtonType.Ghost"
        [ccTooltip]="'conversations-v4.composer.messageTypes.changeMessageType' | translate"
        class="composer-message-type-selector__dropdown-trigger-button"
        [hasSecondIcon]="true"
      >
        <i
          [class.icon-forward]="composer.messageType === 'forward'"
          [class.icon-internal-message]="composer.messageType === 'internal'"
          [class.icon-reply-all]="composer.messageType === 'replyAll'"
          [class.icon-reply]="composer.messageType === 'reply'"
          slot="ghost-icon"
        ></i>
        <i
          class="composer-message-type-selector__dropdown-trigger-button-toggle-icon icon-chevronDown"
          slot="second-icon"
        ></i>
      </app-button>
    </div>
    <ng-template
      (detach)="messageTypeDropdownVisible = false"
      (overlayOutsideClick)="
        messageTypeDropdownVisible = getOverlayVisibilityAfterOutsideClick($event, messageTypeDropdownTrigger)
      "
      [cdkConnectedOverlayOpen]="messageTypeDropdownVisible"
      [cdkConnectedOverlayOrigin]="messageTypeDropdownTrigger"
      [cdkConnectedOverlayPositions]="dropdownPositionStrategy"
      cdkConnectedOverlay
    >
      <cc-dropdown width="200px">
        <div
          class="cc-disabled-tooltip-wrapper"
          [ccTooltip]="'conversations-v4.composer.messageTypes.replyForbiddenNotice' | translate"
          [tooltipPosition]="TooltipPosition.Left"
          [tooltipDisabled]="!replyForbidden"
        >
          <cc-dropdown-action
            (click)="setMessageType('reply'); messageTypeDropdownVisible = false"
            [active]="composer.messageType === 'reply'"
            [disabled]="!allowedMessageTypes.includes('reply') || replyForbidden"
            leadingIcon="icon-reply"
          >
            <ng-container slot="title">
              {{ 'conversations-v4.composer.messageTypes.reply' | translate }}
            </ng-container>
          </cc-dropdown-action>
        </div>

        <div
          class="cc-disabled-tooltip-wrapper"
          [ccTooltip]="'conversations-v4.composer.messageTypes.replyForbiddenNotice' | translate"
          [tooltipPosition]="TooltipPosition.Left"
          [tooltipDisabled]="!replyForbidden"
        >
          <cc-dropdown-action
            (click)="setMessageType('replyAll'); messageTypeDropdownVisible = false"
            [active]="composer.messageType === 'replyAll'"
            [disabled]="!allowedMessageTypes.includes('replyAll') || replyForbidden"
            leadingIcon="icon-reply-all"
          >
            <ng-container slot="title">
              {{ 'conversations-v4.composer.messageTypes.replyAll' | translate }}
            </ng-container>
          </cc-dropdown-action>
        </div>

        <cc-dropdown-action
          (click)="setMessageType('forward'); messageTypeDropdownVisible = false"
          [active]="composer.messageType === 'forward'"
          [disabled]="!allowedMessageTypes.includes('forward')"
          leadingIcon="icon-forward"
        >
          <ng-container slot="title">
            {{ 'conversations-v4.composer.messageTypes.forward' | translate }}
          </ng-container>
        </cc-dropdown-action>

        <cc-dropdown-divider></cc-dropdown-divider>

        <cc-dropdown-action
          (click)="setMessageType('internal'); messageTypeDropdownVisible = false"
          [active]="composer.messageType === 'internal'"
          [disabled]="!allowedMessageTypes.includes('internal')"
          leadingIcon="icon-internal-message"
        >
          <ng-container slot="title">
            {{ 'conversations-v4.composer.messageTypes.internalMessage' | translate }}
          </ng-container>
        </cc-dropdown-action>
      </cc-dropdown>
    </ng-template>
  }

  <div class="composer-message-type-selector__content">
    @if (composer.messageType !== 'internal') {
      <cc-composer-recipients-selector
        (recipientsChange)="updateRecipients($event)"
        [recipients]="composer.emailParticipants"
      ></cc-composer-recipients-selector>
    } @else {
      <div class="composer-message-type-selector__content-internal-notice">
        {{ 'conversations-v4.composer.messageTypes.internalMessageNotice' | translate }}
      </div>
    }
  </div>

  @if (composer.presentation === 'inline') {
    <app-button
      (click)="openOverlay()"
      [size]="ButtonSize.Small"
      [tooltipAlignment]="TooltipAlignment.End"
      [type]="ButtonType.Ghost"
      [ccTooltip]="'conversations-v4.composer.openInOverlay' | translate"
      class="composer-message-type-selector__open-overlay-button"
      style="height: fit-content"
    >
      <i
        class="icon-window-maximize"
        slot="ghost-icon"
      ></i>
    </app-button>
  }
</div>
