<ng-template #modalTemplate>
  <div class="workspace-settings-modal">
    <div class="workspace-settings-modal__header">
      <div class="workspace-settings-modal__header-main">
        <div class="workspace-settings-modal__header-main-title">
          {{
            workspace.ownerType === 'user'
              ? ('conversations-v4.workspaces.titles.privateWorkspace' | translate)
              : workspace.name
          }}
        </div>

        @if (workspace.ownerType === 'team') {
          <div class="workspace-settings-modal__header-main-type">
            {{ 'conversations-v4.workspaces.titles.teamWorkspace' | translate }}
          </div>
        }

        <app-button
          (click)="closeModal()"
          [ccTooltip]="'common.buttons.close' | translate"
          [size]="ButtonSize.Big"
          [type]="ButtonType.Ghost"
        >
          <i
            class="icon-close"
            slot="ghost-icon"
          ></i>
        </app-button>
      </div>

      <cc-tab-selector
        (selectedTabIdChange)="handleTabSelect($event)"
        [selectedTabId]="selectedTabId"
        [tabs]="tabs"
        [inlinePadding]="12"
      />
    </div>

    <div
      [ccAutoAnimate]
      class="workspace-settings-modal__content"
    >
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-template>
