<div class="workspaces">
  <ng-scrollbar
    cdkScrollable
    class="workspaces--scrollbar"
  >
    <div class="workspaces__list">
      @if (hasAggregatedWorkspace$ | async) {
        <cc-collapsible-group
          #myWorkspaceGroup
          [title]="'conversations-v4.workspaces.titles.myWorkspace' | translate"
        >
          <cc-category-picker
            (childSelected)="myWorkspaceGroup.expand()"
            [workspaceAlias]="WorkspaceAlias.Aggregated"
          ></cc-category-picker>
        </cc-collapsible-group>
      }

      <cc-collapsible-group
        #privateWorkspaceGroup
        [title]="'conversations-v4.workspaces.titles.privateWorkspace' | translate"
      >
        <ng-container slot="collapsible-group-action">
          <app-button
            [ccTooltip]="'conversations-v4.workspaces.settings.openSettings' | translate"
            [routerLink]="getWorkspaceSettingsRouterLink(WorkspaceAlias.Private)"
            [size]="ButtonSize.Small"
            [type]="ButtonType.Ghost"
          >
            <i
              class="icon-more"
              slot="ghost-icon"
            ></i>
          </app-button>
        </ng-container>

        <cc-category-picker
          (childSelected)="privateWorkspaceGroup.expand()"
          [workspaceAlias]="WorkspaceAlias.Private"
        ></cc-category-picker>
      </cc-collapsible-group>

      @if (teamWorkspaces$ | async; as teamWorkspaces) {
        <cc-collapsible-group
          #teamWorkspacesGroup
          [title]="'conversations-v4.workspaces.titles.teamWorkspaces' | translate"
        >
          @for (workspace of teamWorkspaces; track workspace.id) {
            <cc-collapsible-group
              #teamWorkspaceGroup
              (childSelected)="teamWorkspacesGroup.expand()"
              [expanded]="false"
              [style]="'team-workspace'"
              [title]="workspace.name"
            >
              <ng-container slot="collapsible-group-action">
                <app-button
                  (click)="$event.stopPropagation()"
                  [routerLink]="getWorkspaceSettingsRouterLink(workspace.id.toString())"
                  [size]="ButtonSize.Small"
                  [type]="ButtonType.Ghost"
                  [ccTooltip]="'conversations-v4.workspaces.settings.openSettings' | translate"
                >
                  <i
                    class="icon-more"
                    slot="ghost-icon"
                  ></i>
                </app-button>
              </ng-container>

              <cc-category-picker
                (childSelected)="teamWorkspaceGroup.expand()"
                [workspaceAlias]="workspace.id.toString()"
              ></cc-category-picker>
            </cc-collapsible-group>
          }
        </cc-collapsible-group>
      }
    </div>
  </ng-scrollbar>

  @if ((hasConnectedAccounts$ | async) === false) {
    <cc-connect-account-cta class="workspaces__connect-account-cta" />
  }

  <div class="workspaces__notices">
    <cc-accounts-failures-notice class="workspaces__notices-item" />
    <cc-accounts-sync-notice class="workspaces__notices-item" />
  </div>
</div>
